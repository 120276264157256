import Grid from "@mui/material/Grid";
import Loader from "@shared/components/molecules/loader";
import { useEffect } from "react";
import {
  useNavigate,
  useSearchParams,
  createSearchParams,
} from "react-router-dom";

export interface Props {
  to: string;
  replace: boolean;
  keys?: string[];
}

const NavigateWithParams: React.FC<Props> = ({ to, replace, keys }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams({});

  useEffect(() => {
    const searchParamsObject: any = {};
    keys?.map((key) => {
      const value = searchParams.get(key);
      if (value) searchParamsObject[key] = value;
      return false;
    });

    navigate({
      pathname: to,
      search: createSearchParams(searchParamsObject).toString(),
    });
  }, [navigate, searchParams, to, keys]);

  return (
    <Grid
      sx={{ height: "100vh", width: "100vw" }}
      justifyContent="center"
      alignContent={"center"}
    >
      <Loader text="Loading..." />
    </Grid>
  );
};

export default NavigateWithParams;

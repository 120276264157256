import { useQuery } from "react-query";
import axios from "axios";
import dayjs from "dayjs";
import {
  OptionsObject,
  SnackbarKey,
  SnackbarMessage,
  useSnackbar,
} from "notistack";
import { QueryKeys } from "@shared/constants/QueryKeys";
import * as BrowserStorage from "@shared/constants/BrowserStorage";

const GEO_CACHE_TIME = 5; // minutes

interface GeocodingVars {
  fallbackCountry?: string;
  fallbackState?: string;
  geocodingKey: string;
}

export function useGeoLocation(variables: GeocodingVars) {
  const { enqueueSnackbar } = useSnackbar();
  return useQuery(
    QueryKeys.GetGeolocation,
    () => getStateAndCountry(variables, enqueueSnackbar),
    {
      cacheTime: Infinity,
    }
  );
}

type FnEnqueueSnackbar = (
  message: SnackbarMessage,
  options?: OptionsObject | undefined
) => SnackbarKey;

async function getStateAndCountry(
  variables: GeocodingVars,
  enqueueSnackbar: FnEnqueueSnackbar
): Promise<{ state: string; country: string; lat?: number; long?: number }> {
  return new Promise((resolve, reject) => {
    const cachedGeoLocation = loadGeoLocation();
    if (cachedGeoLocation) {
      return resolve(cachedGeoLocation);
    }
    if (!navigator.geolocation) {
      return reject("Geolocation disabled or blocked");
    }
    navigator.geolocation.getCurrentPosition(
      (pos) => {
        reverseGeocodeCoordinates(
          pos.coords.latitude,
          pos.coords.longitude,
          variables
        ).then((resp) => {
          if (resp) {
            saveGeoLocation(
              resp.state,
              resp.country,
              pos.coords.latitude,
              pos.coords.longitude
            );
            resolve({
              state: resp.state,
              country: resp.country,
              lat: pos.coords.latitude,
              long: pos.coords.longitude,
            });
          } else {
            return reject("Unable to retrieve geolocation");
          }
        });
      },
      (error) => {
        handleLocationError(enqueueSnackbar)(error);
        return reject("Unable to retrieve geolocation");
      }
    );
  });
}

interface CachedGeoLocation {
  country: string;
  state: string;
  lat: number;
  long: number;
  expiresAt: number;
}

function saveGeoLocation(
  state: string,
  country: string,
  lat: number,
  long: number
) {
  const payload: CachedGeoLocation = {
    state,
    country,
    lat,
    long,
    expiresAt: dayjs().add(GEO_CACHE_TIME, "minute").toDate().getTime(),
  };
  localStorage.setItem(BrowserStorage.GEO, JSON.stringify(payload));
}

function loadGeoLocation(): {
  state: string;
  country: string;
  lat: number;
  long: number;
} | null {
  const value = localStorage.getItem(BrowserStorage.GEO);
  if (!value) {
    return null;
  }
  try {
    const payload: CachedGeoLocation = JSON.parse(value);
    if (
      !payload.state ||
      !payload.country ||
      !payload.lat ||
      !payload.long ||
      !payload.expiresAt ||
      dayjs(payload.expiresAt).isBefore(dayjs())
    ) {
      return null;
    }
    return {
      country: payload.country,
      state: payload.state,
      lat: payload.lat,
      long: payload.long,
    };
  } catch (_ex) {
    return null;
  }
}

type GeocodingResponse = Array<{
  address_components: Array<{
    long_name: string;
    short_name: string;
    types: Array<"premise" | "administrative_area_level_1" | "country">;
  }>;
  formatted_address: string;
  geometry: {
    location: {
      lat: number;
      lng: number;
      location_type: "ROOFTOP" | "APPROXIMATE";
    };
  };
  place_id: string;
  types: Array<
    "street_address" | "administrative_area_level_1" | "locality" | "political"
  >;
}>;

async function reverseGeocodeCoordinates(
  lat: number,
  lon: number,
  options: GeocodingVars
): Promise<{ state: string; country: string } | void> {
  return axios
    .get(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lon}&key=${options.geocodingKey}`
    )
    .then((resp) => resp.data)
    .then(({ results }: { results: GeocodingResponse }) => {
      const stateLevel = results?.filter((addresses) => {
        return addresses.types.includes("administrative_area_level_1");
      })[0];

      const state = stateLevel?.address_components?.filter((component) => {
        return component.types.includes("administrative_area_level_1");
      })[0];

      const country = stateLevel?.address_components?.filter((component) => {
        return component.types.includes("country");
      })[0];
      return {
        state: state?.short_name ?? options.fallbackState,
        country: country?.short_name ?? options.fallbackCountry,
      };
    })
    .catch((error) => {
      if (error.response) {
        // Request made and server responded
        // TODO @irina: handle and surface errors
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        // TODO @irina: handle and surface errors
        console.log(error.request);
        throw new Error("Wrong URL");
      } else {
        // TODO @irina: handle and surface errors
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
      }
    });
}

const ERROR_MESSAGES = {
  LOCATION_NOT_SUPPORTED: `Geolocation is not supported by this browser.`,
  PERMISSION_DENIED: `Please enable Geolocation to proceed.`,
  POSITION_UNAVAILABLE: `Location information is unavailable.`,
  TIMEOUT: `Location request timed out.`,
  UNKNOWN_ERROR: `Failed to get location information.`,
  DEFAULT_ERROR: `Failed to get location information.`,
};

const handleLocationError =
  (enqueueSnackbar: FnEnqueueSnackbar) => (error: any) => {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        enqueueSnackbar(ERROR_MESSAGES.PERMISSION_DENIED, {
          variant: "warning",
          anchorOrigin: { horizontal: "right", vertical: "top" },
          preventDuplicate: true,
        });
        break;
      case error.POSITION_UNAVAILABLE:
        enqueueSnackbar(ERROR_MESSAGES.POSITION_UNAVAILABLE, {
          variant: "warning",
          anchorOrigin: { horizontal: "right", vertical: "top" },
          preventDuplicate: true,
        });
        break;
      case error.TIMEOUT:
        enqueueSnackbar(ERROR_MESSAGES.TIMEOUT, {
          variant: "warning",
          anchorOrigin: { horizontal: "right", vertical: "top" },
          preventDuplicate: true,
        });
        break;
      case error.UNKNOWN_ERROR:
        enqueueSnackbar(ERROR_MESSAGES.UNKNOWN_ERROR, {
          variant: "warning",
          anchorOrigin: { horizontal: "right", vertical: "top" },
          preventDuplicate: true,
        });
        break;
      default:
        enqueueSnackbar(ERROR_MESSAGES.DEFAULT_ERROR, {
          variant: "warning",
          anchorOrigin: { horizontal: "right", vertical: "top" },
          preventDuplicate: true,
        });
        break;
    }
  };

import { useState, useRef } from "react";
import { Control, Controller, ControllerProps, Path } from "react-hook-form";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Box, InputAdornment, TextField } from "@mui/material";

const DEFAULT_ACCEPTABLE_FILE_TYPES = ".jpg, .jpeg, .png";

export type FileUploadControlProps<TFormFields> = Pick<
  ControllerProps,
  "rules"
> &
  Omit<FileUploadProps, "onChange" | "name"> & {
    control: Control<TFormFields, object>;
    id: Path<TFormFields>;
    onChange?: (file: File) => void;
  };

export function FileUploadControl<TFormFields>(
  props: FileUploadControlProps<TFormFields>
) {
  const { control, id, onChange, ...fileUploadProps } = props;
  return (
    <Controller
      name={id}
      control={control}
      render={({ field, fieldState }) => (
        <FileUpload
          {...field}
          {...fileUploadProps}
          error={fieldState.error?.message}
          name={id}
          onChange={(val) => {
            field.onChange(val);
            if (onChange) {
              try {
                onChange(val);
              } catch (_ex) {}
            }
          }}
        />
      )}
    />
  );
}

interface FileUploadProps {
  defaultValue?: string;
  disabled?: boolean;
  error?: string;
  helperText?: string;
  label?: string;
  name: string;
  onChange: (file: File) => void;
}

function FileUpload(props: FileUploadProps) {
  const { error, helperText, name, label, defaultValue, disabled, onChange } =
    props;
  const [file, setFile] = useState<{ name: string } | File | undefined>(
    defaultValue ? { name: defaultValue } : undefined
  );
  const fileRef = useRef<HTMLInputElement | null>(null);

  const showFileUpload = () => {
    if (fileRef?.current) {
      fileRef.current.click();
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (!e.target.files) {
      return;
    }
    const file = e.target.files[0];
    if (file) {
      setFile(file);
      onChange(file);
    }
  };

  return (
    <Box width="100%">
      <Box
        accept={DEFAULT_ACCEPTABLE_FILE_TYPES}
        component="input"
        disabled={disabled}
        hidden={true}
        name={name}
        onChange={handleFileChange}
        readOnly
        ref={fileRef}
        type="file"
      />
      <TextField
        disabled={disabled}
        error={Boolean(error)}
        label={label || "Upload File"}
        helperText={error ?? helperText}
        size="small"
        type="text"
        value={file ? file.name : ""}
        onClick={() => {
          showFileUpload();
        }}
        fullWidth
        variant="outlined"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {file ? (
                <CheckCircleIcon
                  sx={{ color: disabled ? "text-green-700" : "text-green-500" }}
                />
              ) : (
                <CloudUploadIcon sx={{ color: "text-gray-500" }} />
              )}
            </InputAdornment>
          ),
          sx: { cursor: "pointer" },
        }}
      />
    </Box>
  );
}

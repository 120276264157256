import { ButtonBase, Grid, Typography, useMediaQuery } from "@mui/material";
import { theme } from "@shared/components/tokens/theme";
export interface FooterLinkProps {
  item: {
    label: string;
    link: string;
  };
}

export function FooterLink(props: FooterLinkProps) {
  const isSmall = useMediaQuery(theme.breakpoints.down("md"));
  const { item } = props;
  const redirectPage = (path: string) => {
    if (window.location) {
      window.location.href = path;
    }
  };

  return (
    <Grid
      item
      sx={{
        paddingTop: isSmall ? "0" : "0.5rem",
        paddingBottom: isSmall ? "0" : "0.5rem",
        marginTop: "0.25rem",
      }}
    >
      <ButtonBase onClick={() => redirectPage(item.link)}>
        <Typography
          variant="body1"
          align="center"
          sx={{
            color: (theme) => theme.palette.grey[500],
            padding: "0px 0.5rem",
          }}
        >
          {item.label}
        </Typography>
      </ButtonBase>
    </Grid>
  );
}

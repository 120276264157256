import { useForm, SubmitHandler } from "react-hook-form";
import { Link as RouterLink } from "react-router-dom";
import Link from "@mui/material/Link";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMemo } from "react";
import * as yup from "yup";
import {
  Box,
  Button,
  Card,
  Container,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { LogoFullLarge } from "@shared/components/atoms/logos/LogoFullLarge";
import { theme } from "@shared/components/tokens/theme";
import { PasswordControl } from "@shared/components/molecules/forms/PasswordControl";

interface IFormValues {
  password: string;
  confirmPassword: string;
}
export interface ResetPasswordProps {
  onResetPassword: (data: IFormValues) => void;
}
export function ResetPassword(props: ResetPasswordProps) {
  const { onResetPassword } = props;
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("md"));

  const formSchema = useMemo(
    () =>
      yup.object({
        password: yup
          .string()
          .required("Please choose a password")
          .matches(
            /^(?=[^A-Z\s]*[A-Z])(?=[^a-z\s]*[a-z])(?=[^\d\s]*\d)(?=\w*[\W_])\S{12,}$/,
            "Password must contain at least 12 characters, one number, one uppercase alphabet, one lowercase alphabet, and one special character"
          ),
        confirmPassword: yup
          .string()
          .required("Please confirm your password")
          .test("passwords-match", "Passwords must match", function (value) {
            return this.parent.password === value;
          }),
      }),
    []
  );

  const {
    control,
    formState: { errors, isDirty },
    handleSubmit,
  } = useForm<IFormValues>({
    resolver: yupResolver(formSchema),
    defaultValues: {
      password: "",
      confirmPassword: "",
    },
  });

  const onSubmit: SubmitHandler<IFormValues> = (data) => {
    onResetPassword(data);
  };

  return (
    <Container component="main" maxWidth="xs">
      <Stack
        alignItems="center"
        direction="column"
        justifyContent="center"
        spacing={4}
        height="100%"
      >
        <LogoFullLarge />
        <Card sx={{ width: "100%", p: 2 }}>
          <Stack direction="column" spacing={2}>
            <Stack
              direction="column"
              alignItems={isLargeScreen ? "flex-start" : "center"}
            >
              <Typography variant="h5">Reset Password</Typography>
              <Stack direction="row" spacing={1}>
                <Typography>Please input your new password</Typography>
              </Stack>
            </Stack>
            <Box component="form" onSubmit={handleSubmit(onSubmit)}>
              <Stack direction="column" spacing={2}>
                <PasswordControl
                  control={control}
                  error={Boolean(errors.password)}
                  id="password"
                  label="Password*"
                  rules={{ required: true }}
                  type="password"
                  variant="standard"
                />
                <PasswordControl
                  control={control}
                  error={Boolean(errors.confirmPassword)}
                  id="confirmPassword"
                  label="Confirm Password*"
                  rules={{ required: true }}
                  type="password"
                  variant="standard"
                />
                <Stack
                  direction="row"
                  justifyContent={isLargeScreen ? "flex-end" : "center"}
                >
                  <Link component={RouterLink} to="/">
                    Back to Login
                  </Link>
                </Stack>
                <Stack direction="row" justifyContent="flex-end">
                  <Button
                    size="large"
                    fullWidth={!isLargeScreen}
                    disableElevation
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={!isDirty}
                    sx={{
                      mt: 2,
                      pl: 6,
                      pr: 6,
                      fontWeight: "bold",
                    }}
                  >
                    Reset Password
                  </Button>
                </Stack>
              </Stack>
            </Box>
          </Stack>
        </Card>
      </Stack>
    </Container>
  );
}

import { InputAdornment, Typography } from "@mui/material";
import {
  TextInputControl,
  TextInputControlProps,
} from "@shared/components/molecules/forms/TextInputControl";

type TextInputControlWithPrefixProps<TFormFields> =
  TextInputControlProps<TFormFields> & {
    prefix?: string;
  };

export function TextInputControlWithPrefix<TFormFields>(
  props: TextInputControlWithPrefixProps<TFormFields>
) {
  const { prefix, ...textInputControlProps } = props;

  return (
    <TextInputControl
      {...textInputControlProps}
      InputProps={
        prefix
          ? {
              startAdornment: (
                <InputAdornment position="start">
                  <Typography variant="h5">{prefix}</Typography>
                </InputAdornment>
              ),
            }
          : {}
      }
    />
  );
}

import { useState } from "react";
import { Control, Controller, ControllerProps, Path } from "react-hook-form";
import {
  IconButton,
  InputAdornment,
  TextField,
  TextFieldProps,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

type PasswordControlProps<TFormFields> = TextFieldProps &
  Pick<ControllerProps, "rules"> & {
    control: Control<TFormFields, object>;
    id: Path<TFormFields>;
  };

export function PasswordControl<TFormFields>(
  props: PasswordControlProps<TFormFields>
) {
  const { control, ...textFieldProps } = props;
  const [isRevealed, setRevealed] = useState(false);
  return (
    <Controller
      name={textFieldProps.id}
      control={control}
      render={({ field, fieldState }) => (
        <TextField
          {...field}
          {...textFieldProps}
          helperText={fieldState.error?.message ?? textFieldProps.helperText}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setRevealed(!isRevealed)}
                >
                  {isRevealed ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
            sx: { fontFamily: "Roboto, sans" },
          }}
          type={isRevealed ? "text" : "password"}
        />
      )}
    />
  );
}

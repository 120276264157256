import { useForm, SubmitHandler } from "react-hook-form";
import { Link as RouterLink } from "react-router-dom";
import Link from "@mui/material/Link";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMemo } from "react";
import * as yup from "yup";
import {
  Box,
  Button,
  Card,
  Container,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { LogoFullLarge } from "@shared/components/atoms/logos/LogoFullLarge";
import { TextInputControl } from "@shared/components/molecules/forms/TextInputControl";
import { theme } from "@shared/components/tokens/theme";

interface IFormValues {
  email: string;
}
export interface ForgotPasswordProps {
  onForgotPassword: (data: IFormValues) => void;
}
export function ForgotPassword(props: ForgotPasswordProps) {
  const { onForgotPassword } = props;
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("md"));

  const formSchema = useMemo(
    () =>
      yup.object({
        email: yup
          .string()
          .email("Invalid email address")
          .required("Email address is required"),
      }),
    []
  );

  const {
    control,
    formState: { errors, isDirty },
    handleSubmit,
  } = useForm<IFormValues>({
    resolver: yupResolver(formSchema),
    defaultValues: {
      email: "",
    },
  });

  const onSubmit: SubmitHandler<IFormValues> = (data) => {
    onForgotPassword(data);
  };

  return (
    <Container component="main" maxWidth="xs">
      <Stack
        alignItems="center"
        direction="column"
        justifyContent="center"
        spacing={4}
        height="100%"
      >
        <LogoFullLarge />
        <Card sx={{ width: "100%", p: 2 }}>
          <Stack direction="column" spacing={2}>
            <Stack
              direction="column"
              alignItems={isLargeScreen ? "flex-start" : "center"}
            >
              <Typography variant="h5">Forgot Password</Typography>
              <Stack direction="row" spacing={1}>
                <Typography>
                  Please input your registered email id and we will send you a
                  reset link.
                </Typography>
              </Stack>
            </Stack>
            <Box component="form" onSubmit={handleSubmit(onSubmit)}>
              <Stack direction="column" spacing={2}>
                <TextInputControl
                  control={control}
                  error={Boolean(errors.email)}
                  id="email"
                  label="Email"
                  rules={{ required: true }}
                  type="text"
                  variant="standard"
                />
                <Stack
                  direction="row"
                  justifyContent={isLargeScreen ? "flex-end" : "center"}
                >
                  <Link component={RouterLink} to="/">
                    Back to Login
                  </Link>
                </Stack>
                <Stack direction="row" justifyContent="flex-end">
                  <Button
                    size="large"
                    fullWidth={!isLargeScreen}
                    disableElevation
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={!isDirty}
                    sx={{
                      mt: 2,
                      pl: 6,
                      pr: 6,
                      fontWeight: "bold",
                    }}
                  >
                    Send Email
                  </Button>
                </Stack>
              </Stack>
            </Box>
          </Stack>
        </Card>
      </Stack>
    </Container>
  );
}

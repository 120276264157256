import { useEffect, useState } from "react";
import { Control, Controller, ControllerProps, Path } from "react-hook-form";
import { TextField, TextFieldProps } from "@mui/material";
import AdapterDayjs from "@mui/lab/AdapterDayjs";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import dayjs, { Dayjs } from "dayjs";
import { DatePickerView } from "@mui/lab/DatePicker/shared";

type DatePickerControlProps<TFormFields> = TextFieldProps &
  Pick<ControllerProps, "rules"> & {
    control: Control<TFormFields, object>;
    id: Path<TFormFields>;
    maxDate?: Dayjs;
    minDate?: Dayjs;
    value?: Dayjs;
    defaultValue?: Dayjs;
    views?: DatePickerView[];
  };

export function DatePickerControl<TFormFields>(
  props: DatePickerControlProps<TFormFields>
) {
  const {
    control,
    maxDate,
    minDate,
    disabled,
    defaultValue,
    id,
    views,
    ...textFieldProps
  } = props;

  const [value, setValue] = useState<Dayjs | null>(defaultValue || null);

  const handleChange =
    (field: { onChange: (...event: any[]) => void }) =>
    (date: Dayjs | null) => {
      setValue(date);
      const dateVal = dayjs(date);
      value && dateVal.isValid() && field.onChange(date);
    };

  useEffect(() => {
    if (props.value) {
      setValue(props.value);
    }
  }, [props.value]);

  return (
    <Controller
      control={control}
      name={id}
      render={({ field, fieldState }) => (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label={textFieldProps.label}
            disabled={disabled}
            maxDate={maxDate}
            minDate={minDate}
            onChange={handleChange(field)}
            value={value}
            views={views || ["year", "month", "day"]}
            renderInput={(params) => (
              <TextField
                {...params}
                {...textFieldProps}
                helperText={
                  fieldState.error?.message ?? params?.inputProps?.placeholder
                }
                fullWidth
                sx={{
                  button: { position: "relative", right: 10 },
                }}
              />
            )}
          />
        </LocalizationProvider>
      )}
    />
  );
}

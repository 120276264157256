import { Auth, signOut } from "firebase/auth";
import { useMutation } from "react-query";
import { logout } from "@src/services/auth";
import {
  clearAuthTokens,
  clearUserConfig,
} from "@src/services/authTokenStorage";
import routes from "@shared/constants/routePaths";
import useFirebaseAuth from "./useFirebaseAuth";

export function useSignOut() {
  const { data: auth } = useFirebaseAuth();
  return useMutation(() => signoutAndClearAuthTokens(auth));
}

async function signoutAndClearAuthTokens(auth?: Auth): Promise<void> {
  if (!auth) {
    throw new Error("Incomplete credentials");
  }
  return signOut(auth)
    .then(() => {
      logout();
    })
    .then(() => {
      clearAuthTokens();
      clearUserConfig();
    })
    .then(() => {
      window.location.href = `${routes.account.login}`;
    });
}

import { include } from "named-urls";

export enum AppRoot {
  ACCOUNT = "account",
  NFL = "nfl",
  PGA = "pga",
}

const Routes = {
  [AppRoot.ACCOUNT]: include(
    process.env.NODE_ENV === "development" ? `/${AppRoot.ACCOUNT}` : "/",
    {
      login: "",
      loginAlt: "login",
      signup: "signup",
      mobilePromotion: "mobile-promotion",
      register: "register",
      forgotPassword: "forgot-password",
      resetPassword: "reset-password/:token",

      privacyPolicy: "privacy-policy",
      termsOfService: "terms-of-service",
      responsibleGaming: "responsible-gaming",

      profile: include("profile", {
        wallet: "wallet",
        withdraw: "withdraw",
        transfers: "transfers",
        verify: "verify",
      }),
    }
  ),
  [AppRoot.PGA]: include(`/${AppRoot.PGA}`, {
    battleground: include("battleground", {
      lobby: "lobby",
      myContests: "mycontests",
      history: "history",
    }),
  }),
  [AppRoot.NFL]: include(`/${AppRoot.NFL}`, {
    battleground: include("battleground", {
      lobby: "lobby",
      myContests: "mycontests",
      history: "history",
    }),
  }),
};

export function routesForApp<TApp extends AppRoot>(
  app: AppRoot
): typeof Routes[TApp] {
  return stripPrefix(Routes[app], app);
}

function stripPrefix(routes: any, prefix: string) {
  const result: any = {};
  for (let key in routes) {
    if (typeof routes[key] === "string") {
      result[key] = routes[key].replace(prefix, "");
    } else if (typeof routes[key] !== "function") {
      result[key] = stripPrefix(routes[key], prefix);
    }
  }
  return result;
}

export default Routes;

import { useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { ResetPassword as ResetPasswordTemplate } from "@shared/components/templates/ResetPassword";
import { resetPassword } from "@src/services/auth";
import { AppRoot, routesForApp } from "@shared/constants/routePaths";
import BaseLayout from "@src/components/templates/BaseLayout";

const routes = routesForApp<AppRoot.ACCOUNT>(AppRoot.ACCOUNT);

interface IFormValues {
  password: string;
  confirmPassword: string;
}

export function ResetPassword() {
  const navigate = useNavigate();
  let { token } = useParams();

  const onResetPassword = useCallback(
    async (payload: IFormValues) => {
      try {
        const requestPayload = {
          ...payload,
          forgotPasswordToken: token || "",
        };
        await resetPassword(requestPayload);
        // TODO @irina: display a success message
        navigate(routes.login);
      } catch (errors) {
        // TODO @irina: display a toast message
        console.log(errors);
      }
    },
    [navigate, token]
  );

  return (
    <BaseLayout>
      <ResetPasswordTemplate onResetPassword={onResetPassword} />
    </BaseLayout>
  );
}

import { Fragment } from "react";
import { useQueryClient } from "react-query";
import { useSnackbar } from "notistack";
import ProfileLayout from "@src/components/templates/ProfileLayout";
import { WithdrawFunds as WithdrawFundsTab } from "@shared/components/templates/Wallet/WithdrawFunds";
import { useCreateTsevoSession } from "@shared/hooks/useCreateTsevoSession";
import { useWalletInfo } from "@shared/hooks/useWalletInfo";
import { useGatewayInfo } from "@shared/hooks/useGatewayInfo";
import { usePaymentSources } from "@src/hooks/usePaymentSources";
import { MIN_WITHDRAW_BALANCE } from "@src/constants/wallet";
import { useWithdrawFunds } from "@src/hooks/useWithDrawFunds";
import { QueryKeys } from "@shared/constants/QueryKeys";
import { useDeletePaymentSource } from "@src/hooks/useDeletePaymentSource";
import { useGeoLocation } from "@shared/hooks/useGeolocation";
import { WithdrawFundsPayload } from "@shared/types";

export function WithdrawFunds() {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const { data: walletInfo } = useWalletInfo();
  const { mutate: withdrawFundsRequest } = useWithdrawFunds();
  const { mutate: deletePaymentSource } = useDeletePaymentSource();
  const geocodingKey = process.env.REACT_APP_GOOGLE_GEOCODING_KEY || "";
  const fallbackCountry = "IN";
  const fallbackState = "GA";

  const { data: geoData } = useGeoLocation({
    geocodingKey,
    fallbackCountry,
    fallbackState,
  });

  const { data: tsevoSession } = useCreateTsevoSession({
    payActionCode: "PAYOUT",
    lat: geoData?.lat,
    long: geoData?.long,
  });

  const balance = walletInfo?.balance || 0;

  const hasInsufficientFunds = balance < MIN_WITHDRAW_BALANCE;

  const { data: gatewayInfo } = useGatewayInfo({
    lat: geoData?.lat,
    long: geoData?.long,
  });
  const isVerified = gatewayInfo?.status === "verified";

  const onWithdraw = (payload: WithdrawFundsPayload) => {
    withdrawFundsRequest(payload, {
      onSuccess: (data) => {
        enqueueSnackbar(data.data.message, {
          variant: "success",
        });
        queryClient.invalidateQueries(QueryKeys.GetUserWallet);
      },
      onError: (error: any) => {
        const errorMessage = error.response.data.message;
        enqueueSnackbar(errorMessage, {
          variant: "default",
        });
      },
    });
  };

  const onDeleteFundingSource = (fundingSourceId: string) => {
    const payload = {
      fundingSourceId,
    };

    deletePaymentSource(payload, {
      onSuccess: (data) => {
        enqueueSnackbar(data.data.message, {
          variant: "success",
        });
        queryClient.invalidateQueries(QueryKeys.PaymentSources);
      },
      onError: (error: any) => {
        const errorMessage = error.response.data.message;
        enqueueSnackbar(errorMessage, {
          variant: "default",
        });
      },
    });
  };

  return (
    <Fragment>
      <ProfileLayout>
        <WithdrawFundsTab
          isVerified={isVerified}
          hasInsufficientFunds={hasInsufficientFunds}
          onWithdraw={onWithdraw}
          fundingSources={
            tsevoSession?.PaymentMethods.filter(
              (paymentMethod: any) => paymentMethod.Type === "ACH"
            ) || []
          }
          availableBalance={balance}
          onDeleteFundingSource={onDeleteFundingSource}
        ></WithdrawFundsTab>
      </ProfileLayout>
    </Fragment>
  );
}

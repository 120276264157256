import { AxiosResponse } from "axios";
import { Auth } from "firebase/auth";
import { useMutation } from "react-query";
import useFirebaseAuth from "@shared/hooks/useFirebaseAuth";
import { supportMail } from "@shared/services/auth";

interface IVariables {
  message: string;
  userId: number;
  userName: string;
  email: string;
}

export function useSupportMail() {
  const { data: auth } = useFirebaseAuth();

  return useMutation<AxiosResponse<any, any>, unknown, IVariables, unknown>(
    (variables) => sendSupportMail(variables, auth)
  );
}

async function sendSupportMail(variables: IVariables, auth?: Auth) {
  if (!auth) {
    throw new Error("Incomplete credentials");
  }
  return supportMail(variables);
}

import { useQuery } from "react-query";
import { getFirebaseAuth } from "@shared/services/auth";
import { QueryKeys } from "@shared/constants/QueryKeys";

function useFirebaseAuth() {
  return useQuery(QueryKeys.FirebaseConfig, getFirebaseAuth, {
    staleTime: Infinity,
  });
}

export default useFirebaseAuth;

import React, { useMemo } from "react";
import { useSnackbar } from "notistack";
import { useQueryClient } from "react-query";
import { useForm } from "react-hook-form";
import dayjs from "dayjs";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import {
  Box,
  Dialog,
  DialogContent,
  DialogActions,
  Grid,
  Button,
  Stepper,
  Step,
  StepLabel,
  StepContent,
} from "@mui/material";

// import DialogTitle from "../DialogTitle";
import { USStates } from "@shared/constants/States";
import { DialogTitle } from "@shared/components/molecules/dialogTitle";
import { DatePickerControl } from "@shared/components/molecules/forms/DatePickerControl";
import { TextInputControl } from "@shared/components/molecules/forms/TextInputControl";
import { SelectControl } from "@shared/components/molecules/forms/SelectControl";
import { QueryKeys } from "@shared/constants/QueryKeys";

import { useCreatePaymentMethod } from "@shared/hooks/useCreatePaymentMethod";

export interface CreateFundingSourceProps {
  tsevoSession: {
    MerchantSessionID: string;
  };
  open: boolean;
  handleClose: Function;
}

interface IFormValues {
  step: number;
  displayName: string;
  cardNumber: string;
  expirationDate: Date;
  cvv: string;
  nameOnAccount: string;
  phoneNumber: string;
  network: {
    label: string;
    value: string;
  };
  billingAddress: {
    addressLine1: string;
    addressLine2: string;
    city: string;
    stateCode: {
      label: string;
      value: string;
    };
    postalCode: string;
    countryCode: string;
  };
}

const networks = [
  "Visa",
  "Mastercard",
  "Citibank",
  "Chase",
  "American Express",
  "Capital One",
];

const networkDropdownValues = networks.map((network) => {
  return {
    label: network,
    value: network,
  };
});

// interface IFormValues {
//   token?: string;
//   cardNumber?: string;
//   cvv: string;
//   expirationDate?: string;
//   amount: number;
//   // savePaymentMethod?: boolean;
// }

const CreateFundingSourceModal = (props: CreateFundingSourceProps) => {
  const { open, handleClose, tsevoSession } = props;
  const queryClient = useQueryClient();

  const stateDropdownData = useMemo(
    () =>
      USStates.map((state) => {
        return {
          label: state.text,
          value: state.value,
        };
      }),
    []
  );

  const { mutate: createPaymentMethod } = useCreatePaymentMethod();

  const { enqueueSnackbar } = useSnackbar();

  const onSuccess = (data: any) => {
    handleClose(true);
    queryClient.invalidateQueries(QueryKeys.CreateSession);
    enqueueSnackbar("Payment method added successfully", {
      variant: "success",
    });
  };
  const onError = (err: any) => {
    console.log(err);
    enqueueSnackbar("There was an error adding your payment method", {
      variant: "default",
    });
  };

  const formSchema = useMemo(
    () =>
      yup.object({
        CVV: yup.string().length(3),
      }),
    []
  );

  const methods = useForm<IFormValues>({
    resolver: yupResolver(formSchema),
    defaultValues: {
      step: 0,
      // Card Information
      displayName: "Test Card",
      cardNumber: "4111111111111111",
      expirationDate: undefined,
      network: {
        label: "Visa",
        value: "Visa",
      },
      cvv: "123",
      // Personal Information
      nameOnAccount: "Corey Chandler",
      phoneNumber: "123123123",
      billingAddress: {
        addressLine1: "66 Forest Street",
        addressLine2: "",
        city: "Reading",
        stateCode: {
          label: "Massachusetts",
          value: "MA",
        },
        postalCode: "01867",
        countryCode: "US",
      },
    },
  });

  const {
    watch,
    reset,
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = (data: any) => {
    const year = new Date(data.expirationDate).getFullYear();
    const month = new Date(data.expirationDate).getMonth() + 1;
    const date = new Date(data.expirationDate).getDate();
    const expirationDate = `${year}-${month}-${date}`;

    const payload = {
      merchantSessionId: tsevoSession.MerchantSessionID,
      // Card Information
      displayName: data.displayName,
      cardNumber: data.cardNumber,
      expirationDate: expirationDate,
      network: data.network.value,
      cvv: data.cvv,
      // Personal Information
      nameOnAccount: data.nameOnAccount,
      phoneNumber: data.phoneNumber,
      billingAddress: {
        addressLine1: data.billingAddress.addressLine1,
        addressLine2: data.billingAddress.addressLine2,
        city: data.billingAddress.city,
        stateCode: data.billingAddress.stateCode.value,
        postalCode: data.billingAddress.postalCode,
        countryCode: data.billingAddress.countryCode,
      },
    };
    createPaymentMethod(payload, {
      onSuccess,
      onError,
    });
  };

  return (
    <Dialog
      open={open || false}
      onClose={() => handleClose()}
      maxWidth="sm"
      fullWidth
      aria-labelledby="form-dialog-title"
      disableEscapeKeyDown
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle onClose={() => handleClose()}>
          Add a new payment method
        </DialogTitle>
        <DialogContent>
          <br />
          <Stepper activeStep={watch("step")} orientation="vertical">
            <Step key="Card Info" completed={false}>
              <StepLabel>Card Information</StepLabel>
              <StepContent>
                <Box mt={1}>
                  <Grid container direction="column" spacing={2}>
                    <Grid item xs={12} sm={12}>
                      <TextInputControl
                        control={control}
                        disabled={isSubmitting}
                        fullWidth
                        id="cardNumber"
                        label="Card Number"
                        required
                        size="small"
                        type="number"
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <DatePickerControl
                        control={control}
                        disabled={isSubmitting}
                        id="expirationDate"
                        label="Expiration Date"
                        helperText="mm/dd/yyyy"
                        required
                        size="small"
                        minDate={dayjs()}
                        variant="outlined"
                        views={["year", "month"]}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <TextInputControl
                        control={control}
                        disabled={isSubmitting}
                        fullWidth
                        size="small"
                        id="cvv"
                        label="CVV"
                        type="number"
                        required
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12} sm={3} md={3} lg={3}>
                      <SelectControl
                        id="network"
                        control={control}
                        isClearable
                        isSearchable
                        options={networkDropdownValues}
                        placeholder="Select Network"
                        rules={{ required: true }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <TextInputControl
                        control={control}
                        disabled={isSubmitting}
                        fullWidth
                        size="small"
                        id="displayName"
                        label="Card Display Name"
                        type="text"
                        required
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item>
                      <Button
                        variant="outlined"
                        disabled={watch("step") === 1}
                        onClick={() => {
                          const stepValue = getValues("step");
                          setValue("step", stepValue === 1 ? 1 : stepValue + 1);
                        }}
                      >
                        Next
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </StepContent>
            </Step>
            <Step key="Personal Info" completed={false}>
              <StepLabel>Personal Information</StepLabel>
              <StepContent>
                <Box mt={1}>
                  <Grid container direction="column" spacing={2}>
                    <Grid item xs={12} sm={12}>
                      <TextInputControl
                        control={control}
                        disabled={isSubmitting}
                        fullWidth
                        id="nameOnAccount"
                        label="Name on Account"
                        required
                        size="small"
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <TextInputControl
                        control={control}
                        disabled={isSubmitting}
                        fullWidth
                        size="small"
                        id="phoneNumber"
                        label="Phone Number"
                        type="number"
                        required
                        variant="outlined"
                      />
                    </Grid>

                    <Grid item xs={12} sm={12}>
                      <TextInputControl
                        control={control}
                        disabled={isSubmitting}
                        fullWidth
                        size="small"
                        id="billingAddress.addressLine1"
                        label="Address Line 1"
                        type="text"
                        required
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <TextInputControl
                        control={control}
                        disabled={isSubmitting}
                        fullWidth
                        size="small"
                        id="billingAddress.addressLine2"
                        label="Address Line 2"
                        type="text"
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <TextInputControl
                        control={control}
                        disabled={isSubmitting}
                        fullWidth
                        size="small"
                        id="billingAddress.city"
                        label="City"
                        type="text"
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <SelectControl
                        id="billingAddress.stateCode"
                        control={control}
                        isClearable
                        isSearchable
                        options={stateDropdownData}
                        placeholder="Select State"
                        rules={{ required: true }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <TextInputControl
                        control={control}
                        disabled={isSubmitting}
                        fullWidth
                        size="small"
                        id="billingAddress.postalCode"
                        label="Postal Code"
                        type="text"
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <TextInputControl
                        control={control}
                        disabled={isSubmitting}
                        fullWidth
                        size="small"
                        helperText="2 Character Country Code. Eg. US / CA"
                        id="billingAddress.countryCode"
                        label="Country Code"
                        type="text"
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item>
                      <Button
                        variant="outlined"
                        onClick={() => {
                          const stepValue = getValues("step");
                          setValue("step", stepValue === 0 ? 0 : stepValue - 1);
                        }}
                        disabled={watch("step") === 0}
                      >
                        Back
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </StepContent>
            </Step>
          </Stepper>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              reset();
              handleClose();
            }}
          >
            Cancel
          </Button>
          <Button
            disabled={isSubmitting || watch("step") !== 1}
            type="submit"
            variant="contained"
          >
            Add Payment Method
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
export default CreateFundingSourceModal;

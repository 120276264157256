import { Fragment } from "react";
// import { Wallet as WalletTab } from "@shared/components/templates/Wallet";
import ProfileLayout from "@src/components/templates/ProfileLayout";
import { useTransferInfo } from "@shared/hooks/useTransferInfo";
import {
  HeaderItem,
  RowItem,
  TransferList as TransferTab,
} from "@shared/components/organisms/transfers";
import {
  Card,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  dateRender,
  dateTimeRender,
  renderCurrency,
} from "@shared/helpers/currency";
import { useUserInfo } from "@shared/hooks/useUserInfo";

export function Transfers() {
  const { data } = useTransferInfo();
  const { data: userInfo } = useUserInfo();
  const userId = userInfo?.id;
  // const { data: gatewayInfo } = useGatewayInfo();

  interface TItem {
    id: number;
    creditorId?: number;
    debitorId?: number;
    createdAt: string;
    type: string;
    status: string;
    originatingService?: string;
    amount: number;
    narration: string;
  }
  function HeaderComponent() {
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down("md"));

    return isSmall ? <HeaderComponentSmall /> : <HeaderComponentLarge />;
  }

  function HeaderComponentSmall() {
    return (
      <Stack direction="row" spacing={2}>
        <Stack direction="row" flex={5} spacing={2}>
          <HeaderItem id="created" flex={1}>
            DATE
          </HeaderItem>
          <HeaderItem id="status" flex={1}>
            SERVICE
          </HeaderItem>
          <HeaderItem id="amount" flex={1}>
            AMOUNT
          </HeaderItem>
          <HeaderItem id="type" flex={1}>
            TYPE
          </HeaderItem>
        </Stack>
      </Stack>
    );
  }

  function HeaderComponentLarge() {
    return (
      <Stack direction="row" spacing={2}>
        <Stack direction="row" flex={5} spacing={2}>
          <HeaderItem id="created" flex={1}>
            DATE
          </HeaderItem>
          <HeaderItem id="type" flex={1}>
            TYPE
          </HeaderItem>
          <HeaderItem id="status" flex={1}>
            SERVICE
          </HeaderItem>
          <HeaderItem id="amount" flex={1}>
            AMOUNT
          </HeaderItem>
          <HeaderItem id="narration" flex={1}>
            NARRATION
          </HeaderItem>
        </Stack>
      </Stack>
    );
  }

  function RowComponentSmall(props: TItem) {
    const { createdAt, creditorId, originatingService, amount } = props;

    return (
      <Stack direction="row" mb={2} borderRadius={4} position="relative" spacing={2}>
        <RowItem flex={1}>
          <Typography>{dateRender(createdAt)}</Typography>
        </RowItem>
        <RowItem flex={1}>
          <Typography>{originatingService}</Typography>
        </RowItem>
        <RowItem flex={1}>
          <Typography>{renderCurrency(amount / 100)}</Typography>
        </RowItem>
        <RowItem flex={1}>
          <Typography>{creditorId === userId ? "Cr" : "Dr"}</Typography>
        </RowItem>
      </Stack>
    );
  }

  function RowComponentLarge(props: TItem) {
    const { createdAt, creditorId, originatingService, amount, narration } =
      props;
    return (
      <Stack direction="row" spacing={2}>
        <Stack
          alignItems="center"
          component={Card}
          direction="row"
          flex={5}
          spacing={2}
          paddingLeft={1}
          sx={{
            py: 1,
            height: 48,
          }}
        >
          <RowItem flex={1}>
            <Typography>{dateTimeRender(createdAt)}</Typography>
          </RowItem>
          <RowItem flex={1}>
            <Typography>
              {creditorId === userId ? "Paid" : "Received"}
            </Typography>
          </RowItem>
          <RowItem flex={1}>
            <Typography>{originatingService}</Typography>
          </RowItem>
          <RowItem flex={1}>
            <Typography>{renderCurrency(amount / 100)}</Typography>
          </RowItem>
          <RowItem flex={1}>
            <Typography>{narration}</Typography>
          </RowItem>
        </Stack>
      </Stack>
    );
  }

  function RowComponent(props: TItem) {
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down("md"));

    return isSmall ? (
      <RowComponentSmall {...props} />
    ) : (
      <RowComponentLarge {...props} />
    );
  }
  return (
    <Fragment>
      <ProfileLayout>
        <TransferTab
          data={data || { rows: [] }}
          heading="TRANSFERS"
          isSearchable
          renderHeader={() => <HeaderComponent />}
          renderRow={(row) => <RowComponent {...row} key={row.id} />}
        ></TransferTab>
      </ProfileLayout>
    </Fragment>
  );
}

import { AxiosResponse } from "axios";
import { useMutation } from "react-query";
import { createPaymentMethodRequest } from "@shared/services/wallet";

interface IVariables {
  merchantSessionId: string;
  displayName: string;
  cardNumber: number;
  expirationDate: string;
  network: string;
  cvv: number;
  nameOnAccount: string;
  phoneNumber: number;
  billingAddress: {
    addressLine1: string;
    addressLine2: string;
    city: string;
    stateCode: string;
    postalCode: string;
    countryCode: string;
  };
}

export function useCreatePaymentMethod() {
  return useMutation<AxiosResponse<any, any>, unknown, IVariables, unknown>(
    (payload: IVariables) => createPaymentMethod(payload)
  );
}

async function createPaymentMethod(payload: IVariables) {
  return createPaymentMethodRequest(payload);
}

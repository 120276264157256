import Grid from "@mui/material/Grid";
import { Fragment } from "react";
import { Helmet } from "react-helmet";
import { LogoFullLarge } from "@shared/components/atoms/logos/LogoFullLarge";
import PublicPagesNavBar from "@organisms/PublicPagesNavBar";

export interface Props {
  title: string;
}

const PublicPageLayout: React.FC<Props> = ({ children, title }) => {
  return (
    <Fragment>
      <Helmet>
        <title>TopProp | {title}</title>
      </Helmet>
      <Grid
        sx={{
          height: "100vh",
          paddingLeft: {
            xs: "2rem",
            md: "4rem",
          },
          paddingRight: {
            xs: "2rem",
            md: "4rem",
          },
          paddingTop: "2rem",
        }}
        container
      >
        <Grid item xs={12} container justifyContent="center">
          <LogoFullLarge width="50%" />
        </Grid>
        {children}
        <PublicPagesNavBar />
      </Grid>
    </Fragment>
  );
};

export default PublicPageLayout;

import { Fragment } from "react";
import { Grid, Box, Container, Chip } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

import { Sidebar } from "@shared/components/organisms/sidebar";
import GolfBallAlt from "@shared/icons/golf-ball-alt.svg";
import { ReactComponent as BattlegroundOutlineIcon } from "@shared/icons/battleground-outline.svg";
import { ReactComponent as BattlegroundContainedIcon } from "@shared/icons/battleground-contained.svg";
import allRoutes, { AppRoot, routesForApp } from "@shared/constants/routePaths";
import { useSignOut } from "@src/hooks/useSignOut";
import { Footer } from "@shared/components/organisms/footer";
import { Tabbar } from "@shared/components/organisms/tabbar";
import { TabPanel } from "@shared/components/molecules/tabPanel";
import { useGatewayInfo } from "@shared/hooks/useGatewayInfo";
import { DRAWER_WIDTH } from "@shared/components/tokens";
import { useGeoLocation } from "@shared/hooks/useGeolocation";

const routes = routesForApp<AppRoot.ACCOUNT>(AppRoot.ACCOUNT);

export interface Props {}

const ProfileLayout: React.FC<Props> = ({ children }) => {
  const { mutate: signout } = useSignOut();

  const geocodingKey = process.env.REACT_APP_GOOGLE_GEOCODING_KEY || "";
  const fallbackCountry = "IN";
  const fallbackState = "GA";

  const { data: geoData } = useGeoLocation({
    geocodingKey,
    fallbackCountry,
    fallbackState,
  });

  const { data: gatewayInfo } = useGatewayInfo({
    lat: geoData?.lat,
    long: geoData?.long,
  });

  // const navigate = useNavigate();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("md"));
  const status = gatewayInfo?.status as string;

  const items = [
    {
      label: "PGA",
      path: allRoutes.pga.battleground.lobby,
      activePaths: [allRoutes.pga.battleground.lobby],
      activeIcon: <img alt="sparkling trophy" src={GolfBallAlt} width="45px" />,
      inactiveIcon: (
        <img alt="sparkling trophy" src={GolfBallAlt} width="45px" />
      ),
      isExternal: true,
    },
    // {
    //   label: "NFL",
    //   path: allRoutes.nfl.battleground.lobby,
    //   activePaths: [allRoutes.nfl.battleground.lobby],
    //   activeIcon: <BattlegroundContainedIcon />,
    //   inactiveIcon: <BattlegroundOutlineIcon />,
    //   isExternal: true,
    // },
  ];

  const tabs = [
    {
      label: "Wallet",
      path: routes.profile.wallet,
    },
    {
      label: "Withdraw Funds",
      path: routes.profile.withdraw,
    },
    {
      label: "Transfers",
      path: routes.profile.transfers,
    },
    {
      label: "Account Verification",
      path: routes.profile.verify,
      badge:
        status !== "verified" ? (
          <Chip
            sx={{
              color: "red",
              border: "1px solid red",
              fontSize: "0.65rem",
            }}
            variant="outlined"
            label={status || "Loading..."}
            size="small"
          />
        ) : undefined,
    },
  ];

  const handleLogout = () => {
    signout();
  };

  return (
    <Fragment>
      <Grid
        container
        direction="column"
        justifyContent="space-between"
        sx={{ height: "100vh" }}
      >
        <Sidebar
          hasPaidContests={true}
          items={items}
          showAppBar={true}
          profilePagePath={`${allRoutes.account.profile.wallet}`}
          logout={() => {
            handleLogout();
          }}
        />
        <Box
          component="main"
          sx={{
            flexGrow: "1",
            overflow: "auto",
            width: "100%",
            height: "100%",
          }}
        >
          <Grid
            container
            direction="column"
            sx={{
              minHeight: "100%",
              paddingLeft: isSmall ? "auto" : `${DRAWER_WIDTH}px`,
            }}
          >
            <Box
              component="div"
              sx={{
                height: isSmall ? "4rem" : "auto",
                width: isSmall ? "100%" : "auto",
              }}
            ></Box>

            <Grid
              item
              container
              direction="column"
              justifyContent="space-between"
              sx={{
                height: "100%",
                flex: "1 1 0%",
              }}
            >
              <Grid
                item
                sx={{
                  width: "100%",
                }}
              >
                <Container maxWidth="xl" component="main">
                  <Box py={2} px={isSmall ? 1 : 0}>
                    <Tabbar tabs={tabs} accessabilityControlKey="profile-tab" />
                    <TabPanel tabs={tabs} accessabilityControlKey="profile-tab">
                      {children}
                    </TabPanel>
                  </Box>
                </Container>
              </Grid>
              <Grid
                item
                sx={{
                  width: "100%",
                }}
              >
                <Footer hideBorder={false} />
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Fragment>
  );
};

export default ProfileLayout;

import { Stack } from "@mui/material";
import { ReactNode, useMemo } from "react";
import { useRecoilValue } from "recoil";
import { searchState, sortCriteriaState } from "./ListState";

interface ListItemsProps<TItem> {
  items: Array<TItem>;
  render: (item: TItem) => ReactNode;
}

export function ListItems<TItem>(props: ListItemsProps<TItem>) {
  const { render } = props;
  const sortCriteria = useRecoilValue(sortCriteriaState);
  const search = useRecoilValue(searchState);

  const items = useMemo(() => {
    const items = props.items.filter((row) => {
      if (!search) {
        return true;
      }
      for (let val of Object.values(row)) {
        if (val === undefined || val === null) {
          return true;
        }
        if (`${val}`.toLowerCase().indexOf(search) >= 0) {
          return true;
        }
      }
      return false;
    });
    if (!sortCriteria) {
      return items;
    }
    return items.sort((a: any, b: any) => {
      if (sortCriteria.order === "ASC") {
        return a[sortCriteria.field] > b[sortCriteria.field] ? -1 : 1;
      }
      if (sortCriteria.order === "DESC") {
        return a[sortCriteria.field] < b[sortCriteria.field] ? -1 : 1;
      }
      return 0;
    });
  }, [props, props.items, search, sortCriteria]);

  return <Stack spacing={1}>{items.map((item) => render(item))}</Stack>;
}

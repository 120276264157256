import { Control, Controller, ControllerProps, Path } from "react-hook-form";
import Select, { Props as ReactSelectProps } from "react-select";
import * as FONTS from "@shared/components/tokens/fonts";
import { Stack, Typography } from "@mui/material";

type SelectControlProps<TFormFields> = Pick<ControllerProps, "rules"> &
  ReactSelectProps & {
    control: Control<TFormFields, object>;
    id: Path<TFormFields>;
    OptionComponent?: (props: any) => JSX.Element;
  };

// ref: https://react-select.com/props#replacing-components

export function SelectControl<TFormFields>(
  props: SelectControlProps<TFormFields>
) {
  const { control, id, OptionComponent, ...selectProps } = props;
  return (
    <Controller
      control={control}
      name={id}
      render={({ field, fieldState }) => (
        <Stack>
          <Select
            {...selectProps}
            {...field}
            menuPortalTarget={document.body}
            onChange={(selectedVal, actionMeta) => {
              field.onChange(selectedVal);
              if (selectProps.onChange) {
                try {
                  selectProps.onChange(selectedVal, actionMeta);
                } catch (_ex) {}
              }
            }}
            name={id}
            styles={{
              control: (provided) => ({
                ...provided,
                backgroundColor: "none",
                fontFamily: FONTS.MAIN,
                fontSize: "1rem",
              }),
              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
              option: (provided) => ({
                ...provided,
                fontFamily: FONTS.MAIN,
              }),
            }}
            components={{
              ...(OptionComponent
                ? {
                    Option: OptionComponent,
                  }
                : null),
            }}
          />
          {fieldState.error && (
            <Typography color="red" variant="caption">
              {fieldState.error.message}
            </Typography>
          )}
        </Stack>
      )}
    />
  );
}

import { ReactElement } from "react";
import { matchPath, useLocation } from "react-router-dom";
import { Typography, ButtonBase, Grid } from "@mui/material";

export interface SidebarItemProps {
  activePaths: Array<string>;
  handleLinkClick: Function;
  label: string;
  path: string;
  ActiveIcon: ReactElement;
  InactiveIcon: ReactElement;
}

export function SidebarItem({
  label,
  path,
  InactiveIcon,
  ActiveIcon,
  handleLinkClick,
  activePaths,
}: SidebarItemProps) {
  const { pathname } = useLocation();
  const isActive = activePaths.some((path) => matchPath(path, pathname));
  return (
    <ButtonBase
      onClick={() => {
        handleLinkClick(path);
      }}
      disableRipple
    >
      <Grid
        item
        container
        direction="column"
        justifyContent="center"
        alignContent="center"
      >
        <Grid item container justifyContent="center">
          {isActive ? ActiveIcon : InactiveIcon}
        </Grid>
        <Grid item container justifyContent="center">
          <Typography
            variant="h6"
            color={isActive ? "primary" : "initial"}
            gutterBottom
          >
            {label}
          </Typography>
        </Grid>
      </Grid>
    </ButtonBase>
  );
}

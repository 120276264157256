import {
  Avatar,
  Button,
  Divider,
  Grid,
  Popover,
  Stack,
  Typography,
} from "@mui/material";
import voca from "voca";
import { renderCurrency } from "@shared/helpers/currency";
import { useUserInfo } from "@shared/hooks/useUserInfo";
import { useWalletInfo } from "@shared/hooks/useWalletInfo";

interface UserMenuProps {
  anchorEl: Element | null;
  hasPaidContests: boolean;
  onClose: () => void;
  onOpenSupport: () => void;
  onLogout: () => void;
  profilePagePath: string;
}

export function UserMenu(props: UserMenuProps) {
  const {
    anchorEl,
    hasPaidContests,
    onClose,
    onOpenSupport,
    onLogout,
    profilePagePath,
  } = props;

  const { data: userInfoData } = useUserInfo();
  const { data: walletInfo } = useWalletInfo();

  return (
    <Popover
      open={Boolean(anchorEl)}
      {...(anchorEl ? { id: "usermenu-popover" } : null)}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
    >
      <Grid container direction="column" alignContent="center" p={4}>
        <Stack pb={4} direction="row" justifyContent="center">
          <Avatar color="primary">
            {userInfoData ? voca.first(userInfoData.fullName) : ""}
          </Avatar>
        </Stack>
        <Typography align="center">
          {userInfoData ? userInfoData.fullName : ""}
        </Typography>
        {hasPaidContests && (
          <Typography align="center">
            Available Funds:{" "}
            {walletInfo
              ? renderCurrency((walletInfo.balance ?? 0) / 100)
              : "N.A"}
          </Typography>
        )}
        <Divider sx={{ my: 4 }} />
        <Grid item container spacing={2}>
          {hasPaidContests && (
            <Grid item xs={12}>
              <Button
                color="primary"
                fullWidth
                href={profilePagePath}
                variant="contained"
              >
                Profile
              </Button>
            </Grid>
          )}
          <Grid item xs={6}>
            <Button
              onClick={onOpenSupport}
              disabled={userInfoData === null}
              variant="outlined"
              color="primary"
              fullWidth
            >
              Support
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              onClick={onLogout}
              variant="outlined"
              color="primary"
              fullWidth
            >
              Logout
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Popover>
  );
}

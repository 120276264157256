import { useMemo } from "react";
import { Theme } from "@mui/material/styles";
import {
  Card,
  Radio,
  Stack,
  Grid,
  Typography,
  useMediaQuery,
  Box,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { theme } from "@shared/components/tokens/theme";
// import VisaImage from "@shared/images/VisaBG_16rem.png";
import BankLogo from "@shared/icons/bank.svg";
import BankBG from "@shared/images/bank-bg.png";
import { PayoutMethodData } from "@shared/types";

export interface FundingSourceCardProps {
  fundingSource: PayoutMethodData;
  isSelected: boolean;
  onSelect: () => void;
  onDelete: (id: string) => void;
}

export function FundingSourceCard(props: FundingSourceCardProps) {
  const { fundingSource, onSelect, onDelete, isSelected } = props;

  const { AccountNumber, DisplayName, NameOnAccount, Type } = fundingSource;
  const isXtraSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const isMedium = useMediaQuery(theme.breakpoints.down("lg"));

  // const handleDelete = () => {
  //   onDelete(id);
  // };

  return (
    <Box sx={{ paddingTop: "1rem", paddingBottom: "1rem" }}>
      <Card
        sx={{
          display: "grid",
          height: {
            xs: "17rem",
          },
          width: "20rem",
          placeItems: "start",
          padding: "0rem 1rem 0.5rem 1rem",
          borderRadius: "1rem",
          backgroundImage: `url(${BankBG})`,
          backgroundSize: "cover",
          backgroundColor: (theme: Theme) =>
            isSelected ? theme.palette.primary.light : theme.palette.grey[300],
        }}
      >
        <Grid padding={2} container justifyItems="space-between">
          <Stack width="100%" alignItems="center">
            <Grid item xs={12} container justifyItems="space-between">
              <Grid item xs={6} sm={6} md={6}>
                <Box
                  component="img"
                  sx={{
                    width: "70%",
                    fill: "#fff",
                  }}
                  src={BankLogo}
                  alt={AccountNumber}
                />
              </Grid>
              <Grid
                item
                xs={3}
                sm={3}
                md={3}
                container
                justifyItems="flex-end"
              ></Grid>
              <Grid
                item
                xs={3}
                sm={3}
                md={3}
                container
                justifyContent="flex-end"
                alignContent="center"
                color="white"
                sx={{ cursor: "pointer" }}
              >
                <Radio
                  style={{ color: "#ffffff" }}
                  checked={isSelected}
                  onChange={onSelect}
                />
                {/* <Box
                  component={DeleteIcon}
                  sx={{
                    color: "#fff",
                    cursor: "pointer",
                  }}
                  // onClick={() => handleDelete()}
                /> */}
              </Grid>
            </Grid>
          </Stack>
          <Grid item xs={12}>
            <Typography
              color="white"
              sx={{
                color: "#fff",
                paddingTop: "1rem",
                paddingBottom: "1rem",
              }}
              variant="h5"
            >
              {AccountNumber}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography color="white" variant="h6" align="left">
              {NameOnAccount}
            </Typography>
          </Grid>
        </Grid>
      </Card>
    </Box>
  );
}

import {
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Box,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { DialogTitle } from "@shared/components/molecules/dialogTitle";
import { useUserInfo } from "@shared/hooks/useUserInfo";
import { useSupportMail } from "@shared/hooks/useSupportMail";
import { TextInputControl } from "@shared/components/molecules/forms/TextInputControl";

export interface SupportModalProps {
  isOpen: boolean;
  onClose: () => void;
}

interface IFormValues {
  email: string;
  name: string;
  message: string;
}

const CHARACTER_LIMIT = 620;

export function SupportModal(props: SupportModalProps) {
  const { isOpen, onClose } = props;
  // const { enqueueSnackbar } = useSnackbar();
  const { data: userInfo } = useUserInfo();
  const { mutate: sendSupportMail } = useSupportMail();

  const submitSupportTicket = ({ message, name, email }: IFormValues) => {
    userInfo &&
      sendSupportMail({
        userId: userInfo?.id,
        message,
        userName: name,
        email,
      });
  };

  const { control, handleSubmit, reset, watch } = useForm<IFormValues>({
    defaultValues: {
      email: userInfo?.email,
      name: userInfo?.fullName,
      message: "",
    },
  });

  const onSubmit = (data: IFormValues) => {
    if (!userInfo) {
      return;
    }
    submitSupportTicket(data);
    onClose();
    reset();
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      aria-labelledby="form-dialog-title"
    >
      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle onClose={onClose}>Raise a support ticket</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextInputControl
                id="name"
                control={control}
                disabled
                fullWidth
                name="name"
                size="small"
                variant="filled"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextInputControl
                id="email"
                control={control}
                disabled
                fullWidth
                name="email"
                size="small"
                variant="filled"
              />
            </Grid>
            <Grid item xs={12}>
              <TextInputControl
                id="message"
                control={control}
                size="small"
                label="message"
                variant="outlined"
                multiline
                rows={8}
                name="message"
                inputProps={{
                  maxLength: CHARACTER_LIMIT,
                }}
                helperText={`Character limit ${
                  watch("message").length
                }/${CHARACTER_LIMIT}`}
                fullWidth
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container justifyContent="flex-end">
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              lg={4}
              container
              justifyContent="center"
            >
              <Button
                className="text-white"
                color="primary"
                fullWidth
                variant="contained"
                disableElevation
                type="submit"
              >
                Send
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Box>
    </Dialog>
  );
}

import axios, { AxiosRequestConfig } from "axios";
import * as jwt from "jsonwebtoken";
import createAuthRefreshInterceptor from "axios-auth-refresh";

import {
  AUTH_BASE_URL,
  COMMON_API_BASE_URL,
  WALLET_API_BASE_URL,
} from "@shared/constants/ApiHosts";
import * as BrowserStorage from "@shared/constants/BrowserStorage";
import { renewAccessToken } from "./auth";
import { AccessTokenPayload } from "@shared/types";

export const axiosAuthInstance = axios.create({
  baseURL: AUTH_BASE_URL,
  headers: { "Content-Type": "application/json" },
});

export const axiosWalletInstance = axios.create({
  baseURL: WALLET_API_BASE_URL,
  headers: { "Content-Type": "application/json" },
});

export const axiosCommonInstance = axios.create({
  baseURL: COMMON_API_BASE_URL,
  headers: { "Content-Type": "application/json" },
});

async function refreshAuthLogic(failedRequest: any) {
  let accessToken = localStorage.getItem(BrowserStorage.ACCESS_TOKEN) || "";
  let refreshToken = localStorage.getItem(BrowserStorage.REFRESH_TOKEN) || "";
  let userData: AccessTokenPayload = jwt.decode(
    accessToken
  ) as AccessTokenPayload;
  let userInfo = userData?.data;

  const payload = {
    accessToken: accessToken || "",
    refreshToken: refreshToken || "",
    email: userInfo?.email || "",
  };

  const tokenRefreshResponse = await renewAccessToken(payload);

  localStorage.setItem(
    BrowserStorage.ACCESS_TOKEN,
    tokenRefreshResponse.data.data
  );
  failedRequest.response.config.headers["Authorization"] =
    "Bearer " + tokenRefreshResponse.data.data;

  return Promise.resolve();
}

axiosAuthInstance.interceptors.request.use(
  function (config: AxiosRequestConfig) {
    let token = localStorage.getItem(BrowserStorage.ACCESS_TOKEN);
    if (token && config.headers) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axiosWalletInstance.interceptors.request.use(
  function (config: AxiosRequestConfig) {
    let token = localStorage.getItem(BrowserStorage.ACCESS_TOKEN);
    if (token && config.headers) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axiosCommonInstance.interceptors.request.use(
  function (config: AxiosRequestConfig) {
    let token = localStorage.getItem(BrowserStorage.ACCESS_TOKEN);
    if (token && config.headers) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

createAuthRefreshInterceptor(axiosAuthInstance, refreshAuthLogic);
createAuthRefreshInterceptor(axiosWalletInstance, refreshAuthLogic);
createAuthRefreshInterceptor(axiosCommonInstance, refreshAuthLogic);

import { SyntheticEvent, useState } from "react";
import { Box, Tabs } from "@mui/material";
import { matchPath, useLocation } from "react-router-dom";
import { TabLink } from "@shared/components/molecules/tabLink";

export interface TabProps {
  label: string;
  path: string;
  badge?: JSX.Element;
}

export interface TabbarProps {
  tabs: TabProps[];
  accessabilityControlKey?: string;
}

export function Tabbar(props: TabbarProps) {
  const { tabs, accessabilityControlKey } = props;
  const { pathname } = useLocation();
  const matchedPathIndex = tabs.findIndex((tab) =>
    matchPath(tab.path, pathname)
  );
  const [value, setValue] = useState(matchedPathIndex);

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
      <Tabs
        textColor="primary"
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="auto"
        aria-label="topprop application tab"
      >
        {tabs.map((tab, index) => (
          <TabLink
            key={tab.path}
            label={tab.label}
            path={tab.path}
            badge={tab.badge}
            index={index}
            ariaControlKey={accessabilityControlKey}
          />
        ))}
      </Tabs>
    </Box>
  );
}

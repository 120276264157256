import {
  AppBar as MuiAppBar,
  Grid,
  IconButton,
  Toolbar,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import MenuIcon from "@mui/icons-material/Menu";
import { LogoFullLarge } from "@shared/components/atoms/logos/LogoFullLarge";
import { DRAWER_WIDTH } from "@shared/components/tokens";

interface AppBarProps {
  isDisabled?: boolean;
  onOpenDrawer: () => void;
}

export function AppBar({ isDisabled = false, onOpenDrawer }: AppBarProps) {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("md"));

  if (isDisabled || !isSmall) {
    return null;
  }

  return (
    <MuiAppBar position="fixed">
      <Toolbar sx={{ bgcolor: grey[50] }}>
        <Grid container>
          <IconButton
            color="primary"
            aria-label="open drawer"
            edge="start"
            onClick={onOpenDrawer}
          >
            <MenuIcon />
          </IconButton>
          <LogoFullLarge width={DRAWER_WIDTH} />
        </Grid>
      </Toolbar>
    </MuiAppBar>
  );
}

import { Box, Button, ButtonBase, Stack, Typography } from "@mui/material";
import { useRecoilState } from "recoil";
import SortIcon from "@mui/icons-material/UnfoldMore";
import DownIcon from "@mui/icons-material/ExpandMore";
import UpIcon from "@mui/icons-material/ExpandLess";
import { sortCriteriaState } from "./ListState";
import { grey } from "@mui/material/colors";

interface HeaderItemProps {
  children?: string;
  flex?: number;
  id?: string;
  isSortable?: boolean;
  width?: number;
}

export function HeaderItem(props: HeaderItemProps) {
  const { children, flex, id, isSortable, width } = props;
  const [sortCriteria, setSortCritera] = useRecoilState(sortCriteriaState);

  const handleToggleSort = () => {
    if (!id || !isSortable) {
      return;
    }
    setSortCritera({
      ...sortCriteria,
      field: id,
      order: sortCriteria?.order === "ASC" ? "DESC" : "ASC",
    });
  };

  const Contents = (
    <Stack direction="row">
      <Typography color={grey[600]}>{children}</Typography>
      {id && isSortable ? (
        sortCriteria?.order === "ASC" && sortCriteria?.field === id ? (
          <DownIcon fontSize="small" sx={{ opacity: 0.6 }} />
        ) : sortCriteria?.order === "DESC" && sortCriteria?.field === id ? (
          <UpIcon fontSize="small" sx={{ opacity: 0.6 }} />
        ) : (
          <SortIcon fontSize="small" sx={{ opacity: 0.1 }} />
        )
      ) : null}
    </Stack>
  );

  return (
    <Box
      {...(width ? { width } : null)}
      {...(flex && !width ? { flex } : null)}
    >
      {isSortable ? (
        <ButtonBase
          component="div"
          disableRipple
          disableTouchRipple
          focusRipple={false}
          onClick={handleToggleSort}
        >
          {Contents}
        </ButtonBase>
      ) : (
        Contents
      )}
    </Box>
  );
}

import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { FourOhFour as FourOhFourTemplate } from "@shared/components/templates/FourOhFour";
import { AppRoot, routesForApp } from "@shared/constants/routePaths";
import BaseLayout from "@src/components/templates/BaseLayout";

const routes = routesForApp<AppRoot.ACCOUNT>(AppRoot.ACCOUNT);

export function FourOhFour() {
  const navigate = useNavigate();

  const onReturnToDashboard = useCallback(async () => {
    navigate(routes.login);
  }, [navigate]);

  return (
    <BaseLayout>
      <FourOhFourTemplate onReturnToDashboard={onReturnToDashboard} />
    </BaseLayout>
  );
}

import React, { useMemo } from "react";
import { useSnackbar } from "notistack";
import { useQueryClient } from "react-query";
import { useForm } from "react-hook-form";
import dayjs from "dayjs";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import {
  Box,
  Dialog,
  DialogContent,
  DialogActions,
  Grid,
  Button,
  Stepper,
  Step,
  StepLabel,
  StepContent,
} from "@mui/material";

// import DialogTitle from "../DialogTitle";
import { USStates } from "@shared/constants/States";
import { DialogTitle } from "@shared/components/molecules/dialogTitle";
import { TextInputControl } from "@shared/components/molecules/forms/TextInputControl";
import { SelectControl } from "@shared/components/molecules/forms/SelectControl";
import { QueryKeys } from "@shared/constants/QueryKeys";

import { useCreatePayoutMethod } from "@shared/hooks/useCreatePayoutMethod";

export interface CreateFundingSourceProps {
  tsevoSession: {
    MerchantSessionID: string;
  };
  open: boolean;
  handleClose: Function;
  isError: boolean;
  geoData:
    | {
        state: string;
        country: string;
      }
    | undefined;
}

interface IFormValues {
  step: number;
  displayName: string;
  accountNumber: string;
  routingNumber: string;
  nameOnAccount: string;
  phoneNumber: string;
  billingAddress: {
    addressLine1: string;
    addressLine2: string;
    city: string;
    stateCode: {
      label: string;
      value: string;
    };
    postalCode: string;
    countryCode: string;
  };
}

// interface IFormValues {
//   token?: string;
//   cardNumber?: string;
//   cvv: string;
//   expirationDate?: string;
//   amount: number;
//   // savePaymentMethod?: boolean;
// }

const CreateFundingSourceModal = (props: CreateFundingSourceProps) => {
  const { open, handleClose, tsevoSession } = props;
  const queryClient = useQueryClient();

  const stateDropdownData = useMemo(
    () =>
      USStates.map((state) => {
        return {
          label: state.text,
          value: state.value,
        };
      }),
    []
  );

  const { mutate: createPayoutMethod } = useCreatePayoutMethod();

  const { enqueueSnackbar } = useSnackbar();

  const onSuccess = (data: any) => {
    handleClose(true);
    queryClient.invalidateQueries(QueryKeys.CreateSession);
    enqueueSnackbar("Payout method added successfully", {
      variant: "success",
    });
  };
  const onError = (err: any) => {
    console.log(err);
    enqueueSnackbar("There was an error adding your payout method", {
      variant: "default",
    });
  };

  const formSchema = useMemo(
    () =>
      yup.object({
        CVV: yup.string().length(3),
      }),
    []
  );

  const methods = useForm<IFormValues>({
    resolver: yupResolver(formSchema),
    defaultValues: {
      step: 0,
      // Card Information
      displayName: "Test ACH",
      accountNumber: "123456789",
      routingNumber: "999999999",
      // Personal Information
      nameOnAccount: "Andrew Siegfried",
      phoneNumber: "123123123",
      billingAddress: {
        addressLine1: "321 Greenwood Lane",
        addressLine2: "",
        city: "Williston",
        stateCode: {
          label: "Vermont",
          value: "VT",
        },
        postalCode: "05495",
        countryCode: "US",
      },
    },
  });

  const {
    watch,
    reset,
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = (data: any) => {
    const payload = {
      merchantSessionId: tsevoSession.MerchantSessionID,
      // Card Information
      displayName: data.displayName,
      accountNumber: data.accountNumber,
      routingNumber: data.routingNumber,
      // Personal Information
      nameOnAccount: data.nameOnAccount,
      phoneNumber: data.phoneNumber,
      billingAddress: {
        addressLine1: data.billingAddress.addressLine1,
        addressLine2: data.billingAddress.addressLine2,
        city: data.billingAddress.city,
        stateCode: data.billingAddress.stateCode.value,
        postalCode: data.billingAddress.postalCode,
        countryCode: data.billingAddress.countryCode,
      },
    };
    createPayoutMethod(payload, {
      onSuccess,
      onError,
    });
  };

  return (
    <Dialog
      open={open || false}
      onClose={() => handleClose()}
      maxWidth="sm"
      fullWidth
      aria-labelledby="form-dialog-title"
      disableEscapeKeyDown
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle onClose={() => handleClose()}>
          Add a new payout destination
        </DialogTitle>
        <DialogContent>
          <br />
          <Stepper activeStep={watch("step")} orientation="vertical">
            <Step key="Card Info" completed={false}>
              <StepLabel>Bank Account Information</StepLabel>
              <StepContent>
                <Box mt={1}>
                  <Grid container direction="column" spacing={2}>
                    <Grid item xs={12} sm={12}>
                      <TextInputControl
                        control={control}
                        disabled={isSubmitting}
                        fullWidth
                        id="accountNumber"
                        label="Account Number"
                        required
                        size="small"
                        type="number"
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <TextInputControl
                        control={control}
                        disabled={isSubmitting}
                        fullWidth
                        size="small"
                        id="routingNumber"
                        label="Routing Number"
                        type="number"
                        required
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <TextInputControl
                        control={control}
                        disabled={isSubmitting}
                        fullWidth
                        size="small"
                        id="displayName"
                        label="Account Display Name"
                        type="text"
                        required
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item>
                      <Button
                        variant="outlined"
                        disabled={watch("step") === 1}
                        onClick={() => {
                          const stepValue = getValues("step");
                          setValue("step", stepValue === 1 ? 1 : stepValue + 1);
                        }}
                      >
                        Next
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </StepContent>
            </Step>
            <Step key="Personal Info" completed={false}>
              <StepLabel>Personal Information</StepLabel>
              <StepContent>
                <Box mt={1}>
                  <Grid container direction="column" spacing={2}>
                    <Grid item xs={12} sm={12}>
                      <TextInputControl
                        control={control}
                        disabled={isSubmitting}
                        fullWidth
                        id="nameOnAccount"
                        label="Name on Account"
                        required
                        size="small"
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <TextInputControl
                        control={control}
                        disabled={isSubmitting}
                        fullWidth
                        size="small"
                        id="phoneNumber"
                        label="Phone Number"
                        type="number"
                        required
                        variant="outlined"
                      />
                    </Grid>

                    <Grid item xs={12} sm={12}>
                      <TextInputControl
                        control={control}
                        disabled={isSubmitting}
                        fullWidth
                        size="small"
                        id="billingAddress.addressLine1"
                        label="Address Line 1"
                        type="text"
                        required
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <TextInputControl
                        control={control}
                        disabled={isSubmitting}
                        fullWidth
                        size="small"
                        id="billingAddress.addressLine2"
                        label="Address Line 2"
                        type="text"
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <TextInputControl
                        control={control}
                        disabled={isSubmitting}
                        fullWidth
                        size="small"
                        id="billingAddress.city"
                        label="City"
                        type="text"
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <SelectControl
                        id="billingAddress.stateCode"
                        control={control}
                        isClearable
                        isSearchable
                        options={stateDropdownData}
                        placeholder="Select State"
                        rules={{ required: true }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <TextInputControl
                        control={control}
                        disabled={isSubmitting}
                        fullWidth
                        size="small"
                        id="billingAddress.postalCode"
                        label="Postal Code"
                        type="text"
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <TextInputControl
                        control={control}
                        disabled={isSubmitting}
                        fullWidth
                        size="small"
                        helperText="2 Character Country Code. Eg. US / CA"
                        id="billingAddress.countryCode"
                        label="Country Code"
                        type="text"
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item>
                      <Button
                        variant="outlined"
                        onClick={() => {
                          const stepValue = getValues("step");
                          setValue("step", stepValue === 0 ? 0 : stepValue - 1);
                        }}
                        disabled={watch("step") === 0}
                      >
                        Back
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </StepContent>
            </Step>
          </Stepper>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              reset();
              handleClose();
            }}
          >
            Cancel
          </Button>
          <Button
            disabled={isSubmitting || watch("step") !== 1}
            type="submit"
            variant="contained"
          >
            Add Payout Destination
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
export default CreateFundingSourceModal;

// @ts-nocheck
import React from "react";
import { Box } from "@mui/material";

const PrivacyPolicy = () => {
  return (
    <Box sx={{ paddingBottom: "6rem" }}>
      <div title="header">
        <p
          style={{
            marginBottom: "0cm",
            border: "none",
            padding: "0cm",
            lineHeight: "100%",
          }}
        >
          <br />
        </p>
      </div>
      <p
        align="center"
        style={{
          marginBottom: "0.18cm",
          border: "none",
          padding: "0cm",
          lineHeight: "100%",
        }}
      >
        <font color="#212529">
          <font face="Helvetica Neue, serif">
            <font size={3} style={{ fontSize: "15pt" }}>
              <b>Privacy Policy</b>
            </font>
          </font>
        </font>
      </p>
      <p
        align="justifyContent"
        style={{
          marginBottom: "0.18cm",
          border: "none",
          padding: "0cm",
          lineHeight: "100%",
        }}
      >
        <font color="#212529">
          <font face="Helvetica Neue, serif">
            <font size={2} style={{ fontSize: "10pt" }}>
              Last Revised:{" "}
            </font>
          </font>
        </font>
        <font color="#212529">
          <font face="Helvetica Neue, serif">
            <font size={2} style={{ fontSize: "10pt" }}>
              August 1, 2022
            </font>
          </font>
        </font>
      </p>
      <p style={{ marginBottom: "0cm", lineHeight: "100%" }}>
        <font color="#212529">
          <font face="Helvetica Neue, serif">
            <font size={2} style={{ fontSize: "10pt" }}>
              Here at TopProp Corporation (“
            </font>
          </font>
        </font>
        <font color="#212529">
          <font face="Helvetica Neue, serif">
            <font size={2} style={{ fontSize: "10pt" }}>
              <b>TopProp</b>
            </font>
          </font>
        </font>
        <font color="#212529">
          <font face="Helvetica Neue, serif">
            <font size={2} style={{ fontSize: "10pt" }}>
              ”, “
            </font>
          </font>
        </font>
        <font color="#212529">
          <font face="Helvetica Neue, serif">
            <font size={2} style={{ fontSize: "10pt" }}>
              <b>we</b>
            </font>
          </font>
        </font>
        <font color="#212529">
          <font face="Helvetica Neue, serif">
            <font size={2} style={{ fontSize: "10pt" }}>
              ”, “
            </font>
          </font>
        </font>
        <font color="#212529">
          <font face="Helvetica Neue, serif">
            <font size={2} style={{ fontSize: "10pt" }}>
              <b>us</b>
            </font>
          </font>
        </font>
        <font color="#212529">
          <font face="Helvetica Neue, serif">
            <font size={2} style={{ fontSize: "10pt" }}>
              ”, “
            </font>
          </font>
        </font>
        <font color="#212529">
          <font face="Helvetica Neue, serif">
            <font size={2} style={{ fontSize: "10pt" }}>
              <b>our</b>
            </font>
          </font>
        </font>
        <font color="#212529">
          <font face="Helvetica Neue, serif">
            <font size={2} style={{ fontSize: "10pt" }}>
              ”), we work hard to build and maintain a relationship of trust
              with you. This Privacy Policy&nbsp;(“
            </font>
          </font>
        </font>
        <font color="#212529">
          <font face="Helvetica Neue, serif">
            <font size={2} style={{ fontSize: "10pt" }}>
              <b>Privacy Policy</b>
            </font>
          </font>
        </font>
        <font color="#212529">
          <font face="Helvetica Neue, serif">
            <font size={2} style={{ fontSize: "10pt" }}>
              ”) describes how we collect, use, and disclose information in
              connection with your access and use of all websites, applications,
              and other online products and services provided by us that link to
              this Privacy Policy, including www.toppropfantasy.com (and all
              related subdomains) (the “
            </font>
          </font>
        </font>
        <font color="#212529">
          <font face="Helvetica Neue, serif">
            <font size={2} style={{ fontSize: "10pt" }}>
              <b>Site</b>
            </font>
          </font>
        </font>
        <font color="#212529">
          <font face="Helvetica Neue, serif">
            <font size={2} style={{ fontSize: "10pt" }}>
              ”) and related online and offline services thereto, including any
              services, transactions, or engagement you might have with us
              (collectively, the “
            </font>
          </font>
        </font>
        <font color="#212529">
          <font face="Helvetica Neue, serif">
            <font size={2} style={{ fontSize: "10pt" }}>
              <b>Services</b>
            </font>
          </font>
        </font>
        <font color="#212529">
          <font face="Helvetica Neue, serif">
            <font size={2} style={{ fontSize: "10pt" }}>
              ”).{" "}
            </font>
          </font>
        </font>
      </p>
      <p style={{ marginBottom: "0cm", lineHeight: "100%" }}>
        <br />
      </p>
      <p
        align="justifyContent"
        style={{
          marginBottom: "0.18cm",
          border: "none",
          padding: "0cm",
          lineHeight: "100%",
        }}
      >
        <font color="#212529">
          <font face="Helvetica Neue, serif">
            <font size={2} style={{ fontSize: "10pt" }}>
              Please carefully read this Privacy Policy, which is part of
              our&nbsp;
            </font>
          </font>
        </font>
        <font color="#007bff">
          <font face="Helvetica Neue, serif">
            <font size={2} style={{ fontSize: "10pt" }}>
              <u>Terms of Service</u>
            </font>
          </font>
        </font>
        <font color="#212529">
          <font face="Helvetica Neue, serif">
            <font size={2} style={{ fontSize: "10pt" }}>
              , before using our Services. If you do not agree with the&nbsp;
            </font>
          </font>
        </font>
        <font color="#007bff">
          <font face="Helvetica Neue, serif">
            <font size={2} style={{ fontSize: "10pt" }}>
              <u>Terms of Service</u>
            </font>
          </font>
        </font>
        <font color="#000000">
          <font face="Helvetica Neue, serif">
            <font size={2} style={{ fontSize: "10pt" }}>
              {" "}
            </font>
          </font>
        </font>
        <font color="#212529">
          <font face="Helvetica Neue, serif">
            <font size={2} style={{ fontSize: "10pt" }}>
              and/or this Privacy Policy, you must refrain from using our
              Services. By accessing or using our Services, you agree to our use
              of your information consistent with the&nbsp;
            </font>
          </font>
        </font>
        <font color="#007bff">
          <font face="Helvetica Neue, serif">
            <font size={2} style={{ fontSize: "10pt" }}>
              <u>Terms of Service</u>
            </font>
          </font>
        </font>
        <font color="#007bff">
          <font face="Helvetica Neue, serif">
            <font size={2} style={{ fontSize: "10pt" }}>
              {" "}
            </font>
          </font>
        </font>
        <font color="#212529">
          <font face="Helvetica Neue, serif">
            <font size={2} style={{ fontSize: "10pt" }}>
              and this Privacy Policy. Minors (those under the age of 18 years)
              are not eligible to use our Services.
            </font>
          </font>
        </font>
        <font color="#000000">
          <font face="Helvetica Neue, serif">
            <font size={2} style={{ fontSize: "10pt" }}>
              {" "}
            </font>
          </font>
        </font>
        <font color="#212529">
          <font face="Helvetica Neue, serif">
            <font size={2} style={{ fontSize: "10pt" }}>
              <br />{" "}
            </font>
          </font>
        </font>
        <br />
        <br />
      </p>
      <p
        align="justifyContent"
        style={{
          marginBottom: "0.18cm",
          border: "none",
          padding: "0cm",
          lineHeight: "100%",
        }}
      >
        <font color="#212529">
          <font face="Helvetica Neue, serif">
            <font size={2} style={{ fontSize: "10pt" }}>
              <b>PRIVACY POLICY CHANGES</b>
            </font>
          </font>
        </font>
      </p>
      <p
        align="justifyContent"
        style={{
          marginBottom: "0.18cm",
          border: "none",
          padding: "0cm",
          lineHeight: "100%",
        }}
      >
        <font color="#212529">
          <font face="Helvetica Neue, serif">
            <font size={2} style={{ fontSize: "10pt" }}>
              This Privacy Policy is subject to change. We reserve the right to
              update or modify this Privacy Policy at any time to reflect
              changes in the law, our data collection and use practices, the
              features of our Services, or advances in technology. We encourage
              you to review this Privacy Policy frequently for any revisions or
              amendments. Changes to this Privacy Policy will be made accessible
              through use of the Services with an updated “Last Revised” date.
              You will be deemed to have been made aware of and have accepted
              the changes by your continued use of our Services.
              <br />{" "}
            </font>
          </font>
        </font>
        <br />
        <br />
      </p>
      <p
        align="justifyContent"
        style={{
          marginBottom: "0.18cm",
          border: "none",
          padding: "0cm",
          lineHeight: "100%",
        }}
      >
        <font color="#212529">
          <font face="Helvetica Neue, serif">
            <font size={2} style={{ fontSize: "10pt" }}>
              <b>INFORMATION WE COLLECT</b>
            </font>
          </font>
        </font>
      </p>
      <p
        align="justifyContent"
        style={{
          marginBottom: "0.18cm",
          border: "none",
          padding: "0cm",
          lineHeight: "100%",
        }}
      >
        <font color="#212529">
          <font face="Helvetica Neue, serif">
            <font size={2} style={{ fontSize: "10pt" }}>
              <b>Information You Provide </b>
            </font>
          </font>
        </font>
        <font color="#212529">
          <font face="Helvetica Neue, serif">
            <font size={2} style={{ fontSize: "10pt" }}>
              <b>To Us</b>
            </font>
          </font>
        </font>
      </p>
      <p
        align="justifyContent"
        style={{
          marginBottom: "0.18cm",
          border: "none",
          padding: "0cm",
          lineHeight: "100%",
        }}
      >
        <font color="#212529">
          <font face="Helvetica Neue, serif">
            <font size={2} style={{ fontSize: "10pt" }}>
              We collect information you choose to share with us, including
              information you enter through the Services. For example, if you
              are a user of the Services or visitor to the Site, when you:
            </font>
          </font>
        </font>
      </p>
      <ul>
        <li>
          <p
            align="justifyContent"
            style={{
              marginBottom: "0.18cm",
              border: "none",
              padding: "0cm",
              lineHeight: "100%",
            }}
          >
            <font color="#212529">
              <font face="Helvetica Neue, serif">
                <font size={2} style={{ fontSize: "10pt" }}>
                  <b>
                    Create an account to use the Services or request further
                    information about the Services
                  </b>
                </font>
              </font>
            </font>
            <font color="#212529">
              <font face="Helvetica Neue, serif">
                <font size={2} style={{ fontSize: "10pt" }}>
                  , we may collect contact information (such as your name, email
                  address, phone number, and postal address), registration data
                  (such as your username, password, and date of birth), and
                  account profile information (such as your
                </font>
              </font>
            </font>
            <font color="#212529">
              <font face="Helvetica Neue, serif">
                <font size={2} style={{ fontSize: "10pt" }}>
                  profile photo
                </font>
              </font>
            </font>
            <font color="#212529">
              <font face="Helvetica Neue, serif">
                <font size={2} style={{ fontSize: "10pt" }}>
                  , team and player preferences);
                </font>
              </font>
            </font>
          </p>
        </li>
        <li>
          <p
            align="justifyContent"
            style={{
              marginBottom: "0.18cm",
              border: "none",
              padding: "0cm",
              lineHeight: "100%",
            }}
          >
            <font color="#212529">
              <font face="Helvetica Neue, serif">
                <font size={2} style={{ fontSize: "10pt" }}>
                  <b>Verify your identity</b>
                </font>
              </font>
            </font>
            <font color="#212529">
              <font face="Helvetica Neue, serif">
                <font size={2} style={{ fontSize: "10pt" }}>
                  , we may collect your Social Security Number, driver’s license
                  information, passport information, permanent and temporary
                  address, tax-related information, and other information and
                  documentation required to verify your identity in compliance
                  with applicable laws and regulations.
                </font>
              </font>
            </font>
          </p>
        </li>
        <li>
          <p
            align="justifyContent"
            style={{
              marginBottom: "0.18cm",
              border: "none",
              padding: "0cm",
              lineHeight: "100%",
            }}
          >
            <font color="#212529">
              <font face="Helvetica Neue, serif">
                <font size={2} style={{ fontSize: "10pt" }}>
                  <b>
                    Deposit funds, enter contests, and interact with the
                    Services
                  </b>
                </font>
              </font>
            </font>
            <font color="#212529">
              <font face="Helvetica Neue, serif">
                <font size={2} style={{ fontSize: "10pt" }}>
                  , we may collect information related to depositing funds (such
                  as billing and payment processing information, including
                  credit card, bank account, and ACH information), entering and
                  participating in a contest, and withdrawing prizes (such as
                  your contact information, location information, and billing
                  and payment processing information), information related to
                  your use of our Services (such as information about your
                  integrated fantasy sports accounts and leagues), as well as
                  other information you provide through your use of the Services
                  (such as your contest history, in-service friends and
                  connections, communications in our in-service chat features
                  and message boards, transaction history and in-service
                  purchases, and preferences);
                </font>
              </font>
            </font>
          </p>
        </li>
        <li>
          <p
            align="justifyContent"
            style={{
              marginBottom: "0.18cm",
              border: "none",
              padding: "0cm",
              lineHeight: "100%",
            }}
          >
            <font color="#212529">
              <font face="Helvetica Neue, serif">
                <font size={2} style={{ fontSize: "10pt" }}>
                  <b>Contact us with questions or for customer service</b>
                </font>
              </font>
            </font>
            <font color="#212529">
              <font face="Helvetica Neue, serif">
                <font size={2} style={{ fontSize: "10pt" }}>
                  , we may collect contact information (such as your name, email
                  address, phone number, and postal address) as well as any
                  information you provide in connection with your outreach; and
                </font>
              </font>
            </font>
          </p>
        </li>
        <li>
          <p
            align="justifyContent"
            style={{
              marginBottom: "0.18cm",
              border: "none",
              padding: "0cm",
              lineHeight: "100%",
            }}
          >
            <font color="#212529">
              <font face="Helvetica Neue, serif">
                <font size={2} style={{ fontSize: "10pt" }}>
                  <b>
                    Participate in promotions, events, surveys, and sweepstakes
                  </b>
                </font>
              </font>
            </font>
            <font color="#212529">
              <font face="Helvetica Neue, serif">
                <font size={2} style={{ fontSize: "10pt" }}>
                  , we may collect basic contact information and any other
                  information you provide in connection with these activities.
                </font>
              </font>
            </font>
          </p>
        </li>
      </ul>
      <p
        align="justifyContent"
        style={{
          marginLeft: "1.06cm",
          marginBottom: "0.18cm",
          border: "none",
          padding: "0cm",
          lineHeight: "100%",
        }}
      >
        <br />
        <br />
      </p>
      <p
        align="justifyContent"
        style={{
          marginBottom: "0.18cm",
          border: "none",
          padding: "0cm",
          lineHeight: "100%",
        }}
      >
        <font color="#212529">
          <font face="Helvetica Neue, serif">
            <font size={2} style={{ fontSize: "10pt" }}>
              <b>Information We Collect Through Automated Means</b>
            </font>
          </font>
        </font>
        <font color="#212529">
          <font face="Helvetica Neue, serif">
            <font size={2} style={{ fontSize: "10pt" }}>
              {" "}
            </font>
          </font>
        </font>
      </p>
      <p
        align="justifyContent"
        style={{
          marginBottom: "0.18cm",
          border: "none",
          padding: "0cm",
          lineHeight: "100%",
        }}
      >
        <font color="#212529">
          <font face="Helvetica Neue, serif">
            <font size={2} style={{ fontSize: "10pt" }}>
              We may automatically collect, receive, and store certain
              information in connection with the actions you take on the
              Services and in connection with use of the Services (“
            </font>
          </font>
        </font>
        <font color="#212529">
          <font face="Helvetica Neue, serif">
            <font size={2} style={{ fontSize: "10pt" }}>
              <b>Device and Usage Data</b>
            </font>
          </font>
        </font>
        <font color="#212529">
          <font face="Helvetica Neue, serif">
            <font size={2} style={{ fontSize: "10pt" }}>
              ”). As discussed further below in the “Cookies &amp; Similar
              Technologies” section, we and our service providers, may use a
              variety of technologies, including cookies, to assist in this
              information collection. For example, each time you use the
              Services, we automatically collect the type of web browser and
              operating system you use, the type of device you use, your IP
              address, Internet service provider, unique device identifiers, and
              other information in accordance with your device settings and
              permissions. We may also collect the pages you view, referring and
              exit pages, the date and time of your visit, the number of clicks
              to, from, and within the Services, time spent on each page, usage
              preferences, and search terms.
            </font>
          </font>
        </font>
      </p>
      <p style={{ marginBottom: "0cm", lineHeight: "100%" }}>
        <br />
      </p>
      <p style={{ marginBottom: "0cm", lineHeight: "100%" }}>
        <font color="#212529">
          <font face="Helvetica Neue, serif">
            <font size={2} style={{ fontSize: "10pt" }}>
              We may also collect different types of information about your
              location, including general location information (such as your IP
              address, city, state, and ZIP code) and more specific information
              (such a
            </font>
          </font>
        </font>
        <font face="Helvetica Neue, serif">
          <font size={2} style={{ fontSize: "10pt" }}>
            s GPS-based functionality on your devices used to access the
            Services), and we may use that information to detect eligibility
            rules violations or customize the Services with location-based
            information, advertising, and features.
          </font>
        </font>
      </p>
      <p
        align="justifyContent"
        style={{
          marginBottom: "0.18cm",
          border: "none",
          padding: "0cm",
          lineHeight: "100%",
        }}
      >
        <br />
        <br />
      </p>
      <p
        align="justifyContent"
        style={{
          marginBottom: "0.18cm",
          border: "none",
          padding: "0cm",
          lineHeight: "100%",
        }}
      >
        <font color="#212529">
          <font face="Helvetica Neue, serif">
            <font size={2} style={{ fontSize: "10pt" }}>
              <b>Information We Collect from Other Sources</b>
            </font>
          </font>
        </font>
      </p>
      <p
        align="justifyContent"
        style={{
          marginBottom: "0.18cm",
          border: "none",
          padding: "0cm",
          lineHeight: "100%",
        }}
      >
        <font color="#212529">
          <font face="Helvetica Neue, serif">
            <font size={2} style={{ fontSize: "10pt" }}>
              We may collect information from other sources, such as social
              networking services, fantasy sports league platforms, sweepstakes,
              contests, and
            </font>
          </font>
        </font>
        <font color="#212529">
          <font face="Helvetica Neue, serif">
            <font size={2} style={{ fontSize: "10pt" }}>
              public records.{" "}
            </font>
          </font>
        </font>
        <font color="#000000">
          <font face="Helvetica Neue, serif">
            <font size={2} style={{ fontSize: "10pt" }}>
              If you choose to log in, access or otherwise connect to TopProp,
              or contact TopProp, through a social networking service, we may
              collect your user ID and username associated with that social
              networking service, as well as any information you make public
              using that social networking service. We may also collect
              information you have authorized the social networking service to
              share with us (such as your user ID, public profile information,
              email address, birthday, friends list, and pages you have
              “liked”).
            </font>
          </font>
        </font>
        <font color="#000000"> </font>
        <font color="#000000">
          <font face="Helvetica Neue, serif">
            <font size={2} style={{ fontSize: "10pt" }}>
              When you use features to import your fantasy leagues from
              third-party platforms (e.g., Yahoo! and ESPN), we may collect your
              user ID and username associated with that fantasy sports platform,
              as well as other information related to your fantasy leagues, such
              as league settings, lineups, and manage identities.
            </font>
          </font>
        </font>
        <font color="#212529">
          <font face="Helvetica Neue, serif">
            <font size={2} style={{ fontSize: "10pt" }}>
              We also obtain contact and business information used to
              communicate with you about the Services from sweepstakes,
              contests, and public records.
            </font>
          </font>
        </font>
      </p>
      <p
        align="justifyContent"
        style={{
          marginBottom: "0.18cm",
          border: "none",
          padding: "0cm",
          lineHeight: "100%",
        }}
      >
        <br />
        <br />
      </p>
      <p
        align="justifyContent"
        style={{
          marginBottom: "0.18cm",
          border: "none",
          padding: "0cm",
          lineHeight: "100%",
        }}
      >
        <font color="#212529">
          <font face="Helvetica Neue, serif">
            <font size={2} style={{ fontSize: "10pt" }}>
              <b>HOW WE USE INFORMATION</b>
            </font>
          </font>
        </font>
      </p>
      <p
        align="justifyContent"
        style={{
          marginBottom: "0.18cm",
          border: "none",
          padding: "0cm",
          lineHeight: "100%",
        }}
      >
        <font color="#212529">
          <font face="Helvetica Neue, serif">
            <font size={2} style={{ fontSize: "10pt" }}>
              We use your information to:
            </font>
          </font>
        </font>
      </p>
      <ul>
        <li>
          <p
            align="justifyContent"
            style={{
              marginBottom: "0.18cm",
              border: "none",
              padding: "0cm",
              lineHeight: "100%",
            }}
          >
            <font color="#212529">
              <font face="Helvetica Neue, serif">
                <font size={2} style={{ fontSize: "10pt" }}>
                  Provide you with the Services and information you request;
                </font>
              </font>
            </font>
          </p>
        </li>
        <li>
          <p
            align="justifyContent"
            style={{
              marginBottom: "0.18cm",
              border: "none",
              padding: "0cm",
              lineHeight: "100%",
            }}
          >
            <font color="#212529">
              <font face="Helvetica Neue, serif">
                <font size={2} style={{ fontSize: "10pt" }}>
                  Manage, verify, and authenticate your account;
                </font>
              </font>
            </font>
          </p>
        </li>
        <li>
          <p
            align="justifyContent"
            style={{
              marginBottom: "0.18cm",
              border: "none",
              padding: "0cm",
              lineHeight: "100%",
            }}
          >
            <font color="#212529">
              <font face="Helvetica Neue, serif">
                <font size={2} style={{ fontSize: "10pt" }}>
                  Engage in transactions, including contacting you about your
                  account, billing, remitting, or charging you, and processing
                  payments;
                </font>
              </font>
            </font>
          </p>
        </li>
        <li>
          <p
            align="justifyContent"
            style={{
              marginBottom: "0.18cm",
              border: "none",
              padding: "0cm",
              lineHeight: "100%",
            }}
          >
            <font color="#212529">
              <font face="Helvetica Neue, serif">
                <font size={2} style={{ fontSize: "10pt" }}>
                  Facilitate prize payments and fund deposits and withdrawals;
                </font>
              </font>
            </font>
          </p>
        </li>
        <li>
          <p
            align="justifyContent"
            style={{
              marginBottom: "0.18cm",
              border: "none",
              padding: "0cm",
              lineHeight: "100%",
            }}
          >
            <font color="#212529">
              <font face="Helvetica Neue, serif">
                <font size={2} style={{ fontSize: "10pt" }}>
                  Improve the Services, including customization and
                  personalization;
                </font>
              </font>
            </font>
          </p>
        </li>
        <li>
          <p
            align="justifyContent"
            style={{
              marginBottom: "0.18cm",
              border: "none",
              padding: "0cm",
              lineHeight: "100%",
            }}
          >
            <font color="#212529">
              <font face="Helvetica Neue, serif">
                <font size={2} style={{ fontSize: "10pt" }}>
                  Provide you with effective customer service;
                </font>
              </font>
            </font>
          </p>
        </li>
        <li>
          <p
            align="justifyContent"
            style={{
              marginBottom: "0.18cm",
              border: "none",
              padding: "0cm",
              lineHeight: "100%",
            }}
          >
            <font color="#212529">
              <font face="Helvetica Neue, serif">
                <font size={2} style={{ fontSize: "10pt" }}>
                  Communicate with you about the Services and contact you with
                  special offers, promotional information, content,
                  publications, and other information we believe will be of
                  interest to you (in accordance with any privacy preferences
                  you have expressed to us) via mail, email, telephone, text
                  messaging, and/or push notifications and display media;
                </font>
              </font>
            </font>
          </p>
        </li>
        <li>
          <p
            align="justifyContent"
            style={{
              marginBottom: "0.18cm",
              border: "none",
              padding: "0cm",
              lineHeight: "100%",
            }}
          >
            <font color="#212529">
              <font face="Helvetica Neue, serif">
                <font size={2} style={{ fontSize: "10pt" }}>
                  Analyze use of the Services and improve the content,
                  functionality, and usability of the Services, enhance the user
                  experience, and improve our business;
                </font>
              </font>
            </font>
          </p>
        </li>
        <li>
          <p
            align="justifyContent"
            style={{
              marginBottom: "0.18cm",
              border: "none",
              padding: "0cm",
              lineHeight: "100%",
            }}
          >
            <font color="#212529">
              <font face="Helvetica Neue, serif">
                <font size={2} style={{ fontSize: "10pt" }}>
                  Invite you to participate in promotions, sweepstakes, surveys
                  and provide feedback to us;
                </font>
              </font>
            </font>
          </p>
        </li>
        <li>
          <p
            align="justifyContent"
            style={{
              marginBottom: "0.18cm",
              border: "none",
              padding: "0cm",
              lineHeight: "100%",
            }}
          >
            <font color="#212529">
              <font face="Helvetica Neue, serif">
                <font size={2} style={{ fontSize: "10pt" }}>
                  Secure the Services and investigate and help prevent fraud,
                  security issues, and abuse;
                </font>
              </font>
            </font>
          </p>
        </li>
        <li>
          <p
            align="justifyContent"
            style={{
              marginBottom: "0.18cm",
              border: "none",
              padding: "0cm",
              lineHeight: "100%",
            }}
          >
            <font color="#212529">
              <font face="Helvetica Neue, serif">
                <font size={2} style={{ fontSize: "10pt" }}>
                  Understand, detect, and resolve problems with the Services and
                  other issues being reported;
                </font>
              </font>
            </font>
          </p>
        </li>
        <li>
          <p
            align="justifyContent"
            style={{
              marginBottom: "0.18cm",
              border: "none",
              padding: "0cm",
              lineHeight: "100%",
            }}
          >
            <font color="#212529">
              <font face="Helvetica Neue, serif">
                <font size={2} style={{ fontSize: "10pt" }}>
                  Comply with any procedures, laws, and regulations;
                </font>
              </font>
            </font>
          </p>
        </li>
        <li>
          <p
            align="justifyContent"
            style={{
              marginBottom: "0.18cm",
              border: "none",
              padding: "0cm",
              lineHeight: "100%",
            }}
          >
            <font color="#212529">
              <font face="Helvetica Neue, serif">
                <font size={2} style={{ fontSize: "10pt" }}>
                  Establish, exercise, or defend our legal rights where
                  necessary, including the enforcement of our
                </font>
              </font>
            </font>
            <font color="#007bff">
              <font face="Helvetica Neue, serif">
                <font size={2} style={{ fontSize: "10pt" }}>
                  <u>Terms of Service</u>
                </font>
              </font>
            </font>
            <font color="#212529">
              <font face="Helvetica Neue, serif">
                <font size={2} style={{ fontSize: "10pt" }}>
                  , other usage policies and agreements, and other legal terms
                  or controls, or to engage in other legal matters; and
                </font>
              </font>
            </font>
          </p>
        </li>
        <li>
          <p
            align="justifyContent"
            style={{
              marginBottom: "0.18cm",
              border: "none",
              padding: "0cm",
              lineHeight: "100%",
            }}
          >
            <font color="#212529">
              <font face="Helvetica Neue, serif">
                <font size={2} style={{ fontSize: "10pt" }}>
                  Fulfill other requests with your consent and for any other
                  purposes disclosed at the time you provide personal
                  information.
                </font>
              </font>
            </font>
          </p>
        </li>
      </ul>
      <p
        align="justifyContent"
        style={{
          marginBottom: "0.18cm",
          border: "none",
          padding: "0cm",
          lineHeight: "100%",
        }}
      >
        <font color="#212529">
          <font face="Helvetica Neue, serif">
            <font size={2} style={{ fontSize: "10pt" }}>
              <b>Combined information </b>
            </font>
          </font>
        </font>
      </p>
      <p
        style={{
          marginBottom: "0.18cm",
          border: "none",
          padding: "0cm",
          lineHeight: "100%",
        }}
      >
        <font color="#212529">
          <font face="Helvetica Neue, serif">
            <font size={2} style={{ fontSize: "10pt" }}>
              For the purposes discussed in this Privacy Policy, we may combine
              the information that we collect through the Services with
              information that we receive from other sources, both online and
              offline, and use such combined information in accordance with this
              Privacy Policy.
            </font>
          </font>
        </font>
      </p>
      <p
        style={{
          marginBottom: "0.18cm",
          border: "none",
          padding: "0cm",
          lineHeight: "100%",
        }}
      >
        <font color="#212529">
          <font face="Helvetica Neue, serif">
            <font size={2} style={{ fontSize: "10pt" }}>
              <b>Aggregate/De-identified Information</b>
            </font>
          </font>
        </font>
      </p>
      <p
        style={{
          marginBottom: "0.18cm",
          border: "none",
          padding: "0cm",
          lineHeight: "100%",
        }}
      >
        <font color="#212529">
          <font face="Helvetica Neue, serif">
            <font size={2} style={{ fontSize: "10pt" }}>
              We may aggregate and/or de-identify any information collected
              through the Services so that such information can no longer be
              linked to you or your device (“Aggregate/De-Identified
              Information”). We may use Aggregate/De-Identified Information for
              any purpose, including without limitation for research and
              marketing purposes, and may also share such data with any third
              parties in our discretion.
              <br />{" "}
            </font>
          </font>
        </font>
        <br />
        <br />
      </p>
      <p
        align="justifyContent"
        style={{
          marginBottom: "0.18cm",
          border: "none",
          padding: "0cm",
          lineHeight: "100%",
        }}
      >
        <span style={{ fontVariant: "small-caps" }}>
          <font color="#212529">
            <font face="Helvetica Neue, serif">
              <font size={2} style={{ fontSize: "10pt" }}>
                <b>COOKIES &amp; SIMILAR TECHNOLOGIES</b>
              </font>
            </font>
          </font>
        </span>
      </p>
      <p
        align="justifyContent"
        style={{
          marginBottom: "0.18cm",
          border: "none",
          padding: "0cm",
          lineHeight: "100%",
        }}
      >
        <font color="#212529">
          <font face="Helvetica Neue, serif">
            <font size={2} style={{ fontSize: "10pt" }}>
              To collect the information in the “
            </font>
          </font>
        </font>
        <font color="#212529">
          <font face="Helvetica Neue, serif">
            <font size={2} style={{ fontSize: "10pt" }}>
              Information We Collect Through Automated Means” section above, we
              and our service providers use web server logs, cookies, tags,
              SDKs, tracking pixels, and similar tracking technologies. We use
              these technologies in order to offer you a more tailored
              experience in the future.
            </font>
          </font>
        </font>
      </p>
      <ul>
        <li>
          <p
            align="justifyContent"
            style={{
              marginBottom: "0cm",
              border: "none",
              padding: "0cm",
              lineHeight: "100%",
            }}
          >
            <font color="#111111">
              <font face="Helvetica Neue, serif">
                <font size={2} style={{ fontSize: "10pt" }}>
                  A web server log is a file where website activity is stored.
                </font>
              </font>
            </font>
          </p>
        </li>
        <li>
          <p
            align="justifyContent"
            style={{
              marginBottom: "0cm",
              border: "none",
              padding: "0cm",
              lineHeight: "100%",
            }}
          >
            <font color="#111111">
              <font face="Helvetica Neue, serif">
                <font size={2} style={{ fontSize: "10pt" }}>
                  An SDK is a set of tools and/or code that we embed in our
                  applications and software to allow third parties to collect
                  information about how Users interact with the Services.
                </font>
              </font>
            </font>
          </p>
        </li>
        <li>
          <p
            align="justifyContent"
            style={{
              marginBottom: "0cm",
              border: "none",
              padding: "0cm",
              lineHeight: "100%",
            }}
          >
            <font color="#111111">
              <font face="Helvetica Neue, serif">
                <font size={2} style={{ fontSize: "10pt" }}>
                  A cookie is a small text file that is placed on your computer
                  or mobile device when you use the Services, that enables us
                  to: (i) recognize your computer; (ii) store your preferences
                  and settings; (iii) understand the web pages of the Services
                  you have visited; (iv), enhance your user experience by
                  delivering and measuring the effectiveness of content and
                  advertising tailored to your interests; (v) perform searches
                  and analytics; and (vi) assist with security and
                  administrative functions. Some cookies are placed in your
                  browser cache while those associated with Flash technologies
                  are stored with your Adobe Flash Player files.
                </font>
              </font>
            </font>
          </p>
        </li>
        <li>
          <p
            align="justifyContent"
            style={{
              marginBottom: "0cm",
              border: "none",
              padding: "0cm",
              lineHeight: "100%",
            }}
          >
            <font color="#111111">
              <font face="Helvetica Neue, serif">
                <font size={2} style={{ fontSize: "10pt" }}>
                  Tracking pixels (sometimes referred to as web beacons or clear
                  GIFs) are tiny electronic tags with a unique identifier
                  embedded in websites, online ads and/or email that are
                  designed to: (1) collect usage information like ad impressions
                  or clicks and email open rates; (2) measure popularity of the
                  Services and associated advertising; and (3) access user
                  cookie.
                </font>
              </font>
            </font>
          </p>
        </li>
      </ul>
      <p
        align="justifyContent"
        style={{
          marginLeft: "0.42cm",
          marginBottom: "0cm",
          border: "none",
          padding: "0cm",
          lineHeight: "100%",
        }}
      >
        <br />
      </p>
      <p
        align="justifyContent"
        style={{
          marginBottom: "0.18cm",
          border: "none",
          padding: "0cm",
          lineHeight: "100%",
        }}
      >
        <font color="#212529">
          <font face="Helvetica Neue, serif">
            <font size={2} style={{ fontSize: "10pt" }}>
              As we adopt additional technologies, we may also gather
              information through other methods.
            </font>
          </font>
        </font>
      </p>
      <p style={{ marginBottom: "0.48cm", lineHeight: "100%" }}>
        <font color="#212529">
          <font face="Helvetica Neue, serif">
            <font size={2} style={{ fontSize: "10pt" }}>
              Please note that you can change your settings to notify you when a
              cookie is being set or updated, or to block cookies altogether.
              Please consult the “Help” section of your browser for more
              information (e.g.,&nbsp;
              <a href="https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies">
                Internet Explorer
              </a>
              ;&nbsp;
              <a href="https://support.google.com/chrome/answer/95647?hl=en">
                Google Chrome
              </a>
              ;&nbsp;
              <a href="http://kb.mozillazine.org/Cookies#Firefox">
                Mozilla Firefox
              </a>
              ; or&nbsp;
              <a href="https://support.apple.com/kb/PH5042?locale=en_US">
                Apple Safari
              </a>
              ). You can also manage the use of Flash technologies, including
              flash cookies and local storage objects with the Flash management
              tools available at&nbsp;
              <a href="http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager02.html">
                Adobe’s website
              </a>
              . Please note that by blocking, disabling, or managing any or all
              cookies, you may not have access to certain features or offerings
              of the Services.
            </font>
          </font>
        </font>
      </p>
      <p
        align="justifyContent"
        style={{
          marginBottom: "0.18cm",
          border: "none",
          padding: "0cm",
          lineHeight: "100%",
        }}
      >
        <span style={{ fontVariant: "small-caps" }}>
          <font color="#212529">
            <font face="Helvetica Neue, serif">
              <font size={2} style={{ fontSize: "10pt" }}>
                <b>ONLINE ANALYTICS AND ADVERTISING </b>
              </font>
            </font>
          </font>
        </span>
      </p>
      <p style={{ marginBottom: "0.48cm", lineHeight: "100%" }}>
        <font color="#111111">
          <font face="Helvetica Neue, serif">
            <font size={2} style={{ fontSize: "10pt" }}>
              <b>Analytics. </b>
            </font>
          </font>
        </font>
        <font color="#111111">
          <font face="Helvetica Neue, serif">
            <font size={2} style={{ fontSize: "10pt" }}>
              We may use third-party analytics services (such as those of Google
              Analytics) on our Services to collect and analyze usage
              information through cookies and similar technologies; engage in
              auditing, research, or reporting; assist with fraud prevention;
              and provide certain features to you. To prevent Google Analytics
              from using your information for analytics, you may install the
              Google Analytics Opt-out Browser Add-on by clicking
            </font>
          </font>
        </font>
        <a href="https://tools.google.com/dlpage/gaoptout">
          <font face="Helvetica Neue, serif">
            <font size={2} style={{ fontSize: "10pt" }}>
              <u>here</u>
            </font>
          </font>
        </a>
        <font color="#111111">
          <font face="Helvetica Neue, serif">
            <font size={2} style={{ fontSize: "10pt" }}>
              .&nbsp;
            </font>
          </font>
        </font>
      </p>
      <p style={{ marginBottom: "0.48cm", lineHeight: "100%" }}>
        <font color="#111111">
          <font face="Helvetica Neue, serif">
            <font size={2} style={{ fontSize: "10pt" }}>
              <b>Advertising. </b>
            </font>
          </font>
        </font>
        <font face="Helvetica Neue, serif">
          <font size={2} style={{ fontSize: "10pt" }}>
            We may use third-party
          </font>
        </font>
        <font face="Helvetica Neue, serif">
          <font size={2} style={{ fontSize: "10pt" }}>
            analytics services on our Services to collect and analyze usage
            information through cookies and similar technologies; engage in
            auditing, research, or reporting; assist with fraud prevention; and
            provide certain features to you.
          </font>
        </font>
      </p>
      <p
        align="justifyContent"
        style={{
          marginBottom: "0cm",
          border: "none",
          padding: "0cm",
          lineHeight: "100%",
        }}
      >
        <font color="#111111">
          <font face="Helvetica Neue, serif">
            <font size={2} style={{ fontSize: "10pt" }}>
              We may also work with a number of companies that assist in
              marketing our services to you on third party websites, mobile
              apps, and online services. These third parties may use cookies,
              web beacons or other tracking technologies to collect information
              about your use of the Services and your activities across other
              websites and online services, which they may associate with
              persistent identifiers. Sometimes, these identifiers may be
              derived from a hashed or encrypted version of personal information
              such as your email address. We may use this information to measure
              the performance of our advertising as well, for instance, to
              evaluate which ads or content our users prefer, or which are most
              effective. In addition, sometimes we or an advertising partner may
              tailor advertising to you across different devices.
            </font>
          </font>
        </font>
      </p>
      <p
        align="justifyContent"
        style={{
          marginBottom: "0cm",
          border: "none",
          padding: "0cm",
          lineHeight: "100%",
        }}
      >
        <br />
      </p>
      <p
        align="justifyContent"
        style={{
          marginBottom: "0cm",
          border: "none",
          padding: "0cm",
          lineHeight: "100%",
        }}
      >
        <font color="#111111">
          <font face="Helvetica Neue, serif">
            <font size={2} style={{ fontSize: "10pt" }}>
              Their activities and your choices regarding their use of your
              information to personalize ads to you are subject to and set out
              in their own policies. We neither have access to, nor does this
              Privacy Policy govern, the use of cookies or other tracking
              technologies that may be placed on your computer, mobile phone, or
              other device by non-affiliated, third-party providers. As
              described below, these providers may offer you a way to opt-out of
              the collection of information that is used for our interest-based
              advertising to you. We cannot guarantee that these instructions
              will not change, or that they will continue to be available; they
              are controlled by each third-party service provider, not us.
            </font>
          </font>
        </font>
      </p>
      <p
        align="justifyContent"
        style={{
          marginBottom: "0cm",
          border: "none",
          padding: "0cm",
          lineHeight: "100%",
        }}
      >
        <br />
      </p>
      <p
        align="justifyContent"
        style={{
          marginBottom: "0cm",
          border: "none",
          padding: "0cm",
          lineHeight: "100%",
        }}
      >
        <font color="#111111">
          <font face="Helvetica Neue, serif">
            <font size={2} style={{ fontSize: "10pt" }}>
              If you are interested in more information about interest-based
              advertising and how you can generally control cookies other
              tracking technologies from being put on your computer to deliver
              such advertising, you may visit the&nbsp;
            </font>
          </font>
        </font>
        <a href="http://www.networkadvertising.org/choices">
          <font color="#000000">
            <font face="Helvetica Neue, serif">
              <font size={2} style={{ fontSize: "10pt" }}>
                <u>Network Advertising Initiative’s Consumer Opt-Out link</u>
              </font>
            </font>
          </font>
        </a>
        <font color="#111111">
          <font face="Helvetica Neue, serif">
            <font size={2} style={{ fontSize: "10pt" }}>
              , the&nbsp;
            </font>
          </font>
        </font>
        <a href="http://www.aboutads.info/choices/">
          <font color="#000000">
            <font face="Helvetica Neue, serif">
              <font size={2} style={{ fontSize: "10pt" }}>
                <u>
                  Digital Advertising Alliance’s (DAA’s) Consumer Opt-Out link
                </u>
              </font>
            </font>
          </font>
        </a>
        <font color="#111111">
          <font face="Helvetica Neue, serif">
            <font size={2} style={{ fontSize: "10pt" }}>
              ,&nbsp;or&nbsp;
            </font>
          </font>
        </font>
        <a href="http://preferences-mgr.truste.com/">
          <font color="#000000">
            <font face="Helvetica Neue, serif">
              <font size={2} style={{ fontSize: "10pt" }}>
                <u>TrustArc’s Advertising Choices Page</u>
              </font>
            </font>
          </font>
        </a>
        <font color="#111111">
          <font face="Helvetica Neue, serif">
            <font size={2} style={{ fontSize: "10pt" }}>
              . To opt out of the display of interest-based advertising from
              Google, please visit the&nbsp;
            </font>
          </font>
        </font>
        <a href="https://www.google.com/settings/ads">
          <font color="#000000">
            <font face="Helvetica Neue, serif">
              <font size={2} style={{ fontSize: "10pt" }}>
                <u>Google Ads Settings</u>
              </font>
            </font>
          </font>
        </a>
        <font color="#111111">
          <font face="Helvetica Neue, serif">
            <font size={2} style={{ fontSize: "10pt" }}>
              &nbsp;page. For more information and to exercise your choices
              regarding Facebook and/or Instagram ads, please visit the
            </font>
          </font>
        </font>
        <a href="https://www.facebook.com/help/1075880512458213">
          <font color="#000000">
            <font face="Helvetica Neue, serif">
              <font size={2} style={{ fontSize: "10pt" }}>
                <u>Facebook Ads Settings</u>
              </font>
            </font>
          </font>
        </a>
        <font color="#111111">
          <font face="Helvetica Neue, serif">
            <font size={2} style={{ fontSize: "10pt" }}>
              {" "}
              page and/or the{" "}
            </font>
          </font>
        </font>
        <a href="https://help.instagram.com/478880589321969/?helpref=hc_fnav&bc%5B0%5D=Instagram%20Help&bc%5B1%5D=Managing%20Your%20Account">
          <font color="#000000">
            <font face="Helvetica Neue, serif">
              <font size={2} style={{ fontSize: "10pt" }}>
                <u>Instagram Ads Settings</u>
              </font>
            </font>
          </font>
        </a>
        <font color="#111111">
          <font face="Helvetica Neue, serif">
            <font size={2} style={{ fontSize: "10pt" }}>
              {" "}
              page.
            </font>
          </font>
        </font>
      </p>
      <p
        align="justifyContent"
        style={{
          marginBottom: "0cm",
          border: "none",
          padding: "0cm",
          lineHeight: "100%",
        }}
      >
        <br />
      </p>
      <p style={{ marginBottom: "0cm", lineHeight: "100%" }}>
        <font color="#111111">
          <font face="Helvetica Neue, serif">
            <font size={2} style={{ fontSize: "10pt" }}>
              <b>Notice Concerning Do Not Track. </b>
            </font>
          </font>
        </font>
        <font color="#111111">
          <font face="Helvetica Neue, serif">
            <font size={2} style={{ fontSize: "10pt" }}>
              Do Not Track (“DNT”) is a privacy preference that users can set in
              certain web browsers. We are committed to providing you with
              meaningful choices about the information collected on our website
              for third party purposes, and that is why we provide the variety
              of opt-out mechanisms listed above. Because of the changing state
              of technology and indecision within the industry regarding the
              meaning of DNT signals, we currently do not make any guarantee
              that we will honor DNT signals. Learn more about&nbsp;
            </font>
          </font>
        </font>
        <a href="https://allaboutdnt.com/">
          <font face="Helvetica Neue, serif">
            <font size={2} style={{ fontSize: "10pt" }}>
              <u>Do Not Track</u>
            </font>
          </font>
        </a>
        <font color="#111111">
          <font face="Helvetica Neue, serif">
            <font size={2} style={{ fontSize: "10pt" }}>
              .
            </font>
          </font>
        </font>
      </p>
      <p style={{ marginBottom: "0cm", lineHeight: "100%" }}>
        <br />
      </p>
      <p
        align="justifyContent"
        style={{
          marginBottom: "0.18cm",
          border: "none",
          padding: "0cm",
          lineHeight: "100%",
        }}
      >
        <font color="#212529">
          <font face="Helvetica Neue, serif">
            <font size={2} style={{ fontSize: "10pt" }}>
              <b>HOW WE SHARE INFORMATION</b>
            </font>
          </font>
        </font>
      </p>
      <p
        align="justifyContent"
        style={{
          marginBottom: "0.18cm",
          border: "none",
          padding: "0cm",
          lineHeight: "100%",
        }}
      >
        <font color="#212529">
          <font face="Helvetica Neue, serif">
            <font size={2} style={{ fontSize: "10pt" }}>
              We will share your information collected from and about you in the
              following ways:
            </font>
          </font>
        </font>
      </p>
      <ul>
        <li>
          <p
            align="justifyContent"
            style={{
              marginBottom: "0cm",
              border: "none",
              padding: "0cm",
              lineHeight: "100%",
            }}
          >
            <font color="#212529">
              <font face="Helvetica Neue, serif">
                <font size={2} style={{ fontSize: "10pt" }}>
                  <b>Service Providers</b>
                </font>
              </font>
            </font>
            <font color="#212529">
              <font face="Helvetica Neue, serif">
                <font size={2} style={{ fontSize: "10pt" }}>
                  : We may provide access to or share your information with
                  select third parties who perform services on our behalf, such
                  as online hosting and maintenance, marketing and promotion
                  design and management, management of e-commerce systems,
                  payment processing, data storage and management, marketing and
                  email delivery, analytics, customer service, security and
                  fraud prevention, legal services, and identity and contact
                  information validation.
                </font>
              </font>
            </font>
          </p>
        </li>
        <li>
          <p
            align="justifyContent"
            style={{
              marginBottom: "0cm",
              border: "none",
              padding: "0cm",
              lineHeight: "100%",
            }}
          >
            <font color="#000000">
              <font face="Helvetica Neue, serif">
                <font size={2} style={{ fontSize: "10pt" }}>
                  <b>Marketing Partners</b>
                </font>
              </font>
            </font>
            <font color="#000000">
              <font face="Helvetica Neue, serif">
                <font size={2} style={{ fontSize: "10pt" }}>
                  : We may share information about your interaction with our
                  marketing partners and sponsors.
                </font>
              </font>
            </font>
          </p>
        </li>
        <li>
          <p
            align="justifyContent"
            style={{
              marginBottom: "0cm",
              border: "none",
              padding: "0cm",
              lineHeight: "100%",
            }}
          >
            <font color="#212529">
              <font face="Helvetica Neue, serif">
                <font size={2} style={{ fontSize: "10pt" }}>
                  <b>Business Transfers</b>
                </font>
              </font>
            </font>
            <font color="#212529">
              <font face="Helvetica Neue, serif">
                <font size={2} style={{ fontSize: "10pt" }}>
                  : As we continue to develop our business, we may buy, merge,
                  or partner with other companies. In such transactions
                  (including in contemplation of such transactions), information
                  may be among the transferred assets. If a portion or all of
                  our assets are sold or transferred to a third party, user
                  information would likely be one of the transferred assets. If
                  such transfer is subject to notifications or restrictions
                  under applicable laws, we will comply with such requirements.
                </font>
              </font>
            </font>
          </p>
        </li>
        <li>
          <p
            align="justifyContent"
            style={{
              marginBottom: "0cm",
              border: "none",
              padding: "0cm",
              lineHeight: "100%",
            }}
          >
            <font color="#212529">
              <font face="Helvetica Neue, serif">
                <font size={2} style={{ fontSize: "10pt" }}>
                  <b>Your Profile: </b>
                </font>
              </font>
            </font>
            <font color="#212529">
              <font face="Helvetica Neue, serif">
                <font size={2} style={{ fontSize: "10pt" }}>
                  We may publish a profile page that includes your username, the
                  date you became a member, your stats from previous contests,
                  your state of residence, your team and player preferences,
                  gameplay badges and designations, any information you post to
                  public areas of our Services, and, if you uploaded one, your
                  profile picture. Where you post personal information about
                  yourself through our Services to public areas of our Services,
                  you acknowledge and agree that this personal information will
                  be publicly available. When you participate in a contest, we
                  may publish your username and, if you uploaded one, your
                  profile picture on a list of the contest participants, along
                  with a link to your profile page.
                </font>
              </font>
            </font>
          </p>
        </li>
        <li>
          <p
            style={{
              marginBottom: "0cm",
              border: "none",
              padding: "0cm",
              lineHeight: "100%",
            }}
          >
            <font color="#212529">
              <font face="Helvetica Neue, serif">
                <font size={2} style={{ fontSize: "10pt" }}>
                  <b>Publicity for Winners</b>
                </font>
              </font>
            </font>
            <font color="#212529">
              <font face="Helvetica Neue, serif">
                <font size={2} style={{ fontSize: "10pt" }}>
                  : When you participate in a contest and win a prize, we may
                  also publicly share your personal information (such as your
                  name, username, state and country of residence, games played,
                  gaming identities, and photos) in connection with publicity
                  purposes.
                </font>
              </font>
            </font>
          </p>
        </li>
        <li>
          <p
            align="justifyContent"
            style={{
              marginBottom: "0cm",
              border: "none",
              padding: "0cm",
              lineHeight: "100%",
            }}
          >
            <font color="#212529">
              <font face="Helvetica Neue, serif">
                <font size={2} style={{ fontSize: "10pt" }}>
                  <b>
                    Comply with Laws and Protect Our Rights and the Rights of
                    Others
                  </b>
                </font>
              </font>
            </font>
            <font color="#212529">
              <font face="Helvetica Neue, serif">
                <font size={2} style={{ fontSize: "10pt" }}>
                  : We may disclose your information when we, in good faith,
                  believe disclosure is appropriate to comply with the law, a
                  court order or a subpoena. We may also disclose your
                  information to prevent or investigate a possible crime, such
                  as fraud or identity theft; to protect the security of the
                  Services; to enforce or apply our online
                </font>
              </font>
            </font>
            <font color="#007bff">
              <font face="Helvetica Neue, serif">
                <font size={2} style={{ fontSize: "10pt" }}>
                  <u>Terms of Service</u>
                </font>
              </font>
            </font>
            <font color="#007bff">
              <font face="Helvetica Neue, serif">
                <font size={2} style={{ fontSize: "10pt" }}>
                  {" "}
                </font>
              </font>
            </font>
            <font color="#212529">
              <font face="Helvetica Neue, serif">
                <font size={2} style={{ fontSize: "10pt" }}>
                  or other agreements; or to protect our own rights or property
                  or the rights, property, or safety of our users or others.
                </font>
              </font>
            </font>
          </p>
        </li>
        <li>
          <p
            align="justifyContent"
            style={{
              marginBottom: "0cm",
              border: "none",
              padding: "0cm",
              lineHeight: "100%",
            }}
          >
            <font color="#212529">
              <font face="Helvetica Neue, serif">
                <font size={2} style={{ fontSize: "10pt" }}>
                  <b>With Your Consent or At Your Direction</b>
                </font>
              </font>
            </font>
            <font color="#212529">
              <font face="Helvetica Neue, serif">
                <font size={2} style={{ fontSize: "10pt" }}>
                  : We may share information with third parties when you direct
                  us to do so or if you have consented to additional sharing of
                  your information (including as set out in this Privacy
                  Policy).
                </font>
              </font>
            </font>
          </p>
        </li>
      </ul>
      <p
        align="justifyContent"
        style={{
          marginLeft: "1.06cm",
          marginBottom: "0cm",
          border: "none",
          padding: "0cm",
          lineHeight: "100%",
        }}
      >
        <br />
      </p>
      <p
        align="justifyContent"
        style={{
          marginBottom: "0.18cm",
          border: "none",
          padding: "0cm",
          lineHeight: "100%",
        }}
      >
        <font color="#212529">
          <font face="Helvetica Neue, serif">
            <font size={2} style={{ fontSize: "10pt" }}>
              <b>SECURITY</b>
            </font>
          </font>
        </font>
      </p>
      <p
        align="justifyContent"
        style={{
          marginBottom: "0.18cm",
          border: "none",
          padding: "0cm",
          lineHeight: "100%",
        }}
      >
        <font color="#212529">
          <font face="Helvetica Neue, serif">
            <font size={2} style={{ fontSize: "10pt" }}>
              We recognize the importance of implementing a variety of security
              safeguards designed to protect the confidentiality of your
              information. However, no data transmission over the Internet or
              other network is completely secure. As a result, while we strive
              to protect information you transmitted on or through our Services,
              you do so at your own risk.
            </font>
          </font>
        </font>
      </p>
      <p
        align="justifyContent"
        style={{
          marginBottom: "0.18cm",
          border: "none",
          padding: "0cm",
          lineHeight: "100%",
        }}
      >
        <font color="#212529">
          <font face="Helvetica Neue, serif">
            <font size={2} style={{ fontSize: "10pt" }}>
              When you create an account, you may be prompted to create a
              username or password. If you create an account with us, you are
              responsible for maintaining the confidentiality of your account
              password and for any activity that occurs under your account. We
              are not responsible for any loss or damage arising from your
              failure to maintain the confidentiality of your password. We urge
              you to change your passwords often, use a combination of letters
              and numbers, and make sure you are using a secure browser. If you
              have reason to believe that your interaction with us is no longer
              secure or if you suspect someone else is using your account,
              please let us know immediately by contacting us as indicated in
              the “
            </font>
          </font>
        </font>
        <font color="#212529">
          <font face="Helvetica Neue, serif">
            <font size={2} style={{ fontSize: "10pt" }}>
              Contact Us” section below.
            </font>
          </font>
        </font>
      </p>
      <p
        align="justifyContent"
        style={{
          marginBottom: "0.18cm",
          border: "none",
          padding: "0cm",
          lineHeight: "100%",
        }}
      >
        <br />
        <br />
      </p>
      <p
        align="justifyContent"
        style={{
          marginBottom: "0.18cm",
          border: "none",
          padding: "0cm",
          lineHeight: "100%",
        }}
      >
        <font color="#212529">
          <font face="Helvetica Neue, serif">
            <font size={2} style={{ fontSize: "10pt" }}>
              <b>THIRD PARTY LINKS AND FEATURES</b>
            </font>
          </font>
        </font>
      </p>
      <p
        align="justifyContent"
        style={{
          marginBottom: "0.18cm",
          border: "none",
          padding: "0cm",
          lineHeight: "100%",
        }}
      >
        <font color="#212529">
          <font face="Helvetica Neue, serif">
            <font size={2} style={{ fontSize: "10pt" }}>
              Please be aware that third-party websites accessible or
              recommended through our Services may have their own privacy and
              data collection policies and practices. These links and features
              are provided for your reference and convenience only and do not
              imply any endorsement of information provided through these
              third-party links and features, nor any association with their
              operators. We are not responsible for any actions, content of
              websites, or privacy policies of such third parties. We urge you
              to read the privacy and security policies of these third parties.
            </font>
          </font>
        </font>
      </p>
      <p
        align="justifyContent"
        style={{
          marginBottom: "0.18cm",
          border: "none",
          padding: "0cm",
          lineHeight: "100%",
        }}
      >
        <br />
        <br />
      </p>
      <p
        align="justifyContent"
        style={{
          marginBottom: "0.18cm",
          border: "none",
          padding: "0cm",
          lineHeight: "100%",
        }}
      >
        <font color="#212529">
          <font face="Helvetica Neue, serif">
            <font size={2} style={{ fontSize: "10pt" }}>
              <b>HOW LONG WE RETAIN YOUR DATA</b>
            </font>
          </font>
        </font>
      </p>
      <p
        align="justifyContent"
        style={{
          marginBottom: "0.18cm",
          border: "none",
          padding: "0cm",
          lineHeight: "100%",
        }}
      >
        <font color="#212529">
          <font face="Helvetica Neue, serif">
            <font size={2} style={{ fontSize: "10pt" }}>
              We will retain your information for as long as we have a business
              need for it or as needed to comply with applicable legal
              obligations. We also retain and use your information as necessary
              to resolve disputes, protect TopProp and our users, and enforce
              our agreements.
            </font>
          </font>
        </font>
      </p>
      <p
        align="justifyContent"
        style={{
          marginBottom: "0.18cm",
          border: "none",
          padding: "0cm",
          lineHeight: "100%",
        }}
      >
        <br />
        <br />
      </p>
      <p
        align="justifyContent"
        style={{
          marginBottom: "0.18cm",
          border: "none",
          padding: "0cm",
          lineHeight: "100%",
        }}
      >
        <font color="#212529">
          <font face="Helvetica Neue, serif">
            <font size={2} style={{ fontSize: "10pt" }}>
              <b>DATA PROTECTION RIGHTS, INFORMATION, AND CHOICES</b>
            </font>
          </font>
        </font>
      </p>
      <p
        align="justifyContent"
        style={{
          marginBottom: "0.18cm",
          border: "none",
          padding: "0cm",
          lineHeight: "100%",
        }}
      >
        <font color="#212529">
          <font face="Helvetica Neue, serif">
            <font size={2} style={{ fontSize: "10pt" }}>
              You may have certain rights with respect to your personal
              information as further described in this section, in addition to
              any rights discussed elsewhere in this Privacy Policy. Please note
              your rights and choices vary depending upon your location.
            </font>
          </font>
        </font>
      </p>
      <p
        align="justifyContent"
        style={{
          marginBottom: "0.18cm",
          border: "none",
          padding: "0cm",
          lineHeight: "100%",
        }}
      >
        <font color="#212529">
          <font face="Helvetica Neue, serif">
            <font size={2} style={{ fontSize: "10pt" }}>
              <b>Marketing Communications</b>
            </font>
          </font>
        </font>
      </p>
      <p
        align="justifyContent"
        style={{
          marginBottom: "0.18cm",
          border: "none",
          padding: "0cm",
          lineHeight: "100%",
        }}
      >
        <font color="#212529">
          <font face="Helvetica Neue, serif">
            <font size={2} style={{ fontSize: "10pt" }}>
              You may instruct us not to use your information to contact you by
              email, postal mail, or phone regarding products, services,
              promotions and special events that might appeal to your interests
              by contacting us using the information in the “
            </font>
          </font>
        </font>
        <font color="#212529">
          <font face="Helvetica Neue, serif">
            <font size={2} style={{ fontSize: "10pt" }}>
              Contact Us” section below. In commercial email messages, you can
              opt out by following the instructions located at the bottom of
              such emails. Removing your name from the email list may take a
              reasonable amount of time. Please note that, regardless of your
              request, we may still use and share certain information as
              permitted by this Privacy Policy or as required by applicable law.
              For example, you may not opt out of certain operational emails,
              such as those reflecting our relationship or transactions with
              you.
            </font>
          </font>
        </font>
      </p>
      <p
        align="justifyContent"
        style={{
          marginBottom: "0.18cm",
          border: "none",
          padding: "0cm",
          lineHeight: "100%",
        }}
      >
        <font color="#212529">
          <font face="Helvetica Neue, serif">
            <font size={2} style={{ fontSize: "10pt" }}>
              <b>Additional Choices</b>
            </font>
          </font>
        </font>
      </p>
      <p
        align="justifyContent"
        style={{
          marginBottom: "0.18cm",
          border: "none",
          padding: "0cm",
          lineHeight: "100%",
        }}
      >
        <font color="#212529">
          <font face="Helvetica Neue, serif">
            <font size={2} style={{ fontSize: "10pt" }}>
              You can also make certain choices by using the options described
              in our “Cookies &amp; Similar Technologies” and “Online Analytics
              and Advertising” sections above.
            </font>
          </font>
        </font>
        <font color="#212529">
          <font face="Helvetica Neue, serif">
            <font size={2} style={{ fontSize: "10pt" }}>
              <i> </i>
            </font>
          </font>
        </font>
      </p>
      <p
        align="justifyContent"
        style={{
          marginBottom: "0.18cm",
          border: "none",
          padding: "0cm",
          lineHeight: "100%",
        }}
      >
        <font color="#212529">
          <font face="Helvetica Neue, serif">
            <font size={2} style={{ fontSize: "10pt" }}>
              <b>Account Information.</b>
            </font>
          </font>
        </font>
        <font color="#212529">
          <font face="Helvetica Neue, serif">
            <font size={2} style={{ fontSize: "10pt" }}>
              To keep your information accurate, current, and complete or delete
              your information, please contact us at support@toppropfantasy.com.
            </font>
          </font>
        </font>
      </p>
      <p
        align="justifyContent"
        style={{
          marginBottom: "0.18cm",
          border: "none",
          padding: "0cm",
          lineHeight: "100%",
        }}
      >
        <font color="#212529">
          <font face="Helvetica Neue, serif">
            <font size={2} style={{ fontSize: "10pt" }}>
              <b>California “Shine the Light” Disclosure</b>
            </font>
          </font>
        </font>
      </p>
      <p
        align="justifyContent"
        style={{
          marginBottom: "0.18cm",
          border: "none",
          padding: "0cm",
          lineHeight: "100%",
        }}
      >
        <font color="#212529">
          <font face="Helvetica Neue, serif">
            <font size={2} style={{ fontSize: "10pt" }}>
              The California “Shine the Light” law gives residents of California
              the right under certain circumstances to opt out of the sharing of
              certain categories of personal information (as defined in the
              Shine the Light law) with third parties for their direct marketing
              purposes.
            </font>
          </font>
        </font>
        <font color="#212529">
          <font face="Helvetica Neue, serif">
            <font size={2} style={{ fontSize: "10pt" }}>
              We do not share your personal information with third parties for
              their own direct marketing purposes within the meaning of that
              law.
            </font>
          </font>
        </font>
      </p>
      <p
        align="justifyContent"
        style={{
          marginBottom: "0.18cm",
          border: "none",
          padding: "0cm",
          lineHeight: "100%",
        }}
      >
        <font color="#212529">
          <font face="Helvetica Neue, serif">
            <font size={2} style={{ fontSize: "10pt" }}>
              <b>Privacy Rights for Nevada Residents</b>
            </font>
          </font>
        </font>
      </p>
      <p
        align="justifyContent"
        style={{
          marginBottom: "0.18cm",
          border: "none",
          padding: "0cm",
          lineHeight: "100%",
        }}
      >
        <font color="#212529">
          <font face="Helvetica Neue, serif">
            <font size={2} style={{ fontSize: "10pt" }}>
              Under Nevada law, certain Nevada residents may opt out of the
              “sale” of “covered information” (as such term is defined under
              Nevada law) for monetary consideration to a person for that person
              to license or sell such information to additional persons.
            </font>
          </font>
        </font>
        <font color="#212529">
          <font face="Helvetica Neue, serif">
            <font size={2} style={{ fontSize: "10pt" }}>
              We do not engage in such activity as of the effective date of this
              Privacy Policy.
            </font>
          </font>
        </font>
      </p>
      <p
        align="justifyContent"
        style={{
          marginBottom: "0cm",
          border: "none",
          padding: "0cm",
          lineHeight: "100%",
        }}
      >
        <br />
      </p>
      <p
        align="justifyContent"
        style={{
          marginBottom: "0.18cm",
          border: "none",
          padding: "0cm",
          lineHeight: "100%",
        }}
      >
        <font color="#000000">
          <font face="Helvetica Neue, serif">
            <font size={2} style={{ fontSize: "10pt" }}>
              <b>CHILDREN’S PRIVACY</b>
            </font>
          </font>
        </font>
      </p>
      <p style={{ marginBottom: "0cm", lineHeight: "100%" }}>
        <font color="#000000">
          <font face="Helvetica Neue, serif">
            <font size={2} style={{ fontSize: "10pt" }}>
              The Services are intended for general audiences and not for
              children. We do not knowingly collect, maintain, or use personal
              information (as defined by the United States Children’s Online
              Privacy Protection Act) from children in the United States under
              the age of 13 without legally-valid parental consent. If you
              believe that we might have any such information from a child,
              please contact us
            </font>
          </font>
        </font>
        <font color="#000000">
          <font face="Helvetica Neue, serif">
            <font size={2} style={{ fontSize: "10pt" }}>
              using the information in the “
            </font>
          </font>
        </font>
        <font color="#000000">
          <font face="Helvetica Neue, serif">
            <font size={2} style={{ fontSize: "10pt" }}>
              Contact Us” section below
            </font>
          </font>
        </font>
        <font color="#000000">
          <font face="Helvetica Neue, serif">
            <font size={2} style={{ fontSize: "10pt" }}>
              . If we discover that personally identifiable information of a
              child has been submitted without legally-valid parental consent,
              we will take reasonable steps to delete it as soon as possible. We
              also comply with other age restrictions and requirements in
              accordance with applicable local laws.
            </font>
          </font>
        </font>
      </p>
      <p
        align="justifyContent"
        style={{
          marginBottom: "0.18cm",
          border: "none",
          padding: "0cm",
          lineHeight: "100%",
        }}
      >
        <br />
        <br />
      </p>
      <p style={{ marginBottom: "0cm", lineHeight: "100%" }}>
        <font color="#000000">
          <font face="Helvetica Neue, serif">
            <font size={2} style={{ fontSize: "10pt" }}>
              IF YOU ARE UNDER 18 YEARS OF AGE (OR A MASSACHUSETTS RESIDENT
              UNDER 21 YEARS OF AGE), YOU MAY NOT USE OR ACCESS THE SERVICES AT
              ANY TIME OR IN ANY MANNER.
            </font>
          </font>
        </font>
      </p>
      <p
        align="justifyContent"
        style={{
          marginBottom: "0.18cm",
          border: "none",
          padding: "0cm",
          lineHeight: "100%",
        }}
      >
        <br />
        <br />
      </p>
      <p
        align="justifyContent"
        style={{
          marginBottom: "0.18cm",
          border: "none",
          padding: "0cm",
          lineHeight: "100%",
        }}
      >
        <font color="#212529">
          <font face="Helvetica Neue, serif">
            <font size={2} style={{ fontSize: "10pt" }}>
              <b>CONTACT US</b>
            </font>
          </font>
        </font>
      </p>
      <p
        align="justifyContent"
        style={{
          marginBottom: "0.18cm",
          border: "none",
          padding: "0cm",
          lineHeight: "100%",
        }}
      >
        <font color="#212529">
          <font face="Helvetica Neue, serif">
            <font size={2} style={{ fontSize: "10pt" }}>
              If you have any questions about our Services or this Privacy
              Policy, please email us at&nbsp;
            </font>
          </font>
        </font>
        <font color="#212529">
          <font face="Helvetica Neue, serif">
            <font size={2} style={{ fontSize: "10pt" }}>
              support@toppropfantasy.com.
            </font>
          </font>
        </font>
      </p>
      <p
        align="justifyContent"
        style={{
          marginLeft: "1.27cm",
          marginBottom: "0cm",
          border: "none",
          padding: "0cm",
          lineHeight: "100%",
        }}
      >
        <br />
      </p>
    </Box>
  );
};
export default PrivacyPolicy;

import { ReactNode } from "react";
import { Stack } from "@mui/material";
import { ListDataType } from ".";
import { Header } from "../commonList/Header";
import { ListHeader } from "../commonList/ListHeader";
import { ListItems } from "../commonList/ListItems";

export interface TItem {
  id: number;
  creditorId?: number;
  debitorId?: number;
  createdAt: string;
  type: string;
  status: string;
  originatingService?: string;
  amount: number;
  narration: string;
}

export interface TrasnferListProps<TItem> {
  data: ListDataType<TItem>;
  heading: string;
  isSearchable: boolean;
  renderHeader: () => ReactNode;
  renderRow: (row: TItem) => ReactNode;
}

export function TransferList<TItem>(props: TrasnferListProps<TItem>) {
  const { data, heading, renderHeader, renderRow, isSearchable } = props;
  return (
    <Stack spacing={4}>
      <Header heading={heading} isSearchable={isSearchable} />
      <Stack spacing={2}>
        <ListHeader render={renderHeader} />
        <ListItems items={data.rows} render={renderRow} />
      </Stack>
    </Stack>
  );
}

import { Fragment } from "react";
import { useQueryClient } from "react-query";
import { useSnackbar } from "notistack";
import { Wallet as WalletTab } from "@shared/components/templates/Wallet";
import ProfileLayout from "@src/components/templates/ProfileLayout";
import { useWalletInfo } from "@shared/hooks/useWalletInfo";
import { useGatewayInfo } from "@shared/hooks/useGatewayInfo";
import { useCreateTsevoSession } from "@shared/hooks/useCreateTsevoSession";
import { useDepositFunds } from "@src/hooks/useDepositFunds";
import { DepositFundsPayload } from "@shared/types";
import { QueryKeys } from "@shared/constants/QueryKeys";
import { useDeletePaymentSource } from "@src/hooks/useDeletePaymentSource";
import { useGeoLocation } from "@shared/hooks/useGeolocation";

export function Wallet() {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const { data: walletInfo } = useWalletInfo();
  const geocodingKey = process.env.REACT_APP_GOOGLE_GEOCODING_KEY || "";
  const fallbackCountry = "IN";
  const fallbackState = "GA";

  const { data: geoData } = useGeoLocation({
    geocodingKey,
    fallbackCountry,
    fallbackState,
  });

  const { data: gatewayInfo } = useGatewayInfo({
    lat: geoData?.lat,
    long: geoData?.long,
  });
  const { data: tsevoSession } = useCreateTsevoSession({
    payActionCode: "PAY",
    lat: geoData?.lat,
    long: geoData?.long,
  });
  const { mutate: deletePaymentSource } = useDeletePaymentSource();

  const { mutate: depositFunds } = useDepositFunds();

  const handleDepositFunds = (payload: DepositFundsPayload) => {
    depositFunds(payload, {
      onSuccess: (data) => {
        enqueueSnackbar(data.data.message, {
          variant: "success",
        });
        queryClient.invalidateQueries(QueryKeys.GetUserWallet);
      },
    });
  };

  const onDeleteFundingSource = (fundingSourceId: string) => {
    const payload = {
      fundingSourceId,
    };

    deletePaymentSource(payload, {
      onSuccess: (data) => {
        enqueueSnackbar(data.data.message, {
          variant: "success",
        });
        queryClient.invalidateQueries(QueryKeys.PaymentSources);
      },
      onError: (error: any) => {
        const errorMessage = error.response.data.message;
        enqueueSnackbar(errorMessage, {
          variant: "default",
        });
      },
    });
  };

  return (
    <Fragment>
      <ProfileLayout>
        <WalletTab
          onAddFunds={(payload: DepositFundsPayload) => {
            handleDepositFunds(payload);
          }}
          availableBalance={walletInfo?.balance || 0}
          isVerified={gatewayInfo?.status === "verified"}
          paymentSources={
            tsevoSession?.PaymentMethods.filter(
              (paymentMethod: any) => paymentMethod.Type === "CC"
            ) || []
          }
          onDeleteFundingSource={onDeleteFundingSource}
        />
      </ProfileLayout>
    </Fragment>
  );
}

import { Link } from "react-router-dom";
import { Grid, ButtonBase } from "@mui/material";
import { LogoFullLarge } from "@shared/components/atoms/logos/LogoFullLarge";
import { SidebarItemType } from "@shared/types";
import { SidebarItem } from "./Item";
import { BalanceAndDeposit } from "./BalanceAndDeposit";
import { ProfileButton } from "./ProfileButton";
import { Toolbar } from "./styles";
import { AppRoot, routesForApp } from "@shared/constants/routePaths";
export interface DrawerContentsProps {
  items: Array<SidebarItemType>;
  onItemClick: (item: SidebarItemType) => void;
  onOpenUserMenu: (anchorEl: Element) => void;
  profilePagePath: string;
}
const ROUTE = routesForApp<AppRoot.PGA>(AppRoot.PGA);

export function DrawerContents(props: DrawerContentsProps) {
  const { items, onItemClick, onOpenUserMenu, profilePagePath } = props;

  return (
    <>
      <Toolbar>
        <Grid container justifyContent="center" height={"100%"}>
          <ButtonBase component={Link} to={ROUTE.battleground.lobby}>
            <LogoFullLarge width={150} />
          </ButtonBase>
        </Grid>
      </Toolbar>
      <Grid
        container
        direction="column"
        flex={1}
        justifyContent="space-between"
      >
        <Grid item />
        <Grid item container direction="column" px={2}>
          {items.map((item) => {
            return (
              <SidebarItem
                key={item.path}
                activePaths={item.activePaths}
                handleLinkClick={() => onItemClick(item)}
                label={item.label}
                path={item.path}
                ActiveIcon={item.activeIcon}
                InactiveIcon={item.inactiveIcon}
              />
            );
          })}
        </Grid>
        <Grid item>
          <BalanceAndDeposit profilePagePath={profilePagePath} />
          <ProfileButton onOpenUserMenu={onOpenUserMenu} />
        </Grid>
      </Grid>
    </>
  );
}

import { Stack, Typography } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import HelpIcon from "@mui/icons-material/Help";
import BlockIcon from '@mui/icons-material/Block';
import { blue, green, red } from "@mui/material/colors";
import { UserVerificationStatusType } from "@shared/types";

export function VerificationStatus({
  status,
}: {
  status: UserVerificationStatusType;
}) {
  return status === UserVerificationStatusType.Unverified ? (
    <Stack direction="row" sx={{ color: red[500] }} justifyContent={"flex-end"}>
      <ErrorIcon />
      <Typography>Unverified</Typography>
    </Stack>
  ) : status === UserVerificationStatusType.Suspended ? (
    <Stack direction="row" sx={{ color: red[500] }} justifyContent={"flex-end"}>
      <ErrorIcon />
      <Typography>Suspended</Typography>
    </Stack>
  ) : status === UserVerificationStatusType.Document ? (
    <Stack
      direction="row"
      sx={{ color: blue[500] }}
      justifyContent={"flex-end"}
    >
      <HelpIcon />
      <Typography>Provide Document</Typography>
    </Stack>
  ) : status === UserVerificationStatusType.Retry ? (
    <Stack
      direction="row"
      sx={{ color: blue[500] }}
      justifyContent={"flex-end"}
    >
      <HelpIcon />
      <Typography>Retry</Typography>
    </Stack>
  ) : status === UserVerificationStatusType.Verified ? (
    <Stack
      direction="row"
      sx={{ color: green[500] }}
      justifyContent={"flex-end"}
    >
      <CheckCircleIcon />
      <Typography>Verified</Typography>
    </Stack>
  ) : status === UserVerificationStatusType.Deactivated ? (
    <Stack direction="row" sx={{ color: red[500] }} justifyContent={"flex-end"}>
      <ErrorIcon />
      <Typography>Deactivated</Typography>
    </Stack>
  ) : status === UserVerificationStatusType.Blocked ? (
    <Stack direction="row" sx={{ color: red[500] }} justifyContent={"flex-end"}>
      <BlockIcon />
      <Typography>Blocked</Typography>
    </Stack>
  ) : (
    <Stack direction="row" sx={{ color: red[500] }} justifyContent={"flex-end"}>
      <ErrorIcon />
      <Typography>Unknown</Typography>
    </Stack>
  );
}

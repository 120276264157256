import { useMutation } from "react-query";
import { deleteFundingSourceRequest } from "@src/services/wallet";
import { DeleteFundingSourcePayload } from "@shared/types";

export function useDeletePaymentSource() {
  return useMutation<any, Error, DeleteFundingSourcePayload>((variables) =>
    deletePaymentSourceRequestHandler(variables)
  );
}

async function deletePaymentSourceRequestHandler(
  variables: DeleteFundingSourcePayload
): Promise<any> {
  return deleteFundingSourceRequest(variables);
}

import { Box } from "@mui/material";
import { matchPath, useLocation } from "react-router-dom";

export interface TabProps {
  label: string;
  path: string;
  badge?: JSX.Element;
}

export interface TabPanelProps {
  children?: React.ReactNode;
  tabs: Array<TabProps>;
  accessabilityControlKey?: string;
}

export function TabPanel(props: TabPanelProps) {
  const { children, tabs, accessabilityControlKey, ...other } = props;

  const { pathname } = useLocation();
  const matchedPathIndex = tabs.findIndex((tab) =>
    matchPath(tab.path, pathname)
  );

  const value = matchedPathIndex;
  const index = matchedPathIndex;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`${accessabilityControlKey}-${index}`}
      aria-labelledby={`${accessabilityControlKey}-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ py: 3 }}>{children}</Box>}
    </div>
  );
}

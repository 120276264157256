import { StrictMode } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { SnackbarProvider } from "notistack";
import { theme } from "@shared/components/tokens";
import { AppRoutes } from "./Routes";
import { RecoilRoot } from "recoil";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

ReactDOM.render(
  <BrowserRouter
    basename={process.env.NODE_ENV === "development" ? "/account" : ""}
  >
    <StrictMode>
      <RecoilRoot>
        <QueryClientProvider client={queryClient}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <SnackbarProvider
              maxSnack={2}
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <AppRoutes />
            </SnackbarProvider>
          </ThemeProvider>
        </QueryClientProvider>
      </RecoilRoot>
    </StrictMode>
  </BrowserRouter>,
  document.getElementById("root")
);

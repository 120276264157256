import { useCallback, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { SignUp as SignUpTemplate } from "@shared/components/templates/SignUp";
import { ISignUpPayload, signUp } from "@src/services/auth";
import { AppRoot, routesForApp } from "@shared/constants/routePaths";
import BaseLayout from "@src/components/templates/BaseLayout";

const routes = routesForApp<AppRoot.ACCOUNT>(AppRoot.ACCOUNT);

export function SignUp() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [searchParams] = useSearchParams({});
  const [isLoading, setIsLoading] = useState(false);
  const promoCode = searchParams.get("promo");

  const handleSignup = useCallback(
    async (payload: ISignUpPayload) => {
      setIsLoading(true);
      try {
        await signUp(payload);
        setIsLoading(false);
        enqueueSnackbar(
          "Successfully created user. Please login to start playing",
          {
            variant: "success",
          }
        );
        navigate(routes.mobilePromotion);
      } catch (errors: any) {
        setIsLoading(false);
        enqueueSnackbar(errors.response.data.message, {
          variant: "default",
        });
      }
    },
    [navigate, enqueueSnackbar]
  );

  if (!process.env.REACT_APP_GOOGLE_GEOCODING_KEY) {
    return null;
  }

  return (
    <BaseLayout>
      <SignUpTemplate
        fallbackCountry="IN"
        fallbackState="GA"
        geocodingKey={process.env.REACT_APP_GOOGLE_GEOCODING_KEY}
        onSignUp={handleSignup}
        isLoading={isLoading}
        promoCode={promoCode}
      />
    </BaseLayout>
  );
}

import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { ForgotPassword as ForgotPasswordTemplate } from "@shared/components/templates/ForgotPassword";
import { forgotPassword } from "@src/services/auth";
import { AppRoot, routesForApp } from "@shared/constants/routePaths";
import BaseLayout from "@src/components/templates/BaseLayout";

const routes = routesForApp<AppRoot.ACCOUNT>(AppRoot.ACCOUNT);

interface IFormValues {
  email: string;
}

export function ForgotPassword() {
  const navigate = useNavigate();

  const onForgotPassword = useCallback(
    async (payload: IFormValues) => {
      try {
        await forgotPassword(payload);
        // TODO @irina: display a success message
        navigate(routes.login);
      } catch (errors) {
        // TODO @irina: display a toast message
        console.log(errors);
      }
    },
    [navigate]
  );

  return (
    <BaseLayout>
      <ForgotPasswordTemplate onForgotPassword={onForgotPassword} />
    </BaseLayout>
  );
}

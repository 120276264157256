// @ts-nocheck

import React from "react";
import { Box } from "@mui/material";

const TermsOfService = () => {
  return (
    <Box sx={{ paddingBottom: "6rem" }}>
      <b>Terms of Service</b>
      <p />
      <p style={{ marginBottom: "0cm" }}>Last Revised: August 1, 2022</p>
      <p style={{ marginBottom: "0cm" }}>
        <br />
      </p>
      <p style={{ marginBottom: "0cm" }}>
        <br />
      </p>
      <p style={{ marginBottom: "0cm" }}>
        <b>IMPORTANT NOTICE</b>:{" "}
        <b>
          THIS AGREEMENT IS SUBJECT TO BINDING ARBITRATION AND A WAIVER OF CLASS
          ACTION RIGHTS AS DETAILED IN{" "}
        </b>
        <u>
          <b>SECTION 23</b>
        </u>
        <b>.</b>
      </p>
      <p style={{ marginBottom: "0cm" }}>
        <br />
      </p>
      <p style={{ marginBottom: "0cm" }}>
        This Terms of Service and End User License Agreement (“<b>Terms</b>
        ”) is a legal agreement between you (the “<b>user</b>”) and TopProp
        Corporation (“<b>TopProp</b>”, “<b>we</b>”, “<b>us</b>”). The Terms
        goven your{" "}
        <font color="#212529">
          <span style={{ textDecoration: "none" }}>
            access and use of all websites, applications, browser extensions,
            and other online products and services provided by us that link to
            these Terms, including{" "}
          </span>
        </font>
        <u>
          <a href="http://www.toppropsports.com/">
            http://www.toppropsports.com
          </a>
        </u>
        <font color="#212529">
          <span style={{ textDecoration: "none" }}>
            (and all related subdomains) (the “
          </span>
        </font>
        <font color="#212529">
          <span style={{ textDecoration: "none" }}>
            <b>Site</b>
          </span>
        </font>
        <font color="#212529">
          <span style={{ textDecoration: "none" }}>
            ”) and related online and offline services thereto, including any
            services, transactions, or engagement you might have with us
            (collectively, the “
          </span>
        </font>
        <font color="#212529">
          <span style={{ textDecoration: "none" }}>
            <b>Services</b>
          </span>
        </font>
        <font color="#212529">
          <span style={{ textDecoration: "none" }}>”).</span>
        </font>
      </p>
      <p style={{ marginBottom: "0cm" }}>
        <br />
      </p>
      <p style={{ marginBottom: "0cm" }}>
        <br />
      </p>
      <ol>
        <li>
          <p style={{ marginBottom: "0cm" }}>
            <b>Acceptance of Terms</b>
          </p>
        </li>
      </ol>
      <p style={{ marginLeft: "0.64cm", marginBottom: "0cm" }}>
        <br />
      </p>
      <p style={{ marginBottom: "0cm" }}>
        <b>
          BY USING OR OTHERWISE ACCESSING THE SERVICES, OR TAKING ANY ACTION TO
          ACCEPT OR AGREE TO THESE TERMS WHERE THE OPTION IS MADE AVAILABLE,
          YOU, YOUR HEIRS, AND ASSIGNS (COLLECTIVELY, “YOU”) AGREE TO BE BOUND
          BY THESE TERMS, ACKNOWLEDGE OUR PRIVACY POLICY, AND ACCEPT ALL OTHER
          GOVERNING AGREEMENTS.
        </b>
      </p>
      <p style={{ marginBottom: "0cm" }}>
        <br />
      </p>
      <ol start={2}>
        <li>
          <p style={{ marginBottom: "0cm" }}>
            <b>Modification to Terms</b>
          </p>
        </li>
      </ol>
      <p style={{ marginLeft: "0.64cm", marginBottom: "0cm" }}>
        <br />
      </p>
      <p style={{ marginBottom: "0cm" }}>
        Except for Section 23 providing for binding arbitration and a waiver of
        class action rights, TopProp reserves the right, at its sole discretion,
        to modify or replace the Terms at any time. You shall be responsible for
        reviewing and becoming familiar with any such modifications. Any changes
        will be incorporated into the Terms and you should check the Terms
        periodically for updates. Changes will be effective immediately unless
        otherwise provided. Use of the Services by you following such
        modification constitutes your acceptance of the Terms as modified.
      </p>
      <p style={{ marginBottom: "0cm" }}>
        <br />
      </p>
      <ol start={3}>
        <li>
          <p style={{ marginBottom: "0cm" }}>
            <b>Privacy Policy and Other Terms</b>
          </p>
        </li>
      </ol>
      <p style={{ marginLeft: "0.64cm", marginBottom: "0cm" }}>
        <br />
      </p>
      <p style={{ marginBottom: "0cm" }}>
        Please review the TopProp <u>Privacy Policy</u>, which is hereby
        incorporated by reference (the “<b>TopProp Privacy Policy</b>”) and
        governs our use and collection of your information. In the event that
        you are required to agree to an additional governing agreement,
        including without limitation a terms of service, end user license
        agreement, privacy policy, contest, sweepstakes or promotion terms or
        rules (collectively, “<b>Other Governing Agreement</b>”), in order to
        use, access and/or download such other service, the Other Governing
        Agreement will govern to the extent there is a conflict between these
        Terms and the Other Governing Agreement. TopProp may issue additional
        terms, rules and conditions of participation in particular contests. You
        agree to be subject to those additional rules if you participate in such
        contests.
      </p>
      <p style={{ marginBottom: "0cm" }}>
        <br />
      </p>
      <ol start={4}>
        <li>
          <p style={{ marginBottom: "0cm" }}>
            <b>Eligibility</b>
          </p>
        </li>
      </ol>
      <p style={{ marginBottom: "0cm" }}>
        <br />
      </p>
      <p style={{ marginBottom: "0cm" }}>
        You hereby represent and warrant that you are fully able and competent
        to enter into the terms, conditions, obligations, affirmations,
        representations and warranties set forth in these Terms and to abide by
        and comply with these Terms. Access to and use of the Services is
        available only to individuals who are at least 18 years old and can form
        legally binding contracts under applicable law. By accessing or using
        the Services, you represent and warrant that you are eligible.
      </p>
      <p style={{ marginBottom: "0cm" }}>
        <br />
      </p>
      <p style={{ marginBottom: "0cm" }}>
        By depositing money or entering a contest with a prize, you are
        representing and warranting that:
      </p>
      <ul>
        <li>
          <p style={{ marginBottom: "0cm" }}>
            you are a natural person of at least 18 years of age or the age of
            majority in your jurisdiction, whichever is higher, who is
            personally assigned to the email address and/or other information
            submitted in relation to your account;
          </p>
        </li>
        <li>
          <p style={{ marginBottom: "0cm" }}>
            you are a citizen or resident of (and have an address in) the United
            States of America;
          </p>
        </li>
        <li>
          <p style={{ marginBottom: "0cm" }}>
            you do not, by employment or affiliation with a fantasy sports
            league provider or service provider associated with the fantasy
            sports league provider, have access to pre-release non-public
            confidential data about fantasy contest-related information;
          </p>
        </li>
        <li>
          <p style={{ marginBottom: "0cm" }}>
            you are not, by employment or affiliation with a professional sports
            team or other entity, or for any other reason, restricted from
            entering into a fantasy sports contest with a prize;
          </p>
        </li>
        <li>
          <p style={{ marginBottom: "0cm" }}>
            at the time of deposit and participation in a contest, you are
            physically located in the United States of America in a jurisdiction
            in which participation in the contest is not prohibited by
            applicable law;
          </p>
        </li>
        <li>
          <p style={{ marginBottom: "0cm" }}>
            you will abide at all times by these Terms and any Other Governing
            Agreement between you and TopProp regarding your use of the Services
            or participation in contests;
          </p>
        </li>
        <li>
          <p style={{ marginBottom: "0cm" }}>
            when depositing funds or entering a paid contest, you are not
            physically located in of any of the following states: Alabama,
            Arizona, Colorado, Connecticut, Delaware, Hawaii, Idaho, Indiana,
            Iowa, Louisiana, Maine, Maryland, Massachusetts, Michigan, Missouri,
            Montana, New Hampshire, New Jersey, Nevada, Ohio, Pennsylvania,
            Tennessee, Virginia or Washington;
          </p>
        </li>
        <li>
          <p style={{ marginBottom: "0cm" }}>
            the name on your bank account, credit card, and other payment and
            withdrawal information provided matches the name on your account;
          </p>
        </li>
        <li>
          <p style={{ marginBottom: "0cm" }}>
            you are not subject to backup withholding tax because: (a) you are
            exempt from backup withholding, or (b) you have not been notified by
            the Internal Revenue Service (IRS) that you are subject to backup
            withholding as a result of a failure to report all interest or
            dividends, or (c) the IRS has notified you that you are no longer
            subject to backup withholding.
          </p>
        </li>
        <li>
          <p style={{ marginBottom: "0cm" }}>
            when entering any contest that awards prizes, you are not an
            employee of a fantasy sports league developer or service provider
            associated with the fantasy sports league associated with that
            contest.
          </p>
        </li>
        <li>
          <p style={{ marginBottom: "0cm" }}>
            you do not, by virtue of affiliation with a fantasy sports league
            developer or service provider, have access to source code underlying
            a fantasy sports league associated with the entered contest.
          </p>
        </li>
      </ul>
      <p style={{ marginBottom: "0cm" }}>
        <br />
      </p>
      <p style={{ marginBottom: "0cm" }}>
        If you do not meet the eligibility requirements of this section, then
        you are not permitted to use the Services and you agree that you will
        not use the Services. In addition to any other rights that TopProp may
        have in law or equity, TopProp reserves the right to suspend or
        terminate the account of any purported user of the Services that, in our
        sole discretion, does not meet the foregoing requirements.
      </p>
      <p style={{ marginBottom: "0cm" }}>
        <br />
      </p>
      <p style={{ marginBottom: "0cm" }}>
        TopProp may require you to provide proof that you are eligible to
        participate according to this Section 4 prior to receiving a prize. This
        includes by requesting that you fill out an affidavit of eligibility or
        other verification information (as discussed in Sections 5 and 6 below).
        If TopProp otherwise determines, in our sole discretion, that you do not
        meet the eligibility requirements of this section, in addition to any
        rights that TopProp may have in law or equity, TopProp reserves the
        right to terminate your account, withhold or revoke the awarding of any
        prizes associated with your account or limit your ability to withdraw
        funds. In such a situation, TopProp may pay out any withheld or revoked
        prizes to the other entrant(s) in the relevant contest in a manner
        consistent with the prize structure of the contest, to be precisely
        determined by TopProp in its sole discretion. TopProp also reserves the
        right to withhold revoked prizes to use in furtherance of its fraud
        prevention or anti-money laundering efforts.
      </p>
      <p style={{ marginBottom: "0cm" }}>
        TopProp employees may use the Services for the purpose of testing the
        user experience, but may not enter paid contests unless they are private
        contests with other TopProp employees. Relatives of TopProp employees
        with whom they share a household are not eligible to participate in paid
        contests unless they are private contests with other TopProp employees
        or household members.
      </p>
      <p style={{ marginBottom: "0cm" }}>
        <br />
      </p>
      <p style={{ marginBottom: "0cm" }}>
        TopProp consultants or promoters of the Services may play in contests
        without such limitation, but only if (i) their arrangement with TopProp
        does not permit them to have any access to the source code underlying
        the TopProp Services and (ii) they do not receive any other advantages
        in their play on the Services.
      </p>
      <p style={{ marginBottom: "0cm" }}>
        <br />
      </p>
      <p style={{ marginBottom: "0cm" }}>
        Employees or operators of other fantasy sports services that charge
        entry fees or offer cash prizes, and individuals who, by virtue of
        affiliation with another fantasy sports service, have access to the
        site’s pre-release non-public confidential data about game-related
        information may not enter any contests in which a real money prize is
        awarded. If such person enters a TopProp contest that awards prizes,
        TopProp will disqualify the entry, will not award a prize, and may
        report such person’s violation of this provision to the fantasy sports
        service for which the entrant is employed by, operates or affiliated
        with. Additionally, TopProp may maintain information about the entrant
        sufficient to assist TopProp in blocking the user from entering future
        TopProp contests, unless and until TopProp determines, in its sole
        discretion, that the entrant is no longer an employee or operator of
        another fantasy sports service or no longer has access to pre-release
        non-public confidential data about game-related information by virtue of
        affiliation with a fantasy sports service.
      </p>
      <p style={{ marginBottom: "0cm" }}>
        <br />
      </p>
      <p style={{ marginBottom: "0cm" }}>
        Athletes, coaches and other team management, team support personnel
        (e.g. without limitation, team physicians) and team owners may not
        participate in any TopProp contests in the sport or sports with which
        they’re associated. Team owners, referees, league employees, sports
        commissioners and other individuals who through an ownership interest or
        game-related employment can influence the gameplay are likewise
        ineligible.
      </p>
      <p style={{ marginBottom: "0cm" }}>
        <br />
      </p>
      <p style={{ marginBottom: "0cm" }}>
        TopProp may offer tools, features, and support meant to encourage
        healthy player behavior and deliver positive player experiences. We may
        also allow qualified third parties, who have concerns about a player’s
        ability to manage his or her play, to request a limitation on that
        player’s use of the Services. You can learn more about our responsible
        play policies and tools at [LINK] or by contacting us at
        support@toppropfantasy.com.
      </p>
      <p style={{ marginBottom: "0cm" }}>
        <br />
      </p>
      <p style={{ marginBottom: "0cm" }}>
        TopProp is not affiliated with or sponsored by the National Football
        League, the National Basketball Association, the National Hockey League
        or Major League Baseball.
      </p>
      <p style={{ marginBottom: "0cm" }}>
        <br />
      </p>
      <ol start={5}>
        <li>
          <p style={{ marginBottom: "0cm" }}>
            <b>Conditions of Participation</b>
          </p>
        </li>
      </ol>
      <p style={{ marginLeft: "0.64cm", marginBottom: "0cm" }}>
        <br />
      </p>
      <p style={{ marginBottom: "0cm" }}>
        In order to use the Services, including participation in a contest on
        the Services, you are required to register for an account. By
        registering for an account, you agree to: (i) provide accurate, current
        and complete information about yourself (including usernames and other
        identifiers associated with your account and third-party fantasy sports
        service accounts) as prompted (such information being the “
        <b>Registration Data</b>”); (ii) maintain the security and
        confidentiality of the Registration Data; (iii) promptly update the
        Registration Data to keep it accurate, current and complete; (iv) ensure
        that others do not use your account; and (v) notify TopProp immediately
        in the event of unauthorized use of, or any other breach of your
        Registration Data.
      </p>
      <p style={{ marginBottom: "0cm" }}>
        <br />
      </p>
      <p style={{ marginBottom: "0cm" }}>
        If you provide any Registration Data that is inaccurate, not current or
        incomplete, or TopProp has reasonable grounds to suspect that such
        Registration Data is inaccurate, not current or incomplete, TopProp may
        deny you access to areas requiring registration or terminate your
        account, at its sole discretion. You agree that TopProp is not liable
        for any loss or damage resulting from your failure to maintain the
        confidentiality of your account as provided herein. TopProp may assume
        that any communications TopProp receives under your password have been
        made by you. You are fully responsible for all uses of your account,
        whether by you or others. You acknowledge and agree that TopProp is
        authorized to act on instructions received through use of your
        Registration Data, and that TopProp may, but is not obligated to, deny
        access or block any transaction made through use of your Registration
        Data without prior notice if we believe in our sole discretion such
        information is being used by someone other than you, or for any other
        reason.
      </p>
      <p style={{ marginBottom: "0cm" }}>
        <br />
      </p>
      <p style={{ marginBottom: "0cm" }}>
        You may establish, maintain, use and control only one account on the
        Services. Each account on the Services may only be owned, maintained,
        used and controlled by only one individual. For avoidance of doubt,
        users may not “co-own” accounts on the Services. In the event TopProp
        determines that you have opened, maintained, used or controlled more
        than one account, in addition to any other rights that TopProp may have,
        TopProp reserves the right to suspend or terminate any or all of your
        accounts and terminate, withhold or revoke the awarding of any prizes.
      </p>
      <p style={{ marginBottom: "0cm" }}>
        <br />
      </p>
      <p style={{ marginBottom: "0cm" }}>
        You agree that the sole and specific purpose of registering an account
        on TopProp is to participate in contests on the Services. TopProp shall
        be entitled to suspend, limit or terminate your account if we determine,
        in our sole discretion, that you are depositing funds without the
        intention of using them in contests on the Services. In such
        circumstances, we may also report such activity to relevant authorities.
      </p>
      <p style={{ marginBottom: "0cm" }}>
        <br />
      </p>
      <p style={{ marginBottom: "0cm" }}>
        You may not use a username that promotes a commercial venture or a
        username that TopProp in its sole discretion deems offensive. Many
        portions of the Services require registration for access (the “
        <b>Restricted Areas</b>”). TopProp may require you to change your
        username or may unilaterally change your username.
      </p>
      <p style={{ marginBottom: "0cm" }}>
        <br />
      </p>
      <p style={{ marginBottom: "0cm" }}>
        <font color="#000000">
          <span lang="en-IN">
            By providing an email address, you authorize TopProp to provide you
            with important announcements, relevant promotions, and other related
            communications relating to the Services and contests, consistent
            with the{" "}
          </span>
        </font>
        TopProp <u>Privacy Policy</u>
        <font color="#000000">
          <span lang="en-IN">
            . You may opt out of these communications at any time by emailing us
            at support@toppropfantasy.com
          </span>
        </font>{" "}
        or writing to us at 140 Arsenal Street, #2327, Watertown, MA 02472,
        Attn: TopProp Support. TopProp cannot and will not be liable for any
        loss or damage arising from your failure to comply with this Section.
      </p>
      <p style={{ marginBottom: "0cm" }}>
        <br />
      </p>
      <ol start={6}>
        <li>
          <p style={{ marginBottom: "0cm" }}>
            <b>Disqualification and Cancellation</b>.
          </p>
        </li>
      </ol>
      <p style={{ marginBottom: "0cm" }}>
        <br />
      </p>
      <p style={{ marginBottom: "0cm" }}>
        TopProp, in its sole discretion, may disqualify you from a contest or
        the Services, refuse to award consideration or prizes and require the
        return of any consideration or prizes, or suspend, limit, or terminate
        your account if you engage in conduct TopProp deems, in its sole
        discretion, to be improper, unfair, fraudulent or otherwise adverse to
        the operation of the Services or in any way detrimental to other users.
        Improper conduct includes, but is not limited to:
      </p>
      <p style={{ marginBottom: "0cm" }}>
        <br />
      </p>
      <ul>
        <li>
          <p style={{ marginBottom: "0cm" }}>
            falsifying personal information, including payment information,
            required to use the Services or claim consideration or prizes;
          </p>
        </li>
        <li>
          <p style={{ marginBottom: "0cm" }}>
            violating eligible payment method terms, including the terms of any
            cash rewards payment card, violating any of these rules;
          </p>
        </li>
        <li>
          <p style={{ marginBottom: "0cm" }}>
            accumulating consideration or prizes through unauthorized methods
            such as unauthorized scripts or other automated means;
          </p>
        </li>
        <li>
          <p style={{ marginBottom: "0cm" }}>
            tampering with the administration of the Services or trying to in
            any way tamper with the computer programs associated with the
            Service;
          </p>
        </li>
        <li>
          <p style={{ marginBottom: "0cm" }}>
            obtaining other users’ information and spamming other users;
          </p>
        </li>
        <li>
          <p style={{ marginBottom: "0cm" }}>
            abusing the Services in any way;
          </p>
        </li>
        <li>
          <p style={{ marginBottom: "0cm" }}>
            or otherwise violating these Terms or any other agreement between
            you and another user or you and TopProp regarding your use of the
            Services or entry into a contest.
          </p>
        </li>
      </ul>
      <p style={{ marginBottom: "0cm" }}>
        <br />
      </p>
      <p style={{ marginBottom: "0cm" }}>
        You acknowledge that the forfeiture and/or return of any consideration
        or prizes shall in no way prevent TopProp from informing the relevant
        authorities, and/or pursuing criminal or civil proceedings in connection
        with such conduct.
      </p>
      <p style={{ marginBottom: "0cm" }}>
        <br />
      </p>
      <p style={{ marginBottom: "0cm" }}>
        If for any reason the Services are not running as originally planned
        (e.g., if the Services become corrupted or do not allow the proper usage
        and processing of contests in accordance with the rules, or if infection
        by a computer virus, bugs, tampering, unauthorized intervention, actions
        by entrants, fraud, technical failures, or any other causes of any kind,
        in the sole opinion of TopProp corrupts or affects the administration,
        security, fairness, integrity or proper conduct of the Services),
        TopProp reserves the right, in its sole discretion, to cancel,
        terminate, extend, modify, or suspend the contest or Services, and/or
        select the winner(s) of a contest based on its then-current status. In
        addition, TopProp may, in its sole discretion, disqualify any individual
        implicated in or relating to the cause.
      </p>
      <p style={{ marginBottom: "0cm" }}>
        <br />
      </p>
      <p style={{ marginBottom: "0cm" }}>
        TopProp reserves the right to cancel, terminate, modify, or suspend
        contests, in our sole discretion, without any restrictions, in which
        case notification may be posted through the Services.
      </p>
      <p style={{ marginBottom: "0cm" }}>
        <br />
      </p>
      <p style={{ marginBottom: "0cm" }}>
        The failure of TopProp to comply with any provision of these Terms due
        to an act of God, hurricane, war, fire, riot, earthquake, terrorism, act
        of public enemies, actions of governmental authorities outside of the
        control of TopProp (excepting compliance with applicable codes and
        regulations) or other force majeure event will not be considered a
        breach of these Terms.
      </p>
      <p style={{ marginBottom: "0cm" }}>
        <br />
      </p>
      <ol start={7}>
        <li>
          <p style={{ marginBottom: "0cm" }}>
            <b>Deposits, Withdrawals, and Taxes</b>
          </p>
        </li>
      </ol>
      <p style={{ marginLeft: "0.64cm", marginBottom: "0cm" }}>
        <br />
      </p>
      <p style={{ marginBottom: "0cm" }}>
        By depositing funds, entering paid contests, or withdrawing funds, you
        agree to provide us with information we request, which may include a
        valid postal address, date of birth, government identifiers, and Social
        Security Number, and any other information we may require in order to
        run appropriate identity checks and comply with applicable rules and
        regulations. If necessary, you may be required to provide appropriate
        documentation that allows us to verify you. While your account is
        pending verification, you may be able to deposit funds into your account
        and participate in contests, but you will not be able to withdraw any
        funds from your account until verification is complete. If we are unable
        to verify you, we reserve the right to suspend your account and withhold
        any funds until such time as we have been able to successfully verify
        you.
      </p>
      <p style={{ marginBottom: "0cm" }}>
        <br />
      </p>
      <p style={{ marginBottom: "0cm" }}>
        We also may conduct checks for compliance with these Terms and Other
        Governing Agreements, including anti-fraud checks on playing patterns
        and deposits prior to processing a withdrawal, and we may request
        additional information before permitting a withdrawal. Subject to such
        checks, you may close your account and withdraw your deposits and/or
        winnings at any time and for any reason. Deposits, and player winnings
        after contests are finished, are held in a separate, segregated bank
        account by TopProp. These funds belong to you, subject to review for
        evidence of fraud, verification or other prohibited conduct as described
        above, and TopProp may not use them to cover its operating expenses or
        for other purposes. Your withdrawals will be made from this segregated
        bank account, and checks issued from that account may bear the name of
        TopProp or an affiliate. TopProp may limit the amount a user can deposit
        into his or her account in its sole discretion and/or in accordance with
        potentially applicable state-imposed limits.
      </p>
      <p style={{ marginBottom: "0cm" }}>
        <br />
      </p>
      <p style={{ marginBottom: "0cm" }}>
        Each year all winners who have won $600 or more over the previous year
        must provide updated address and social security details to TopProp.
        These details will be used to allow TopProp to comply with tax
        regulations and may be shared with appropriate tax authorities. You, not
        TopProp, are responsible for filing and paying applicable state and
        federal taxes, duties, levies and/or fees on any winnings. TopProp does
        not provide tax advice, nor should any statements in this agreement or
        on the Services be construed as tax advice.
      </p>
      <p style={{ marginBottom: "0cm" }}>
        <br />
      </p>
      <p style={{ marginBottom: "0cm" }}>
        In order to use the payment functionality of our Services, you must open
        a “Dwolla Platform” account provided by Dwolla, Inc. and you must accept
        the{" "}
        <u>
          <a href="https://www.dwolla.com/legal/tos/">
            Dwolla Terms of Service
          </a>
        </u>{" "}
        and{" "}
        <u>
          <a href="https://www.dwolla.com/legal/privacy/">Privacy Policy</a>
        </u>
        . Any funds held in or transferred through the Dwolla Account are held
        or transferred by Dwolla’s{" "}
        <u>
          <a href="https://www.dwolla.com/legal/about-our-financial-institution-partners/">
            financial institution partners
          </a>
        </u>{" "}
        as described in the Dwolla Terms of Service. You authorize us to collect
        and share with Dwolla your personal information including full name,
        date of birth, social security number, physical address, email address
        and financial information, and you are responsible for the accuracy and
        completeness of that data. You understand that you will access and
        manage your Dwolla account through our Services, and Dwolla account
        notifications may be sent by us, not Dwolla. We may provide customer
        support for your Dwolla account activity, and can be reached using the
        information in the “Contacting Us” section below.
      </p>
      <p style={{ marginBottom: "0cm" }}>
        <br />
      </p>
      <ol start={8}>
        <li>
          <p style={{ marginBottom: "0cm" }}>
            <b>Publicity</b>
          </p>
        </li>
      </ol>
      <p style={{ marginBottom: "0cm" }}>
        <br />
      </p>
      <p style={{ marginBottom: "0cm" }}>
        By entering a contest, you consent to TopProp’s and its service
        providers’ and business partners’ use of your name, username, state and
        country of residence, games played, gaming identities, and photos in
        connection with the development, production, distribution and/or
        exploitation (including marketing and promotion) of the selected contest
        and/or other TopProp contests and TopProp generally, unless otherwise
        prohibited by law. TopProp and its business partners reserve the right
        to make public statements about the entrants and winner(s), on-air, on
        the Internet, or otherwise, prior to, during, or following the contest.
        Entrants agree that TopProp may announce any winner’s name on-air or on
        any of its websites or any other location at any time in connection with
        the marketing and promotion of TopProp or other contests or games
        operated by TopProp. You agree that participation in and (where
        applicable) the winning of a prize in connection with a contest
        constitute complete compensation for your obligations under this
        paragraph, and you agree not to seek to charge a fee or impose other
        conditions on the fulfillment of these obligations. The rules specific
        to certain contests may contain additional publicity obligations or may
        require a written signature on a separate publicity waiver.
      </p>
      <p style={{ marginBottom: "0cm" }}>
        <br />
      </p>
      <ol start={9}>
        <li>
          <p style={{ marginBottom: "0cm" }}>
            <b>Contests</b>
          </p>
        </li>
      </ol>
      <p style={{ marginLeft: "0.64cm", marginBottom: "0cm" }}>
        <br />
      </p>
      <p style={{ marginBottom: "0cm" }}>
        Each contest is governed by the specific contest rules, scoring rules,
        controls and guidelines for that contest as may be described in the
        Other Governing Agreements. Such rules, scoring rules, controls and
        guidelines form part of these Terms and you agree that you shall comply
        with them in respect of each individual contest which you choose to
        enter.
      </p>
      <p style={{ marginBottom: "0cm" }}>
        <br />
      </p>
      <p style={{ marginBottom: "0cm" }}>
        <b>Game of Skill.</b> TopProp is a game of skill. Winners are determined
        by the criteria stated in each contest’s rules. For each contest,
        winners are determined by the individuals who use their skill and
        knowledge of relevant League and Association information and fantasy
        sports rules to accumulate the most fantasy points. Fantasy points are
        accumulated through the performance of individual athletes in League and
        Association events.
      </p>
      <p style={{ marginBottom: "0cm" }}>
        <br />
      </p>
      <p style={{ marginBottom: "0cm" }}>
        <b>Entry and Entry Fees.</b> When you opt to participate in a contest
        with an entry fee, you will need to pay the required entry fee. Then,
        follow the links and instructions provided for entry.
      </p>
      <p style={{ marginBottom: "0cm" }}>
        <br />
      </p>
      <p style={{ marginBottom: "0cm" }}>
        In the event of a dispute regarding the identity of the person
        participating in a contest, the entry will be deemed submitted by the
        person in whose username the entry was submitted, or if possession of
        the username itself is contested and in TopProp’s opinion sufficiently
        uncertain, the name in which the email address on file was registered
        with the email service provider. For contests, TopProp reserves the
        right not to award a prize to an individual it believes in its sole
        discretion did not submit the winning entry.
      </p>
      <p style={{ marginBottom: "0cm" }}>
        <br />
      </p>
      <p style={{ marginBottom: "0cm" }}>
        After each contest ends, a tentative winner is announced but remains
        subject to final verification. No substitution or transfer of a prize is
        permitted. All taxes associated with the receipt or use of any prize are
        the sole responsibility of the winner. In the event that the awarding of
        any prizes to winners of the contest is challenged by any legal
        authority, TopProp reserves the right in its sole discretion to
        determine whether or not to award or adjust such prizes. In all disputes
        arising out of the determination of the winner of TopProp contests,
        TopProp is the sole judge and its actions are final and binding.
      </p>
      <p style={{ marginBottom: "0cm" }}>
        <br />
      </p>
      <p style={{ marginBottom: "0cm" }}>
        Winners are generally notified immediately following the conclusion of
        each contest. Winners may be requested to return via email or regular
        mail an affidavit of eligibility, a publicity agreement and appropriate
        tax forms by a specified deadline. Failure to comply with this
        requirement can result in disqualification.
      </p>
      <p style={{ marginBottom: "0cm" }}>
        <br />
      </p>
      <p style={{ marginBottom: "0cm" }}>
        <b>League Hosting. </b>TopProp does not host your fantasy league.
        TopProp allows you to important your existing leagues from certain
        fantasy league platforms (e.g., Yahoo! and ESPN) to our Services. These
        third party fantasy league platforms, not TopProp, host your season-long
        leagues.
      </p>
      <p style={{ marginBottom: "0cm" }}>
        <br />
      </p>
      <ol start={10}>
        <li>
          <p style={{ marginBottom: "0cm" }}>
            <b>Access to the Services</b>
          </p>
        </li>
      </ol>
      <p style={{ marginLeft: "0.64cm", marginBottom: "0cm" }}>
        <br />
      </p>
      <p style={{ marginBottom: "0cm" }}>
        You must provide, at your own expense, the equipment, software,
        subscriptions, Internet connections or devices and/or service plans,
        which may include, without limitation, content from fantasy sports
        league platforms and service providers, as may be required to access
        and/or use the Services. TopProp does not guarantee that the Services or
        all portion(s) thereof can be accessed on all devices, or in connection
        with all fantasy sports league platforms. TopProp does not guarantee
        that the Services are available in all geographic locations. You
        acknowledge that when you use the Services, your Internet provider or
        fantasy sports league platform or service provider may charge you fees
        for access, data, and/or other contents, products, or services. Check
        with your applicable provider(s) to see if there are any such fees that
        apply to you.
      </p>
      <p style={{ marginBottom: "0cm" }}>
        <br />
      </p>
      <ol start={11}>
        <li>
          <p style={{ marginBottom: "0cm" }}>
            <b>User Content</b>
          </p>
        </li>
      </ol>
      <p style={{ marginBottom: "0cm" }}>
        <br />
      </p>
      <p style={{ marginBottom: "0cm" }}>
        <font color="#000000">
          The Services may enable you to submit, post, upload, or otherwise make
          available through the Services content such as profile information,
          communications with other users, whether privately or made publicly
          available, video clips, photographs, public messages, ideas, comments
          and other content (collectively, “
        </font>
        <font color="#000000">
          <b>User Content</b>
        </font>
        <font color="#000000">
          ”) that may or may not be viewable by other users.
        </font>
      </p>
      <p style={{ marginBottom: "0cm" }}>
        <br />
      </p>
      <p style={{ marginBottom: "0cm" }}>
        <font color="#000000">
          You acknowledge and agree that all User Content, whether publicly
          posted or privately transmitted, is the sole responsibility of the
          person from whom the User Content originated. This means that you, not
          TopProp, are entirely responsible for all User Content that you
          upload, post, share, email, transmit, or otherwise make available via
          the Service. You further agree that you have all required rights to
          submit, post, upload or otherwise use or disseminate such User Content
          without violation of any third-party rights. Under no circumstances
          will TopProp be liable in any way for any User Content.
        </font>
      </p>
      <p style={{ marginBottom: "0cm" }}>
        <br />
      </p>
      <p style={{ marginBottom: "0.69cm", background: "#ffffff" }}>
        <font face="Times New Roman, serif">
          <font color="#000000">
            <font face="Calibri, serif">
              You acknowledge that TopProp may or may not pre-screen User
              Content, but that TopProp and its designees have the right (but
              not the obligation) in their sole discretion to pre-screen,
              refuse, permanently delete, undelete, modify and/or move any User
              Content available via the Service. Without limiting the foregoing,
              TopProp and its designees shall have the right to remove any User
              Content that violates these Terms or is otherwise objectionable in
              TopProp’s sole discretion. You understand that by using the
              Service, you may be exposed to User Content that you may consider
              to be offensive or objectionable. You agree that you must
              evaluate, and bear all risks associated with, the use or
              disclosure of any User Content. You further acknowledge and agree
              that you bear the sole risk of reliance on any Content available
              on or through the Service.
            </font>
          </font>
        </font>
      </p>
      <p style={{ marginBottom: "0.69cm", background: "#ffffff" }}>
        <font face="Times New Roman, serif">
          <font color="#000000">
            <font face="Calibri, serif">
              With respect to User Content you submit or otherwise make
              available on or to the Service, you grant TopProp an irrevocable,
              fully sub-licensable, perpetual, world-wide, royalty-free,
              non-exclusive license to use, distribute, reproduce, modify,
              adapt, publish, translate, publicly perform and publicly display
              such User Content (in whole or in part), and to incorporate such
              User Content into other works, in any format or medium now known
              or later developed.
            </font>
          </font>
        </font>
      </p>
      <p style={{ marginTop: "0.49cm", marginBottom: "0.49cm" }}>
        <font face="Times New Roman, serif">
          <font color="#000000">
            <font face="Calibri, serif">
              You are solely responsible for your interactions with other users
              of the Service. TopProp reserves the right, but has no obligation,
              to monitor disputes between you and other users.
            </font>
          </font>
        </font>
      </p>
      <ol start={12}>
        <li>
          <p style={{ marginBottom: "0cm" }}>
            <b>Code of Conduct</b>
          </p>
        </li>
      </ol>
      <p style={{ marginLeft: "0.64cm", marginBottom: "0cm" }}>
        <br />
      </p>
      <p style={{ marginBottom: "0cm" }}>
        You agree not to do or attempt to do any of the following:
      </p>
      <ul>
        <li>
          <p style={{ marginBottom: "0cm" }}>
            Create or operate multiple user accounts, log into a user account
            from multiple devices simultaneously, log into a user account from
            more than five different devices, or log into multiple user accounts
            from the same device, without TopProp’s express written consent;
          </p>
        </li>
        <li>
          <p style={{ marginBottom: "0cm" }}>
            Participate in a contest on behalf of multiple users (including
            through multi-accounting) or otherwise collaborate or collude with
            others to participate in any contest or to achieve competitive
            advantage;
          </p>
        </li>
        <li>
          <p style={{ marginBottom: "0cm" }}>
            Use cheats, exploits, hacks, bots, mods or third-party software
            designed to gain an advantage, perceived or actual, over other users
            of the Services, or modify or interfere with the Services;
          </p>
        </li>
        <li>
          <p style={{ marginBottom: "0cm" }}>
            Violate contest rules, these Terms, any Other Governing Agreement,
            or any applicable law or regulation;
          </p>
        </li>
        <li>
          <p style={{ marginBottom: "0cm" }}>
            Engage in any type of financial fraud, including unauthorized use of
            credit instruments to enter a contest or claim other prizes or
            deliberately transfer money between multiple user accounts, or any
            type of bonus abuse, abuse of the refer-a-friend program, or abuse
            of any other offers or promotions;
          </p>
        </li>
        <li>
          <p style={{ marginBottom: "0cm" }}>
            Tamper with the administration of a contest or tamper with the
            computer programs or any security measure associated with a contest;
          </p>
        </li>
        <li>
          <p style={{ marginBottom: "0cm" }}>
            Post or transmit, or cause to be posted or transmitted, any User
            Content that are infringing, libelous, defamatory, abusive,
            offensive, obscene, pornographic or otherwise violates any law or
            right of any third party;
          </p>
        </li>
        <li>
          <p style={{ marginBottom: "0cm" }}>
            Use, replicate, display, mirror or frame (whether directly or
            through the use of meta tags) the Services or any individual element
            within the Services, TopProp’s name, any TopProp trademark, logo or
            other proprietary information or intellectual property, or the
            layout and design of any page or form contained on a page in any
            manner or format, including the development of any third-party
            applications that interact with our Services, without TopProp’s
            express written consent;
          </p>
        </li>
        <li>
          <p style={{ marginBottom: "0cm" }}>
            Access, tamper with, or use non-public areas of the Services,
            TopProp’s computer systems, or the technical delivery systems of
            TopProp’s providers;
          </p>
        </li>
        <li>
          <p style={{ marginBottom: "0cm" }}>
            Probe, scan or test the vulnerability of any TopProp system or
            network or breach, compromise, or circumvent any security or
            authentication measures;
          </p>
        </li>
        <li>
          <p style={{ marginBottom: "0cm" }}>
            Use methods to disguise your location or otherwise circumvent
            TopProp’s tools to control access to the Service;
          </p>
        </li>
        <li>
          <p style={{ marginBottom: "0cm" }}>
            Access or search the Services or download content from the Services
            using any engine, software, tool, agent, device or mechanism
            (including spiders, robots, crawlers, data mining tools or the like)
            other than the software and/or search agents provided by TopProp;
          </p>
        </li>
        <li>
          <p style={{ marginBottom: "0cm" }}>
            Interfere with, disrupt, interrupt, negatively affect or inhibit
            other users from enjoying the Services, or take actions that could
            damage, interrupt, disable, overburden or impair the functioning of
            the Services in any manner;
          </p>
        </li>
        <li>
          <p style={{ marginBottom: "0cm" }}>
            Use the Services, or any portion thereof, including the in-service
            chat features and message boards, for any commercial purpose or for
            the benefit of any third party without our express written approval
            to do so or in any manner not permitted by these Terms;
          </p>
        </li>
        <li>
          <p style={{ marginBottom: "0cm" }}>
            Decipher, decompile, disassemble or reverse engineer or otherwise
            derive any source code or underlying ideas or algorithms of any part
            of the Services (including without limitation any application or
            software used to provide the Services);
          </p>
        </li>
        <li>
          <p style={{ marginBottom: "0cm" }}>
            Interfere with the access of any user, host or network;
          </p>
        </li>
        <li>
          <p style={{ marginBottom: "0cm" }}>
            Collect, harvest, publish, or store any personally identifiable
            information from the Services from other users of the Services
            without their express permission or use automated means (including,
            but not limited to, harvest bots, robots, parser, spiders or screen
            scrapers) to obtain, collect or access any information on the
            Services or of any user for any purpose;
          </p>
        </li>
        <li>
          <p style={{ marginBottom: "0cm" }}>
            Create a false identity or a user account for anyone other than
            yourself, impersonate or misrepresent your affiliation with any
            person or entity or falsify personal information or location
            information required to enter a contest or claim prizes;
          </p>
        </li>
        <li>
          <p style={{ marginBottom: "0cm" }}>
            Transfer, sell, or trade your user account, credentials, or access
            to the Services to anyone without written permission from TopProp;
          </p>
        </li>
        <li>
          <p style={{ marginBottom: "0cm" }}>
            Solicit or obtain other users’ user account credentials or
            confidential information or spam other users;
          </p>
        </li>
        <li>
          <p style={{ marginBottom: "0cm" }}>
            Abuse the Services in any way; or
          </p>
        </li>
        <li>
          <p style={{ marginBottom: "0cm" }}>
            Encourage or enable any other individual or User to do any of the
            foregoing.
          </p>
        </li>
      </ul>
      <p style={{ marginBottom: "0cm" }}>
        <br />
      </p>
      <p style={{ marginBottom: "0cm" }}>
        A violation of this Section 12 may result in the removal of your content
        from the Services and/or the suspension or termination of your account
        or right and ability to use the Services. You acknowledge and agree that
        TopProp may in its sole discretion remove any User Content, block access
        to the Services or the content therein, and suspend or terminate any
        account at any time for any reason or no reason. To report abuse of the
        Terms, please contact us at support@toppropfantasy.com.
      </p>
      <p style={{ marginBottom: "0cm" }}>
        <br />
      </p>
      <ol start={13}>
        <li>
          <p style={{ marginBottom: "0cm" }}>
            <b>Limited License Grant</b>
          </p>
        </li>
      </ol>
      <p style={{ marginLeft: "0.64cm", marginBottom: "0cm" }}>
        <br />
      </p>
      <p style={{ marginBottom: "0cm" }}>
        This Section 13 sets forth your limited license to use the Services (“
        <b>License</b>”). The Services are licensed to you, not sold.
      </p>
      <p style={{ marginBottom: "0cm" }}>
        <br />
      </p>
      <p style={{ marginBottom: "0cm" }}>
        Through your access and/or use of the Services, you are acquiring and
        TopProp grants you a personal, limited, non-exclusive, non-transferable
        license use and access the Services for your non-commercial use as set
        forth in this License. Your acquired rights are subject to your
        compliance with this License. Any commercial use is prohibited. You are
        expressly prohibited from sub-licensing, renting, leasing, transferring
        or otherwise distributing the Services or any rights to use the Services
        (including, without limitation, your Registration Data and/or any other
        similar information). The term of your License shall commence on the
        date that you access or use the Services, and shall end on the earlier
        of the date that you cease use of the Services, or TopProp’s termination
        of this License.
      </p>
      <p style={{ marginBottom: "0cm" }}>
        <br />
      </p>
      <ol start={14}>
        <li>
          <p style={{ marginBottom: "0cm" }}>
            <b>Further Restrictions</b>
          </p>
        </li>
      </ol>
      <p style={{ marginBottom: "0cm" }}>
        <br />
      </p>
      <p style={{ marginBottom: "0cm" }}>
        Your right to use the Services is limited to the license grant above,
        and you may not otherwise copy, display, seek to disable, distribute,
        perform, publish, modify, transfer, create works from, or use the
        Services or any component of it, except as expressly authorized by
        TopProp. Unless expressly authorized in writing by TopProp, you are
        prohibited from making the Services (and/or a copy of the Services)
        available on or over a network where it could be used and/or downloaded
        by multiple users. You may not remove or alter any of TopProp’s
        trademarks and/or logos, any legal notices included in the Services
        and/or any related assets. Your right to use the Services is also
        predicated on your compliance with any applicable terms or agreements
        you have with third parties when using the Services.
      </p>
      <p style={{ marginBottom: "0cm" }}>
        <br />
      </p>
      <ol start={15}>
        <li>
          <p style={{ marginBottom: "0cm" }}>
            <b>Reservation of Rights</b>
          </p>
        </li>
      </ol>
      <p style={{ marginLeft: "0.64cm", marginBottom: "0cm" }}>
        <br />
      </p>
      <p style={{ marginBottom: "0cm" }}>
        You have obtained a license to the Services and your rights are subject
        to this License. Except as expressly licensed to you herein, TopProp and
        its licensors reserve all right, title and interest in the Services, and
        all associated copyrights, trademarks, and other intellectual property
        rights therein. This License is limited to the intellectual property
        rights of TopProp and its licensors in the Services and does not include
        any rights to other patents or intellectual property. Except to the
        extent permitted under applicable law, you may not decompile,
        disassemble, or reverse engineer the Services, or any component thereof,
        by any means whatsoever. You may not remove, alter, or obscure any
        product or brand identification, copyright, or other intellectual
        property notices in the Services. All rights not expressly granted
        herein are reserved by TopProp.
      </p>
      <p style={{ marginBottom: "0cm" }}>
        <br />
      </p>
      <p style={{ marginBottom: "0cm" }}>
        This License is effective until terminated. Your rights under this
        License will terminate immediately and automatically without any notice
        from TopProp if you fail to comply with any of provision of these Terms
        or any Other Governing Agreement. Promptly upon termination, you must
        cease all use of the Services and destroy all copies of the Services in
        your possession or control. Termination will not limit any of TopProp’s
        other rights or remedies under these Terms, at law or in equity.
      </p>
      <p style={{ marginBottom: "0cm" }}>
        <br />
      </p>
      <ol start={16}>
        <li>
          <p style={{ marginBottom: "0cm" }}>
            <b>Location and Push Notifications and Other Technologies</b>
          </p>
        </li>
      </ol>
      <p style={{ marginLeft: "0.64cm", marginBottom: "0cm" }}>
        <br />
      </p>
      <p style={{ marginBottom: "0cm" }}>
        To determine your eligibility to use the Services, TopProp may determine
        your location using one or more reference points, such as GPS, Beacons
        and/or software within your device or any other equipment. If you have
        set your device(s) to disable GPS, Bluetooth or other location
        determining software or do not authorize the Services to access your
        location data, the Services may not be able to determine your location
        and you will not be able to access the Services. For more information
        about how the Services collects, uses and retains your information,
        please read the TopProp
        <u>Privacy Policy</u>.
      </p>
      <p style={{ marginBottom: "0cm" }}>
        <br />
      </p>
      <p style={{ marginBottom: "0cm" }}>
        The Services may provide links to third party applications, services or
        websites from our Site or Services. You understand that when you click
        on these links any data which you provide afterwards is subject to that
        third party’s privacy policy and not to our Privacy Policy. We can take
        no responsibility for the content, safety, privacy or security of any
        third party application, service or website.
      </p>
      <p style={{ marginBottom: "0cm" }}>
        <br />
      </p>
      <ol start={17}>
        <li>
          <p style={{ marginBottom: "0cm" }}>
            <b>Updates to the Services</b>
          </p>
        </li>
      </ol>
      <p style={{ marginLeft: "0.64cm", marginBottom: "0cm" }}>
        <br />
      </p>
      <p style={{ marginBottom: "0cm" }}>
        You agree that the Services may automatically implement updates,
        upgrades and additional features that TopProp deems reasonable,
        beneficial to you and/or reasonably necessary. You acknowledge and agree
        that any obligation TopProp may have to support the previous version(s)
        may be ended upon the availability of the update, upgrade and/or
        implementation of additional features. This License shall apply to any
        updates, upgrades and/or additional features that are not distributed
        with a separate license or other agreement.
      </p>
      <p style={{ marginBottom: "0cm" }}>
        <br />
      </p>
      <ol start={18}>
        <li>
          <p style={{ marginBottom: "0cm" }}>
            <b>Indemnification and Release</b>
          </p>
        </li>
      </ol>
      <p style={{ marginLeft: "0.64cm", marginBottom: "0cm" }}>
        <br />
      </p>
      <p style={{ marginBottom: "0cm" }}>
        By using the Services, you agree to defend, indemnify, release and to
        hold harmless TopProp, as well as its officers, directors, employees,
        shareholders and representatives from and against any claims,
        liabilities, damages, losses, actions or expenses, including without
        limitation reasonable legal and accounting fees, of any kind, arising
        out of or in any way connected with (i) you access to our use of
        Services or your violation of these Terms; (ii) your User Content; (iii)
        your interaction with any other users; (iv) your receipt, ownership,
        use, or misuse of any prize, (v) any of your acts or omissions that
        implicate publicity rights, defamation, or invasion of privacy, or (iv)
        any claims made by any third party in connection with your use of the
        Services, the receipt, ownership, use or misuse of any products or
        services made available on or through the Services.
      </p>
      <p style={{ marginBottom: "0cm" }}>
        <br />
      </p>
      <p style={{ marginBottom: "0cm" }}>
        We reserve the right, at our own expense, to assume the exclusive
        defense and control of such disputes, and in any event you will
        cooperate with us in asserting any available defenses.
      </p>
      <p style={{ marginBottom: "0cm" }}>
        <br />
      </p>
      <ol start={19}>
        <li>
          <p style={{ marginBottom: "0cm" }}>
            <b>Warranty Disclaimers</b>
          </p>
        </li>
      </ol>
      <p style={{ marginBottom: "0cm" }}>
        <br />
      </p>
      <p style={{ marginBottom: "0cm" }}>
        <span style={{ textTransform: "uppercase" }}>
          You expressly understand and agree that your use of the Services is at
          your sole risk. The Services (including the related websites and
          content) are provided on an "AS IS" and "as available" basis, without
          warranties of any kind, either express or implied, including, without
          limitation, implied warranties of merchantability, fitness for a
          particular purpose or non-infringement. You acknowledge that TopProp
          has no control over, and no duty to take any action regarding: which
          users gain access to or use the Services; what effects the content may
          have on you; how you may interpret or use the content; or what actions
          you may take as a result of having been exposed to the content. You
          release TopProp from all liability for you having acquired or not
          acquired content through the Services. The Services may contain, or
          direct you to other websites containing information that some people
          may find offensive or inappropriate. TopProp makes no representations
          concerning any content contained in or accessed through the Services,
          and TopProp will not be responsible or liable for the accuracy,
          copyright compliance, legality or decency of material contained in or
          accessed through the Services.{" "}
        </span>
      </p>
      <p style={{ marginBottom: "0cm" }}>
        <br />
      </p>
      <ol start={20}>
        <li>
          <p style={{ marginBottom: "0cm" }}>
            <b>Limitations on Liability</b>
          </p>
        </li>
      </ol>
      <p style={{ marginLeft: "0.64cm", marginBottom: "0cm" }}>
        <br />
      </p>
      <p style={{ marginBottom: "0cm" }}>
        YOU ACKNOWLEDGE AND AGREE THAT YOU ASSUME FULL RESPONSIBILITY FOR YOUR
        USE OF THE SERVICES. YOU ACKNOWLEDGE AND AGREE THAT ANY INFORMATION YOU
        SEND OR RECEIVE DURING YOUR USE OF THE SERVICES MAY NOT BE SECURE AND
        MAY BE INTERCEPTED OR LATER ACQUIRED BY UNAUTHORIZED PARTIES. YOU
        ACKNOWLEDGE AND AGREE THAT YOUR USE OF THE SERVICES IS AT YOUR OWN RISK.
        RECOGNIZING SUCH, YOU UNDERSTAND AND AGREE THAT, TO THE FULLEST EXTENT
        PERMITTED BY APPLICABLE LAW, NEITHER TOPPROP NOR ITS SUPPLIERS OR
        LICENSORS WILL BE LIABLE TO YOU FOR ANY DIRECT, INDIRECT, INCIDENTAL,
        SPECIAL, CONSEQUENTIAL, PUNITIVE, EXEMPLARY OR OTHER DAMAGES OF ANY
        KIND, INCLUDING WITHOUT LIMITATION DAMAGES FOR LOSS OF PROFITS,
        GOODWILL, USE, DATA OR OTHER TANGIBLE OR INTANGIBLE LOSSES OR ANY OTHER
        DAMAGES BASED ON CONTRACT, TORT, STRICT LIABILITY OR ANY OTHER THEORY
        (EVEN IF TOPPROP HAD BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES),
        RESULTING FROM THE SERVICES; THE USE OR THE INABILITY TO USE THE SITE OR
        SERVICES; UNAUTHORIZED ACCESS TO OR ALTERATION OF YOUR TRANSMISSIONS OR
        DATA; STATEMENTS OR CONDUCT OF ANY THIRD PARTY ON THE SERVICES; ANY
        ACTIONS WE TAKE OR FAIL TO TAKE AS A RESULT OF COMMUNICATIONS YOU SEND
        TO US; ANY INCORRECT, ILLEGIBLE, MISDIRECTED, STOLEN, INVALID OR
        INACCURATE INFORMATION; HUMAN ERRORS; TECHNICAL MALFUNCTIONS; FAILURES,
        INCLUDING PUBLIC UTILITY OR TELEPHONE OUTAGES; OMISSIONS, INTERRUPTIONS,
        LATENCY, DELETIONS OR DEFECTS OF ANY DEVICE OR NETWORK, PROVIDERS, OR
        SOFTWARE (INCLUDING, BUT NOT LIMITED TO, THOSE THAT DO NOT PERMIT AN
        INDIVIDUAL TO JOIN OR PARTICIPATE IN A CONTEST); ANY INJURY OR DAMAGE TO
        COMPUTER EQUIPMENT; INABILITY TO FULLY ACCESS THE SERVICES OR ANY OTHER
        WEBSITE; THEFT, TAMPERING, DESTRUCTION, OR UNAUTHORIZED ACCESS TO, OR
        ALTERATION OF, TRANSACTIONS, IMAGES OR OTHER CONTENT OF ANY KIND; DATA
        THAT IS PROCESSED LATE OR INCORRECTLY OR IS INCOMPLETE OR LOST;
        TYPOGRAPHICAL, PRINTING OR OTHER ERRORS, OR ANY COMBINATION THEREOF; OR
        ANY OTHER MATTER RELATING TO THE SERVICES.
      </p>
      <p style={{ marginBottom: "0cm" }}>
        <br />
      </p>
      <p style={{ marginBottom: "0cm" }}>
        NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, TOPPROP’S
        LIABILITY TO YOU FOR ANY CAUSE WHATSOEVER AND REGARDLESS OF THE FORM OF
        THE ACTION, WILL AT ALL TIMES BE LIMITED TO THE AMOUNT PAID, IF ANY, BY
        YOU TO TOPPROP FOR GENERAL USE OF THE SERVICES DURING THE TERM OF YOUR
        REGISTRATION FOR THE SERVICES, NOT INCLUDING ANY PAYMENTS FOR CONTESTS
        RUN PRIOR TO THE CIRCUMSTANCES GIVING RISE TO THE CLAIM.
      </p>
      <p style={{ marginBottom: "0cm" }}>
        <br />
      </p>
      <p style={{ marginBottom: "0cm" }}>
        BECAUSE SOME STATES/JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR
        LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, THE
        ABOVE LIMITATION MAY NOT APPLY TO YOU. IN SUCH STATES, LIABILITY OF
        TOPPROP SHALL BE LIMITED TO THE FULLEST EXTENT PERMITTED BY LAW.
        ADDITIONAL DISCLAIMERS MAY APPEAR WITHIN THE SERVICES AND ARE
        INCORPORATED HEREIN BY REFERENCE. TO THE EXTENT ANY SUCH DISCLAIMERS
        PLACE GREATER RESTRICTIONS ON YOUR USE OF THE SERVICES OR THE CONTENT
        CONTAINED THEREIN, SUCH GREATER RESTRICTIONS SHALL APPLY. THIS
        LIMITATION OF LIABILITY SHALL APPLY TO THIRD PARTY CLAIMS AS WELL AS
        CLAIMS BETWEEN THE PARTIES.
      </p>
      <p style={{ marginBottom: "0cm" }}>
        <br />
      </p>
      <p style={{ marginBottom: "0cm" }}>
        IF APPLICABLE LAW DOES NOT ALLOW ALL OR ANY PART OF THE ABOVE LIMITATION
        OF LIABILITY TO APPLY TO YOU, THE LIMITATIONS WILL APPLY TO YOU ONLY TO
        THE EXTENT PERMITTED BY APPLICABLE LAW. IF YOU ARE A NEW JERSEY
        RESIDENT, OR A RESIDENT OF ANOTHER STATE THAT PERMITS THE EXCLUSION OF
        THESE WARRANTIES AND LIABILITIES, THEN THE ABOVE LIMITATIONS
        SPECIFICALLY DO APPLY TO YOU.
      </p>
      <p style={{ marginBottom: "0cm" }}>
        <br />
      </p>
      <p style={{ marginBottom: "0cm" }}>
        IF YOU ARE A CALIFORNIA RESIDENT, YOU WAIVE CALIFORNIA CIVIL CODE
        SECTION 1542, WHICH PROVIDES:
      </p>
      <p style={{ marginLeft: "1.27cm", marginBottom: "0cm" }}>
        <br />
      </p>
      <p style={{ marginLeft: "1.27cm", marginBottom: "0cm" }}>
        A GENERAL RELEASE DOES NOT EXTEND TO CLAIMS THAT THE CREDITOR OR
        RELEASING PARTY DOES NOT KNOW OR SUSPECT TO EXIST IN HIS OR HER FAVOR AT
        THE TIME OF EXECUTING THE RELEASE AND THAT, IF KNOWN BY HIM OR HER,
        WOULD HAVE MATERIALLY AFFECTED HIS OR HER SETTLEMENT WITH THE DEBTOR OR
        RELEASED PARTY.
      </p>
      <p style={{ marginBottom: "0cm" }}>
        <br />
      </p>
      <p style={{ marginBottom: "0cm" }}>
        IF YOU ARE NOT A CALIFORNIA RESIDENT, YOU WAIVE YOUR RIGHTS UNDER ANY
        STATUTE OR COMMON LAW PRINCIPLE SIMILAR TO CALIFORNIA CIVIL CODE SECTION
        1542 THAT GOVERNS YOUR RIGHTS IN THE JURISDICTION OF YOUR RESIDENCE.
      </p>
      <p style={{ marginBottom: "0cm" }}>
        <br />
      </p>
      <ol start={21}>
        <li>
          <p style={{ marginBottom: "0cm" }}>
            <b>Our Proprietary Rights</b>
          </p>
        </li>
      </ol>
      <p style={{ marginLeft: "0.64cm", marginBottom: "0cm" }}>
        <br />
      </p>
      <p style={{ marginBottom: "0cm" }}>
        All title, ownership and intellectual property rights in and to the
        Services are owned by TopProp or its respective licensors. You
        acknowledge and agree that the Services contains proprietary and
        confidential information that is protected by applicable intellectual
        property and other laws.{" "}
        <font color="#000000">
          These Terms do not grant you or any other party any right, title, or
          interest in the Services or any content in the Services.
        </font>
        <font color="#000000">
          <font face="Times, serif"></font>
        </font>
        Except as expressly authorized by TopProp, you agree not to modify,
        rent, lease, loan, sell, distribute or create derivative works based on
        the Services, in whole or in part.
      </p>
      <p style={{ marginBottom: "0cm" }}>
        <br />
      </p>
      <ol start={22}>
        <li>
          <p style={{ marginBottom: "0cm" }}>
            <b>Termination</b>
          </p>
        </li>
      </ol>
      <p style={{ marginBottom: "0cm" }}>
        <br />
      </p>
      <p style={{ marginBottom: "0cm" }}>
        TopProp reserves the right at any time to modify, suspend, discontinue,
        or terminate, temporarily or permanently, the Services or associated
        services (or any part thereof), with or without notice. You agree that
        TopProp shall not be liable to you or any third party for any
        modification, suspension or discontinuance of the Services or associated
        services.
      </p>
      <p style={{ marginBottom: "0cm" }}>
        <br />
      </p>
      <p style={{ marginBottom: "0cm" }}>
        We do not guarantee that any of our Services will be available at all
        times or at any given time or that we will continue to offer all or any
        of our Services for any particular length of time. We may change and
        update our Services without notice to you. We make no warranty or
        representation regarding the availability of our Services and reserve
        the right to modify or discontinue the Services in our sole discretion
        without notice, including for example, ceasing a contest or other
        Services for technical reasons (such as technical difficulties
        experienced by us or on the internet) or to allow us to improve user
        experience. NOTWITHSTANDING ANYTHING TO THE CONTRARY, YOU ACKNOWLEDGE
        AND AGREE THAT ANY OR ALL OF OUR SERVICES MAY BE TERMINATED IN WHOLE OR
        IN PART AT OUR SOLE DISCRETION WITHOUT NOTICE TO YOU. YOU ASSUME ANY AND
        ALL RISK OF LOSS ASSOCIATED WITH THE TERMINATION OF OUR SERVICES.
      </p>
      <p style={{ marginBottom: "0cm" }}>
        <br />
      </p>
      <p style={{ marginBottom: "0cm" }}>
        TopProp may terminate or suspend all of part of your account
        immediately, without prior notice or liability, if you breach any of the
        terms or conditions of the Terms or TopProp
        <u>Privacy Policy</u>. Upon termination of your account, your right to
        use the Services will immediately cease. You will, however, have the
        opportunity to withdraw all funds in your account at the time of
        termination, unless TopProp, in its sole discretion, determines that
        those funds are disputed or otherwise were not earned in compliance with
        these Terms or the law.
      </p>
      <p style={{ marginBottom: "0cm" }}>
        <br />
      </p>
      <p style={{ marginBottom: "0cm" }}>
        If you wish to terminate your account, you may simply discontinue using
        the Services or contact us at
        <font color="#000000">
          <span lang="en-IN">support@toppropfantasy.com</span>
        </font>
        via an email expressly stating that you wish to terminate your account.
      </p>
      <p style={{ marginBottom: "0cm" }}>
        <br />
      </p>
      <p style={{ marginBottom: "0cm" }}>
        All provisions of these Terms, which by their nature should survive
        termination, shall survive termination, including, without limitation,
        Conditions of Participation (except for Registration and username,
        password, and Security), Conduct, Limited License Grant, Warranty
        Disclaimers, Indemnity, Limitations of Liability, Our Proprietary
        Rights, Binding Arbitration and Class Action Waiver.
      </p>
      <p style={{ marginBottom: "0cm" }}>
        <br />
      </p>
      <ol start={23}>
        <li>
          <p style={{ marginBottom: "0cm" }}>
            <b>Binding Arbitration and Class Action Waiver</b>
          </p>
        </li>
      </ol>
      <p style={{ marginBottom: "0cm" }}>
        <br />
      </p>
      <p style={{ marginBottom: "0cm" }}>
        PLEASE READ THIS SECTION CAREFULLY – IT MAY SIGNIFICANTLY AFFECT YOUR
        LEGAL RIGHTS, INCLUDING YOUR RIGHT TO FILE A LAWSUIT IN COURT.
      </p>
      <p style={{ marginBottom: "0cm" }}>
        <br />
      </p>
      <p style={{ marginBottom: "0cm" }}>
        <b>23.1 Initial Dispute Resolution</b>
      </p>
      <p style={{ marginBottom: "0cm" }}>
        <br />
      </p>
      <p style={{ marginBottom: "0cm" }}>
        Our Customer Support team is available via email at{" "}
        <font color="#000000">
          <span lang="en-IN">support@toppropfantasy.com</span>
        </font>
        to address any concerns you may have regarding the Service. Our Customer
        Service team can resolve most concerns quickly to our players’
        satisfaction. The parties shall use their best efforts through this
        customer support process to settle any dispute, claim, question, or
        disagreement and engage in good faith negotiations which shall be a
        condition to either party initiating a lawsuit or arbitration. Failure
        to engage in this process could result in the award of fees against you
        in arbitration.
      </p>
      <p style={{ marginBottom: "0cm" }}>
        <br />
      </p>
      <p style={{ marginBottom: "0cm" }}>
        <b>23.2 Binding Arbitration</b>
      </p>
      <p style={{ marginBottom: "0cm" }}>
        <br />
      </p>
      <p style={{ marginBottom: "0cm" }}>
        If the parties do not reach an agreed upon solution within a period of
        30 days from the time informal dispute resolution begins under the
        Initial Dispute Resolution provision, then either party may initiate
        binding arbitration as the sole means to resolve claims, subject to the
        terms set forth below. Specifically, all claims arising out of or
        relating to these Terms (including their formation, performance and
        breach), the parties’ relationship with each other and/or your use of
        the Service shall be finally settled by binding arbitration administered
        by JAMS in accordance with the provisions of its Streamlined Arbitration
        and Procedures, excluding any rules or procedures governing or
        permitting class or representative actions.
      </p>
      <p style={{ marginBottom: "0cm" }}>
        <br />
      </p>
      <p style={{ marginBottom: "0cm" }}>
        Except as set forth in Section 23.5, the arbitrator, and not any
        federal, state or local court or agency, shall have exclusive authority
        to resolve all disputes arising out of or relating to the
        interpretation, applicability, enforceability or formation of these
        Terms, including, but not limited to any claim that all or any part of
        these Terms are void or voidable, whether a claim is subject to
        arbitration, and any dispute regarding the payment of JAMS
        administrative or arbitrator fees (including the timing of such payments
        and remedies for nonpayment). The arbitrator shall be empowered to grant
        whatever relief would be available in a court under law or in equity.
        The parties agree that the arbitrator may allow the filing of
        dispositive motions if they are likely to efficiently resolve or narrow
        issues in dispute. The arbitrator’s award shall be written, and binding
        on the parties and may be entered as a judgment in any court of
        competent jurisdiction. No arbitration award or decision will have any
        preclusive effect as to issues or claims in any dispute with anyone who
        is not a named party to the arbitration.
      </p>
      <p style={{ marginBottom: "0cm" }}>
        <br />
      </p>
      <p style={{ marginBottom: "0cm" }}>
        The Streamlined Arbitration Rules governing the arbitration may be
        accessed at www.jamsadr.com or by calling JAMS at (800) 352-5267. If you
        commence arbitration in accordance with these Terms, you will be
        required to pay $250 to initiate the arbitration. To the extent the
        filing fee for the arbitration exceeds the cost of filing a lawsuit, the
        arbitrator may require TopProp to pay the additional cost. You are
        responsible for your own attorneys’ fees unless the arbitration rules
        and/or applicable law provide otherwise. If the arbitrator finds the
        arbitration to be non-frivolous, TopProp will pay all of the actual
        filing and arbitrator fees for the arbitration, provided your claim does
        not exceed $75,000. For claims above $75,000, fees and costs will be
        determined in accordance with applicable JAMS rules. The arbitration
        rules permit you to recover attorney’s fees in certain cases.
      </p>
      <p style={{ marginBottom: "0cm" }}>
        <br />
      </p>
      <p style={{ marginBottom: "0cm" }}>
        Any arbitration demand or counterclaim asserted by either party must
        contain sufficient information to provide fair notice to the other party
        of the asserting party’s identity, the claims being asserted, and the
        factual allegations on which they are based. The arbitrator and/or JAMS
        may require amendment of any demand or counterclaim that does not
        satisfy these requirements. The arbitrator has the right to impose
        sanctions in accordance with JAMS Rule 24 for any claims the arbitrator
        determines to be frivolous or improper (under the standard set forth in
        Federal Rule of Civil Procedure 11).
      </p>
      <p style={{ marginBottom: "0cm" }}>
        <br />
      </p>
      <p style={{ marginBottom: "0cm" }}>
        The parties agree that JAMS has discretion to modify the amount or
        timing of any administrative or arbitration fees due under JAMS’s Rules
        where it deems appropriate, provided that such modification does not
        increase the costs to you, and you waive any objection to such fee
        modification. The parties also agree that a good-faith challenge by
        either party to the fees imposed by JAMS does not constitute a default,
        waiver, or breach of this Section 23 while such challenge remains
        pending before JAMS, the arbitrator, and/or a court of competent
        jurisdiction.
      </p>
      <p style={{ marginBottom: "0cm" }}>
        <br />
      </p>
      <p style={{ marginBottom: "0cm" }}>
        The parties understand that, absent this mandatory provision, they would
        have the right to sue in court and have a jury trial. They further
        understand that, in some instances, the costs of arbitration could
        exceed the costs of litigation and the right to discovery may be more
        limited in arbitration than in court.
      </p>
      <p style={{ marginBottom: "0cm" }}>
        <br />
      </p>
      <p style={{ marginBottom: "0cm" }}>
        <b>23.3 Location</b>
      </p>
      <p style={{ marginBottom: "0cm" }}>
        <br />
      </p>
      <p style={{ marginBottom: "0cm" }}>
        If you are a resident of the United States, arbitration will take place
        at any reasonable location within the United States convenient for you.
      </p>
      <p style={{ marginBottom: "0cm" }}>
        <br />
      </p>
      <p style={{ marginBottom: "0cm" }}>
        <b>23.4 Class Action Waiver</b>
      </p>
      <p style={{ marginBottom: "0cm" }}>
        <br />
      </p>
      <p style={{ marginBottom: "0cm" }}>
        The parties further agree that any arbitration shall be conducted in
        their individual capacities only and not as a class action or other
        representative action, and the parties expressly waive their right to
        file a class action or seek relief on a class basis. YOU AND TOPPROP
        AGREE THAT EACH MAY BRING CLAIMS AGAINST THE OTHER ONLY IN YOUR OR ITS
        INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY
        PURPORTED CLASS OR REPRESENTATIVE PROCEEDING. If there is a final
        judicial determination that applicable law precludes enforcement of this
        Paragraph’s limitations as to a particular remedy, then that remedy (and
        only that remedy) must be severed from the arbitration and may be sought
        in court. The parties agree, however, that any adjudication of remedies
        not subject to arbitration shall be stayed pending the outcome of any
        arbitrable claims and remedies.
      </p>
      <p style={{ marginBottom: "0cm" }}>
        <br />
      </p>
      <p style={{ marginBottom: "0cm" }}>
        <b>
          23.5 Exception - Litigation of Intellectual Property and Small Claims
          Court Claims
        </b>
      </p>
      <p style={{ marginBottom: "0cm" }}>
        <br />
      </p>
      <p style={{ marginBottom: "0cm" }}>
        Notwithstanding the parties’ decision to resolve all disputes through
        arbitration, either party may bring an action in state or federal court
        to protect its intellectual property rights (“
        <b>intellectual property rights</b>” means patents, copyrights, moral
        rights, trademarks, and trade secrets, but not privacy or publicity
        rights). Either party may also elect to have disputes or claims resolved
        in a small claims court that are within the scope of that court’s
        jurisdiction. Either party may also seek a declaratory judgment or other
        equitable relief in a court of competent jurisdiction regarding whether
        a party’s claims are time-barred or may be brought in small claims court
        in your state and county of residence. Seeking such relief shall not
        waive a party’s right to arbitration under this agreement.
      </p>
      <p style={{ marginBottom: "0cm" }}>
        <br />
      </p>
      <ol>
        <ol start={6}>
          <li>
            <p style={{ marginBottom: "0cm" }}>
              <b>30-Day Right to Opt Out</b>
            </p>
          </li>
        </ol>
      </ol>
      <p style={{ marginBottom: "0cm" }}>
        <br />
      </p>
      <p style={{ marginBottom: "0cm" }}>
        You have the right to opt-out and not be bound by the arbitration and
        class action waiver provisions set forth above by sending written notice
        of your decision to opt-out to the following address: TopProp
        Corporation, 140 Arsenal Street, #2327, Watertown, MA 02472. The notice
        must be sent within 30 days of the later of (i) your first use of the
        Services, or (ii) the Last Revised date of these Terms where TopProp
        made a material change to this Arbitration and Class Action Waiver
        section; otherwise you shall be bound to arbitrate disputes in
        accordance with the terms of those paragraphs. If you opt-out of these
        arbitration provisions, TopProp also will not be bound by them.
      </p>
      <p style={{ marginBottom: "0cm" }}>
        <br />
      </p>
      <ol>
        <ol start={6}>
          <li>
            <p style={{ marginBottom: "0cm" }}>
              <b>Changes to this Section</b>
            </p>
          </li>
        </ol>
      </ol>
      <p style={{ marginLeft: "0.85cm", marginBottom: "0cm" }}>
        <br />
      </p>
      <p style={{ marginBottom: "0cm" }}>
        TopProp will provide 30 days’ notice of any changes to this section.
        Changes will become effective on the 30th day. If you continue to use
        the site after the 30th day, you agree that any unfiled claims of which
        TopProp does not have actual notice are subject to the revised clause.
      </p>
      <p style={{ marginBottom: "0cm" }}>
        <br />
      </p>
      <p style={{ marginBottom: "0cm" }}>
        For any dispute not subject to arbitration you and TopProp agree to
        submit to the personal and exclusive jurisdiction of and venue in the
        federal and state courts located in New York, New York. You further
        agree to accept service of process by mail, and hereby waive any and all
        jurisdictional and venue defenses otherwise available.
      </p>
      <p style={{ marginBottom: "0cm" }}>
        <br />
      </p>
      <p style={{ marginBottom: "0cm" }}>
        The Terms and the relationship between you and TopProp shall be governed
        by the laws of the State of New York without regard to conflict of law
        provisions.
      </p>
      <p style={{ marginBottom: "0cm" }}>
        <br />
      </p>
      <p style={{ marginBottom: "0cm" }}>
        <br />
      </p>
      <ol start={24}>
        <li>
          <p style={{ marginBottom: "0cm" }}>
            <b>
              Notice and Procedure for Making Claims of Copyright Infringement
            </b>
          </p>
        </li>
      </ol>
      <p style={{ marginLeft: "0.64cm", marginBottom: "0cm" }}>
        <br />
      </p>
      <p style={{ marginBottom: "0cm" }}>
        TopProp may, in appropriate circumstances and at its discretion, disable
        and/or terminate the accounts of users who infringe the intellectual
        property of others. If you believe that your copyright or the copyright
        of a person on whose behalf you are authorized to act has been
        infringed, please provide TopProp’s Copyright Agent a written Notice
        containing the following information:
      </p>
      <p style={{ marginBottom: "0cm" }}>
        <br />
      </p>
      <ul>
        <li>
          <p style={{ marginBottom: "0cm" }}>
            an electronic or physical signature of the person authorized to act
            on behalf of the owner of the copyright or other intellectual
            property interest;
          </p>
        </li>
      </ul>
      <p style={{ marginBottom: "0cm" }}>
        <br />
      </p>
      <ul>
        <li>
          <p style={{ marginBottom: "0cm" }}>
            a description of the copyrighted work or other intellectual property
            that you claim has been infringed;
          </p>
        </li>
      </ul>
      <p style={{ marginBottom: "0cm" }}>
        <br />
      </p>
      <ul>
        <li>
          <p style={{ marginBottom: "0cm" }}>
            a description of where the material that you claim is infringing is
            located on the Service;
          </p>
        </li>
      </ul>
      <p style={{ marginBottom: "0cm" }}>
        <br />
      </p>
      <ul>
        <li>
          <p style={{ marginBottom: "0cm" }}>
            your address, telephone number, and email address;
          </p>
        </li>
      </ul>
      <p style={{ marginBottom: "0cm" }}>
        <br />
      </p>
      <ul>
        <li>
          <p style={{ marginBottom: "0cm" }}>
            a statement by you that you have a good faith belief that the
            disputed use is not authorized by the copyright owner, its agent, or
            the law;
          </p>
        </li>
      </ul>
      <p style={{ marginBottom: "0cm" }}>
        <br />
      </p>
      <ul>
        <li>
          <p style={{ marginBottom: "0cm" }}>
            a statement by you, made under penalty of perjury, that the above
            information in your Notice is accurate and that you are the
            copyright or intellectual property owner or authorized to act on the
            copyright or intellectual property owner’s behalf.
          </p>
        </li>
      </ul>
      <p style={{ marginBottom: "0cm" }}>
        <br />
      </p>
      <p style={{ marginBottom: "0cm" }}>
        TopProp’s Copyright Agent can be reached in the following ways:
      </p>
      <p style={{ marginBottom: "0cm" }}>
        <br />
      </p>
      <p style={{ marginBottom: "0cm" }}>
        Mail: 140 Arsenal Street, #2327, Watertown, MA 02472, Attn: Copyright
        Agent
      </p>
      <p style={{ marginBottom: "0cm" }}>
        Email: <font color="#000000">support@toppropfantasy.com</font>
      </p>
      <p style={{ marginBottom: "0cm" }}>
        <br />
      </p>
      <p style={{ marginBottom: "0cm" }}>
        To be valid, a Notice must be in writing and must follow the
        instructions above. You also may use the contact information in this
        Section to notify us of alleged violations of other intellectual
        property rights.
      </p>
      <p style={{ marginBottom: "0cm" }}>
        <br />
      </p>
      <ol start={25}>
        <li>
          <p style={{ marginBottom: "0cm" }}>
            <b>Miscellaneous </b>
          </p>
        </li>
      </ol>
      <p style={{ marginLeft: "0.64cm", marginBottom: "0cm" }}>
        <br />
      </p>
      <p style={{ marginBottom: "0cm" }}>
        In this Section 25, references to Terms shall include these Terms and
        the License.
      </p>
      <p style={{ marginBottom: "0cm" }}>
        <br />
      </p>
      <p style={{ marginBottom: "0cm" }}>
        <b>Severability and Survival. </b>If any provision of the Terms is found
        by an arbitrator or court of competent jurisdiction to be invalid,
        illegal or unenforceable under applicable law, the parties nevertheless
        agree that the arbitrator or court should endeavor to give effect to the
        parties’ intentions as reflected in the provision, and the remainder of
        the provision shall be interpreted to achieve as closely as possible the
        effect of the original term and all other provisions of the Terms remain
        in full force and effect.
      </p>
      <p style={{ marginBottom: "0cm" }}>
        <br />
      </p>
      <p style={{ marginBottom: "0cm" }}>
        <b>
          Limitation of Liability and Disclaimer of Warranties are Material
          Terms of these Terms.{" "}
        </b>
        You agree that the provisions in these Terms that limit liability and
        disclaim warranties are essential terms of these Terms.
      </p>
      <p style={{ marginBottom: "0cm" }}>
        <br />
      </p>
      <p style={{ marginBottom: "0cm" }}>
        <b>Entire Agreement.</b> The Terms constitute the entire agreement
        between you and TopProp with respect to the Services and supersedes any
        prior agreements, oral or written, between you and TopProp.
      </p>
      <p style={{ marginBottom: "0cm" }}>
        <br />
      </p>
      <p style={{ marginBottom: "0cm" }}>
        In the case of a conflict between the Terms and the terms of any other
        electronic or machine readable statement or policy, including our
        Privacy Policy, the Terms shall control, except for any agreement
        governing the use of mobile Services or other online service in which
        the user agrees to or is presented with any Other Governing Agreement.
        In such case, the Other Governing Agreement shall control.
      </p>
      <p style={{ marginBottom: "0cm" }}>
        <br />
      </p>
      <p style={{ marginBottom: "0cm" }}>
        The failure of TopProp to exercise or enforce any right or provision of
        the Terms shall not constitute a waiver of such right or provision.
      </p>
      <p style={{ marginBottom: "0cm" }}>
        <br />
      </p>
      <p style={{ marginBottom: "0cm" }}>
        <b>Statute of Limitations.</b> You agree that regardless of any statute
        or law to the contrary, any claim or cause of action arising out of or
        related to the use of the Services or the Terms must be filed within one
        (1) year after such claim or cause of action arose or will be forever
        barred.
      </p>
      <p style={{ marginBottom: "0cm" }}>
        <br />
      </p>
      <p style={{ marginBottom: "0cm" }}>
        <b>Section Titles.</b> The section titles in the Terms are for
        convenience only and have no legal or contractual effect.
      </p>
      <p style={{ marginBottom: "0cm" }}>
        <br />
      </p>
      <p style={{ marginBottom: "0cm" }}>
        <b>No Third-Party Beneficiaries</b>. You agree that, except as otherwise
        expressly provided in these Terms, there shall be no third-party
        beneficiaries to these Terms.
      </p>
      <p style={{ marginBottom: "0cm" }}>
        <br />
      </p>
      <p style={{ marginBottom: "0cm" }}>
        You agree to abide by U.S. and other applicable export control laws and
        agree not to transfer the Services to a foreign national or national
        destination, which is prohibited by such laws, without first obtaining,
        and then complying with, any requisite government authorization. You
        certify that you are not a person with whom TopProp is prohibited from
        transacting business under applicable law.
      </p>
      <p style={{ marginBottom: "0cm" }}>
        <br />
      </p>
      <h2 className="western">
        <font face="Calibri, serif">
          <font size={3} style={{ fontSize: "12pt" }}>
            <b>Notices.</b>
          </font>
        </font>
        <font face="Calibri, serif">
          <font size={3} style={{ fontSize: "12pt" }}></font>
        </font>
        <font color="#000000">
          <font face="Calibri, serif">
            <font size={3} style={{ fontSize: "12pt" }}>
              Any notices or other communications provided by TopProp under
              these Terms will be given: (i)&nbsp;via email; or (ii)&nbsp;by
              posting to the Services. For notices made by email, the date of
              receipt will be deemed the date on which such notice is
              transmitted. Except as explicitly described in the “Binding
              Arbitration and Class Action Waiver” section, you may give notice
              to TopProp (such notice shall be deemed given when received by
              TopProp) at any time by emailing
            </font>
          </font>
        </font>
        <font color="#000000">
          <span lang="en-IN">support@toppropfantasy.com</span>
        </font>
        <font color="#000000">
          <font face="Calibri, serif">
            <font size={3} style={{ fontSize: "12pt" }}>
              .
            </font>
          </font>
        </font>
      </h2>
      <p style={{ marginBottom: "0cm" }}>
        <b>Assignment</b>. These Terms are personal to you, and are not
        assignable, transferable or sublicensable by you except with our prior
        written consent. We may assign, transfer or delegate any of our rights
        and obligations hereunder without consent.
      </p>
      <p style={{ marginBottom: "0cm" }}>
        <br />
      </p>
      <ol start={26}>
        <li>
          <p style={{ marginBottom: "0cm" }}>
            <b>Contacting Us</b>
          </p>
        </li>
      </ol>
      <p style={{ marginBottom: "0cm" }}>
        <br />
      </p>
      <p style={{ marginBottom: "0cm" }}>
        E-mail is an important communication channel for our users. All e-mail
        sent to TopProp should be generated by the person in whose name the
        e-mail account is registered. Emails shall not mask the identity of the
        person sending them, including by using a false name or someone else’s
        name or account. If you are a customer and the topic of your email is a
        service issue, you must include your full name, username, and address in
        order to ensure that we can respond. By providing TopProp with your
        e-mail address, you authorize TopProp to use that e-mail address to
        deliver notices or disclosures related to the Services and as otherwise
        described in the TopProp Privacy Policy.{" "}
        <font color="#000000">
          <span lang="en-IN">
            You may opt out of these communications at any time by using the
            contact information below.{" "}
          </span>
        </font>
      </p>
      <p lang="en-IN" style={{ marginBottom: "0cm" }}>
        <br />
      </p>
      <p style={{ marginBottom: "0cm" }}>
        If you have any questions, concerns, requests or comments regarding
        these Terms, or if you wish to opt out of communications from TopProp,
        we invite you to contact us by email at{" "}
        <font color="#000000">
          <span lang="en-IN">support@toppropfantasy.com</span>
        </font>
        or write to us at{" "}
        <font color="#000000">
          <span lang="en-IN">
            140 Arsenal Street, #2327, Watertown, MA 02472
          </span>
        </font>
        , Attn: TopProp Support.
      </p>
    </Box>
  );
};

export default TermsOfService;

import { Control, Controller, ControllerProps, Path } from "react-hook-form";
import { TextField, TextFieldProps } from "@mui/material";

export type TextInputControlProps<TFormFields> = TextFieldProps &
  Pick<ControllerProps, "rules"> & {
    control: Control<TFormFields, object>;
    id: Path<TFormFields>;
    prefix?: string;
  };

export function TextInputControl<TFormFields>(
  props: TextInputControlProps<TFormFields>
) {
  const { control, prefix, InputProps, ...textFieldProps } = props;

  return (
    <Controller
      control={control}
      name={textFieldProps.id}
      render={({ field, fieldState }) => (
        <TextField
          {...field}
          {...textFieldProps}
          helperText={fieldState.error?.message ?? textFieldProps.helperText}
          InputProps={{
            ...InputProps,
            sx: {
              ...InputProps?.sx,
              fontFamily: "Roboto, sans",
            },
          }}
        />
      )}
    />
  );
}

import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Drawer, Hidden } from "@mui/material";
import { SidebarItemType } from "@shared/types";
import { DRAWER_WIDTH, theme } from "@shared/components/tokens";
import { DrawerContents } from "./DrawerContents";

export interface SidebarDrawerProps {
  isMobileOpen: boolean;
  items: Array<SidebarItemType>;
  onClose: () => void;
  onOpenUserMenu: (anchorEl: Element) => void;
  profilePagePath: string;
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
}

export function SidebarDrawer(props: SidebarDrawerProps) {
  const { isMobileOpen, items, onClose, onOpenUserMenu, profilePagePath } =
    props;
  const navigate = useNavigate();
  const container = document.body;

  const DrawerContentsInst = useMemo(() => {
    const handleSidebarItemClick = (item: SidebarItemType) => {
      if (item?.isExternal) {
        window.location.href = `${item.path}`;
      } else {
        navigate(item.path);
      }
    };

    return (
      <DrawerContents
        items={items}
        onOpenUserMenu={onOpenUserMenu}
        onItemClick={handleSidebarItemClick}
        profilePagePath={profilePagePath}
      />
    );
  }, [items, onOpenUserMenu, profilePagePath, navigate]);

  return (
    <>
      <Box component="nav" width={{ md: DRAWER_WIDTH }}>
        <Hidden lgUp implementation="css">
          <Drawer
            anchor={theme.direction === "rtl" ? "right" : "left"}
            container={container}
            open={isMobileOpen}
            onClose={onClose}
            variant="temporary"
            PaperProps={{
              sx: {
                width: DRAWER_WIDTH,
              },
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {DrawerContentsInst}
          </Drawer>
        </Hidden>
        <Hidden mdDown implementation="css">
          <Drawer
            open
            onClose={onClose}
            variant="permanent"
            PaperProps={{
              sx: {
                width: DRAWER_WIDTH,
              },
            }}
          >
            {DrawerContentsInst}
          </Drawer>
        </Hidden>
      </Box>
    </>
  );
}

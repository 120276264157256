import { useMutation } from "react-query";
import { depositFunds } from "@src/services/wallet";
import { DepositFundsPayload } from "@shared/types";

export function useDepositFunds() {
  return useMutation<any, Error, DepositFundsPayload>((variables) =>
    depositFundsHandler(variables)
  );
}

async function depositFundsHandler(
  variables: DepositFundsPayload
): Promise<any> {
  return depositFunds(variables);
}

import dayjs from "dayjs";

export function renderCurrency(value: number): string {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(value);
}

export function dateRender(value: string): string {
  return dayjs(value).format("MM/DD/YYYY");
}

export function dateTimeRender(value: string): string {
  return dayjs(value).format("MM/DD/YYYY @ hh:mm a");
}

export function d2c(value: string | number): number {
  return Number(value) * 100;
}

export function c2d(value: string | number): number {
  return Number(value) / 100;
}

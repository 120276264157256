import { useMutation } from "react-query";
import { withdrawFundsRequest } from "@src/services/wallet";
import { WithdrawFundsPayload } from "@shared/types";

export function useWithdrawFunds() {
  return useMutation<any, Error, WithdrawFundsPayload>((variables) =>
    withdrawFundsRequestHandler(variables)
  );
}

async function withdrawFundsRequestHandler(
  variables: WithdrawFundsPayload
): Promise<any> {
  return withdrawFundsRequest(variables);
}

import { useMemo } from "react";
import { Link as RouterLink } from "react-router-dom";
import Link from "@mui/material/Link";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  Box,
  Card,
  Container,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import dayjs from "dayjs";
import { LogoFullLarge } from "@shared/components/atoms/logos/LogoFullLarge";
import { TextInputControl } from "@shared/components/molecules/forms/TextInputControl";
import { PasswordControl } from "@shared/components/molecules/forms/PasswordControl";
import { theme } from "@shared/components/tokens/theme";
import { DatePickerControl } from "@shared/components/molecules/forms/DatePickerControl";
import { useGeoLocation } from "@shared/hooks/useGeolocation";
import { ToSCheckboxControl, ToSContestRules } from "./ToS";
import GooglePlay from "@shared/images/GooglePlay.png";
import AppStore from "@shared/images/AppStore.png";

interface IFormValues {
  fullName: string;
  email: string;
  phone: string;
  dateOfBirth: Date;
  password: string;
  confirmPassword: string;
  promo: string;
  tosAccepted: boolean;
}

export interface SignUpProps {
  fallbackState?: string;
  fallbackCountry?: string;
  geocodingKey: string;
  promoCode: string | null;
  isLoading: boolean;
  onSignUp: (
    data: Omit<IFormValues, "dateOfBirth"> & {
      dateOfBirth: string;
      signUpState: string;
      signUpCountry: string;
      username: string;
    }
  ) => void;
}

export function SignUp(props: SignUpProps) {
  const {
    fallbackCountry,
    fallbackState,
    geocodingKey,
    onSignUp,
    isLoading,
    promoCode,
  } = props;
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("md"));

  const { isError, data: geoData } = useGeoLocation({
    geocodingKey,
    fallbackCountry,
    fallbackState,
  });

  const maxDoB = useMemo(
    () => dayjs().subtract(18, "years").subtract(1, "days"),
    []
  );

  const redirectPage = (path: string) => {
    if (window.location) {
      window.location.href = path;
    }
  };

  const formSchema = useMemo(
    () =>
      yup.object({
        fullName: yup.string().required("Full Legal Name is required"),
        email: yup
          .string()
          .email("Invalid email address")
          .required("Email address is required"),
        phone: yup.string(),
        dateOfBirth: yup
          .date()
          .required("Date of birth is required")
          .max(
            maxDoB.toDate(),
            "You must be at least 18 years of age to sign up"
          ),
        password: yup
          .string()
          .required("Please choose a password")
          .matches(
            /^(?=[^A-Z\s]*[A-Z])(?=[^a-z\s]*[a-z])(?=[^\d\s]*\d)(?=\w*[\W_])\S{12,}$/,
            "Password must contain at least 12 characters, one number, one uppercase alphabet, one lowercase alphabet, and one special character"
          ),
        confirmPassword: yup
          .string()
          .required("Please confirm your password")
          .test("passwords-match", "Passwords must match", function (value) {
            return this.parent.password === value;
          }),
        promoCode: yup.string(),
        tosAccepted: yup
          .boolean()
          .required()
          .isTrue(
            "You must agree to the terms of service and privacy policies to sign up"
          ),
      }),
    [maxDoB]
  );

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<IFormValues>({
    resolver: yupResolver(formSchema),
    defaultValues: {
      fullName: "",
      email: "",
      phone: "",
      dateOfBirth: maxDoB.toDate(),
      password: "",
      confirmPassword: "",
      promo: promoCode || "",
      tosAccepted: false,
    },
  });

  const onSubmit: SubmitHandler<IFormValues> = (data) => {
    if (!geoData) {
      throw new Error("No geo data");
    }
    onSignUp({
      ...data,
      dateOfBirth: data.dateOfBirth.toISOString(),
      signUpCountry: geoData!.country,
      signUpState: geoData!.state,
      username: data.email,
    });
  };

  return (
    <Container component="main" maxWidth="xs">
      <Stack
        alignItems="center"
        direction="column"
        justifyContent="center"
        spacing={4}
        height="100%"
      >
        <LogoFullLarge />
        <Card sx={{ width: "100%", p: 2 }}>
          <Stack direction="column" spacing={2}>
            <Stack
              direction="column"
              alignItems={isLargeScreen ? "flex-start" : "center"}
            >
              <Typography variant="h5">Signup</Typography>
              <Stack direction="row" spacing={1}>
                <Typography className="float-left">
                  Already have an account?
                </Typography>
                <Link component={RouterLink} to="/">
                  Login
                </Link>
              </Stack>
            </Stack>
            <Box component="form" onSubmit={handleSubmit(onSubmit)}>
              <Stack direction="column" spacing={2}>
                <TextInputControl
                  control={control}
                  error={Boolean(errors.fullName)}
                  id="fullName"
                  label="Full Legal Name*"
                  rules={{ required: true }}
                  variant="standard"
                />
                <TextInputControl
                  control={control}
                  error={Boolean(errors.email)}
                  id="email"
                  label="Email*"
                  rules={{ required: true }}
                  type="text"
                  variant="standard"
                />
                <TextInputControl
                  control={control}
                  id="phone"
                  label="Phone"
                  variant="standard"
                />
                <DatePickerControl
                  control={control}
                  id="dateOfBirth"
                  label="Date of Birth*"
                  maxDate={maxDoB}
                  value={maxDoB}
                  variant="standard"
                />
                <PasswordControl
                  control={control}
                  error={Boolean(errors.password)}
                  id="password"
                  label="Password*"
                  rules={{ required: true }}
                  type="password"
                  variant="standard"
                />
                <PasswordControl
                  control={control}
                  error={Boolean(errors.confirmPassword)}
                  id="confirmPassword"
                  label="Confirm Password*"
                  rules={{ required: true }}
                  type="password"
                  variant="standard"
                />
                <TextInputControl
                  control={control}
                  id="promo"
                  label="Promo Code"
                  variant="standard"
                />
                <ToSCheckboxControl control={control} id="tosAccepted" />
                <Stack>
                  <Stack direction="row" justifyContent="flex-end">
                    <LoadingButton
                      sx={{
                        pl: 5,
                        pr: 5,
                        fontWeight: "bold",
                      }}
                      color="primary"
                      loading={isLoading}
                      disableElevation
                      disabled={isError || !geoData}
                      fullWidth={!isLargeScreen}
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                      Sign Up
                    </LoadingButton>
                  </Stack>
                  <ToSContestRules isLargeScreen={isLargeScreen} />
                </Stack>
              </Stack>
            </Box>
          </Stack>
        </Card>
        <Stack direction={"row"}>
          <Box
            component="img"
            src={AppStore}
            alt="TopProp Fantasy Sports"
            sx={{
              width: "48%",
              cursor: "pointer",
              marginLeft: "1%",
              marginRight: "1%",
            }}
            onClick={() =>
              redirectPage(
                "https://apps.apple.com/app/topprop-fantasy-sports/id1639570077"
              )
            }
          />
          <Box
            component="img"
            src={GooglePlay}
            alt="TopProp Fantasy Sports"
            sx={{
              width: "48%",
              cursor: "pointer",
              marginLeft: "1%",
              marginRight: "1%",
            }}
            onClick={() =>
              redirectPage(
                "https://play.google.com/store/apps/details?id=com.toppropfantasy.app"
              )
            }
          />
        </Stack>
      </Stack>
    </Container>
  );
}

import { useQuery } from "react-query";

import { QueryKeys } from "@shared/constants/QueryKeys";
import { fetchWalletBalance } from "@shared/services/wallet";
export interface WalletInfo {
  balance: number;
}

export function useWalletInfo() {
  return useQuery<WalletInfo | undefined, Error>(
    QueryKeys.GetUserWallet,
    () => getWalletInfo(),
    {
      cacheTime: 60000,
    }
  );
}

async function getWalletInfo(): Promise<WalletInfo | undefined> {
  const response = await fetchWalletBalance();
  if (response) {
    const walletInfo: WalletInfo = {
      balance: response.data.data,
    };
    return walletInfo;
  }
}

import { useQuery } from "react-query";
import { initializeApp } from "firebase/app";
import { Auth, getAuth } from "firebase/auth";
import { axiosAuthInstance } from "@src/services/axiosInstances";
import { QueryKeys } from "@shared/constants/QueryKeys";

async function getFirebaseAuth(): Promise<Auth> {
  const response = await axiosAuthInstance.get("/v1/firebase-config");
  const config = response.data.data;
  const app = initializeApp(config);
  return getAuth(app);
}

function useFirebaseAuth() {
  return useQuery(QueryKeys.FirebaseConfig, getFirebaseAuth);
}

export default useFirebaseAuth;

import { useRecoilState } from "recoil";
import {
  Box,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { SearchField } from "@shared/components/molecules/searchField/SearchField";
import { searchState } from "./ListState";

interface HeaderProps {
  heading: string;
  isSearchable: boolean;
}

export function Header(props: HeaderProps) {
  const { heading, isSearchable } = props;
  const [search, setSearch] = useRecoilState(searchState);
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("md"));

  const handleSearch = (value: string) => {
    setSearch(value.toLowerCase());
  };

  return (
    <Box component="header">
      <Grid
        container
        rowSpacing={2}
        alignItems="center"
        direction="row"
        justifyContent="space-between"
      >
        <Grid item>
          <Typography sx={{ color: grey[400] }} variant="h6">
            {heading}
          </Typography>
        </Grid>
        {isSearchable ? (
          <Grid item>
            <SearchField
              onSearch={handleSearch}
              placeholder="Search"
              variant="standard"
              value={search}
            />
          </Grid>
        ) : null}
      </Grid>
    </Box>
  );
}

import { useMemo } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { Link as RouterLink } from "react-router-dom";
import Link from "@mui/material/Link";
import {
  Box,
  Card,
  Container,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { LogoFullLarge } from "@shared/components/atoms/logos/LogoFullLarge";
import { TextInputControl } from "@shared/components/molecules/forms/TextInputControl";
import { PasswordControl } from "@shared/components/molecules/forms/PasswordControl";
import { theme } from "@shared/components/tokens/theme";
import { useGeoLocation } from "@shared/hooks/useGeolocation";
import GooglePlay from "@shared/images/GooglePlay.png";
import AppStore from "@shared/images/AppStore.png";

interface IFormValues {
  email: string;
  password: string;
}

export interface SignInProps {
  fallbackState?: string;
  fallbackCountry?: string;
  geocodingKey: string;
  isLoading: boolean;
  onSignIn: (
    data: IFormValues & {
      state: string;
      country: string;
    }
  ) => void;
}

export function SignIn(props: SignInProps) {
  const { fallbackCountry, fallbackState, geocodingKey, isLoading, onSignIn } =
    props;
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("md"));

  const { isError, data: geoData } = useGeoLocation({
    geocodingKey,
    fallbackCountry,
    fallbackState,
  });

  const redirectPage = (path: string) => {
    if (window.location) {
      window.location.href = path;
    }
  };

  const formSchema = useMemo(
    () =>
      yup.object({
        email: yup
          .string()
          .email("Invalid email address")
          .required("Email address is required"),
        password: yup.string().required("Please choose a password"),
      }),
    []
  );

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<IFormValues>({
    resolver: yupResolver(formSchema),
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const onSubmit: SubmitHandler<IFormValues> = (data) => {
    if (!geoData) {
      return;
    }
    onSignIn({
      ...data,
      ...geoData,
    });
  };

  return (
    <Container component="main" maxWidth="xs">
      <Stack
        alignItems="center"
        direction="column"
        justifyContent="center"
        spacing={4}
        height="100%"
      >
        <LogoFullLarge />
        <Typography variant="h4">Welcome to TopProp!</Typography>
        <Card sx={{ width: "100%", p: 2 }}>
          <Stack direction="column" spacing={2}>
            <Stack
              direction="column"
              alignItems={isLargeScreen ? "flex-start" : "center"}
            >
              <Typography variant="h5">Login</Typography>
              <Stack direction="row" spacing={1}>
                <Typography>Not a Member?</Typography>
                <Link component={RouterLink} to="/signup">
                  Sign up now
                </Link>
              </Stack>
            </Stack>
            <Box component="form" onSubmit={handleSubmit(onSubmit)}>
              <Stack direction="column" spacing={2}>
                <TextInputControl
                  control={control}
                  error={Boolean(errors.email)}
                  id="email"
                  label="Email"
                  rules={{ required: true }}
                  type="text"
                  variant="standard"
                />
                <PasswordControl
                  control={control}
                  error={Boolean(errors.password)}
                  id="password"
                  label="Password"
                  rules={{ required: true }}
                  type="password"
                  variant="standard"
                />
                <Stack
                  direction="row"
                  justifyContent={isLargeScreen ? "flex-end" : "center"}
                >
                  <Link component={RouterLink} to="/forgot-password">
                    Forgot Password
                  </Link>
                </Stack>
                <Stack direction="row" justifyContent="flex-end">
                  <LoadingButton
                    sx={{
                      pl: 6,
                      pr: 6,
                      fontWeight: "bold",
                    }}
                    size="large"
                    fullWidth={!isLargeScreen}
                    loading={isLoading}
                    disabled={isError || !geoData}
                    disableElevation
                    variant="contained"
                    color="primary"
                    type="submit"
                  >
                    Login
                  </LoadingButton>
                </Stack>
              </Stack>
            </Box>
          </Stack>
        </Card>
        <Stack direction={"row"}>
          <Box
            component="img"
            src={AppStore}
            alt="TopProp Fantasy Sports"
            sx={{
              width: "48%",
              cursor: "pointer",
              marginLeft: "1%",
              marginRight: "1%",
            }}
            onClick={() =>
              redirectPage(
                "https://apps.apple.com/app/topprop-fantasy-sports/id1639570077"
              )
            }
          />
          <Box
            component="img"
            src={GooglePlay}
            alt="TopProp Fantasy Sports"
            sx={{
              width: "48%",
              cursor: "pointer",
              marginLeft: "1%",
              marginRight: "1%",
            }}
            onClick={() =>
              redirectPage(
                "https://play.google.com/store/apps/details?id=com.toppropfantasy.app"
              )
            }
          />
        </Stack>
      </Stack>
    </Container>
  );
}

import Box from "@mui/material/Box";
import LogoImage from "@shared/images/LogoFull.png";

export function LogoFullLarge({ width }: { width?: string | number }) {
  return (
    <Box
      component="img"
      src={LogoImage}
      alt="TopProp Fantasy Sports"
      sx={{ width: width ?? "100%" }}
    />
  );
}

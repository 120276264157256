import { atom } from "recoil";

enum StateKeys {
  Search = "List.Search",
  SortCriteria = "List.SortCriteria",
}

export const searchState = atom({
  key: StateKeys.Search,
  default: "",
});

interface ISortCriteria {
  field: string;
  order: "ASC" | "DESC";
}

export const sortCriteriaState = atom<ISortCriteria | null>({
  key: StateKeys.SortCriteria,
  default: null,
});

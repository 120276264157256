import {
  ButtonBase,
  Card,
  CircularProgress,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { Box } from "@mui/system";

interface AddFundingSourceCardProps {
  isLoading: boolean;
  onClick: Function;
  isPayout?: boolean;
  disabled?: boolean;
  disabledMessage?: string;
}

export function AddFundingSourceCard(props: AddFundingSourceCardProps) {
  const {
    isLoading,
    onClick,
    isPayout = false,
    disabled = false,
    disabledMessage = "",
  } = props;

  // const isDisabled = true;

  return (
    <Box sx={{ paddingTop: "1rem", paddingBottom: "1rem" }}>
      <Tooltip title={disabled ? disabledMessage : ""}>
        <span>
          <ButtonBase onClick={() => onClick()} disabled={disabled}>
            <Card
              sx={{
                display: "grid",
                height: {
                  xs: "17rem",
                },
                width: "20rem",
                placeItems: "center",
                padding: "0px 1rem 0.5rem 1rem",
                borderRadius: "1rem",
                cursor: "pointer",
              }}
            >
              <Box>
                <Stack width="100%" alignItems="center">
                  {!isLoading && (
                    <AddIcon
                      sx={{ color: disabled ? "gray" : "primary.main" }}
                      fontSize="large"
                    />
                  )}
                  {isLoading && <CircularProgress size={24} />}
                </Stack>
                <Typography
                  color="primary"
                  variant="h6"
                  align="center"
                  sx={{
                    lineHeight: 1,
                    color: disabled ? "gray" : "primary.main",
                  }}
                >
                  Add {isPayout ? "payout destination" : "funding source"}
                </Typography>
              </Box>
            </Card>
          </ButtonBase>
        </span>
      </Tooltip>
    </Box>
  );
}

import React from "react";
import { Theme } from "@mui/material/styles";

import {
  Grid,
  Stack,
  Card as MuiCard,
  Typography,
  Box,
  Radio,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import BankLogo from "@shared/icons/bank.svg";
import BankBG from "@shared/images/bank-bg.png";
import { PaymentMethodData } from "@shared/types";

export interface FundingSourceProps {
  fundingSource: PaymentMethodData;
  selectedFundingSource: PaymentMethodData | null;
  handleDeleteCard: Function;
  setValue: Function;
}

const SelectionFundingSource = ({
  fundingSource,
  selectedFundingSource,
  handleDeleteCard,
  setValue,
}: FundingSourceProps) => {
  const { CardNumber, NameOnAccount } = fundingSource;

  const handleDelete = () => {
    handleDeleteCard(fundingSource.Token);
  };

  return (
    <Box sx={{ paddingTop: "1rem", paddingBottom: "1rem" }}>
      <MuiCard
        sx={{
          display: "grid",
          height: {
            xs: "17rem",
          },
          width: "20rem",
          placeItems: "start",
          padding: "0rem 1rem 0.5rem 1rem",
          borderRadius: "1rem",
          backgroundImage: `url(${BankBG})`,
          backgroundSize: "cover",
          backgroundColor: (theme: Theme) =>
            selectedFundingSource?.Token === fundingSource.Token
              ? theme.palette.primary.light
              : theme.palette.grey[300],
        }}
      >
        <Grid padding={2} container justifyItems="space-between">
          <Stack width="100%" alignItems="center">
            <Grid item xs={12} container justifyContent="space-between">
              <Grid item xs={6} sm={6} md={6}>
                <Box
                  component="img"
                  sx={{
                    width: "70%",
                    fill: "#fff",
                  }}
                  src={BankLogo}
                  alt={CardNumber}
                />
              </Grid>
              <Grid item xs={3} container justifyContent="flex-end"></Grid>
              <Grid
                item
                xs={3}
                sm={3}
                md={3}
                container
                justifyContent="flex-end"
                alignContent="center"
                color="white"
                sx={{ cursor: "pointer" }}
              >
                {/* <Box
                  component={DeleteIcon}
                  sx={{
                    color: "#fff",
                    cursor: "pointer",
                  }}
                  onClick={() => handleDelete()}
                /> */}
                <Radio
                  style={{ color: "#ffffff" }}
                  checked={
                    selectedFundingSource
                      ? selectedFundingSource.Token === fundingSource.Token
                      : false
                  }
                  onChange={() => {
                    setValue("fundingSourceDetails", fundingSource);
                  }}
                />
              </Grid>
            </Grid>
          </Stack>
          <Grid item xs={12}>
            <Typography
              sx={{
                color: "#fff",
                paddingTop: "1rem",
                paddingBottom: "1rem",
              }}
              variant="h5"
            >
              {CardNumber}
            </Typography>
          </Grid>

          <Grid
            item
            xs={12}
            container
            justifyContent="space-between"
            className="justify-self-end"
          >
            {/* <Grid item xs={4} sm={3}>
                <img className="w-full" src={ChipLogo} alt="chip" />
              </Grid> */}
            <Grid
              item
              xs={12}
              container
              direction="column"
              justifyContent="center"
            >
              <Typography align="left" variant="h6" sx={{ color: "#fff" }}>
                {NameOnAccount}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </MuiCard>
    </Box>
  );
};

export default SelectionFundingSource;

import * as jwt from "jsonwebtoken";
import * as BrowserStorage from "@shared/constants/BrowserStorage";

export function saveAuthTokens(tokens: {
  accessToken: string;
  refreshToken: string;
}) {
  window.localStorage.setItem(BrowserStorage.ACCESS_TOKEN, tokens.accessToken);
  window.localStorage.setItem(
    BrowserStorage.REFRESH_TOKEN,
    tokens.refreshToken
  );
}

export function saveUserConfig(config: string) {
  window.localStorage.setItem(BrowserStorage.USER_CONFIG, config);
}

export function loadAuthTokens(): {
  accessToken: string;
  refreshToken: string;
} {
  const accessToken = window.localStorage.getItem(BrowserStorage.ACCESS_TOKEN);
  const refreshToken = window.localStorage.getItem(
    BrowserStorage.REFRESH_TOKEN
  );
  if (!accessToken || !refreshToken) {
    throw new Error("Tokens not found");
  }
  // test both tokens
  if (!jwt.decode(accessToken) || !jwt.decode(refreshToken)) {
    throw new Error("Invalid tokens");
  }
  return { accessToken, refreshToken };
}

export function loadUserConfig() {
  return JSON.parse(
    window.localStorage.getItem(BrowserStorage.USER_CONFIG) || ""
  );
}

export function clearAuthTokens() {
  window.localStorage.removeItem(BrowserStorage.ACCESS_TOKEN);
  window.localStorage.removeItem(BrowserStorage.REFRESH_TOKEN);
}

export function clearUserConfig() {
  window.localStorage.removeItem(BrowserStorage.USER_CONFIG);
}

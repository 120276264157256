import { Tab, Grid, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";

export interface TabPanelProps {
  path: string;
  label: string;
  badge?: JSX.Element;
  index: number;
  ariaControlKey?: string;
}

export function TabLink(props: TabPanelProps) {
  const { label, badge, path, index, ariaControlKey, ...tabProps } = props;
  const navigate = useNavigate();

  const handleTabClick = (path: string) => {
    navigate(path);
  };

  const a11yProps = (index: number) => {
    return {
      id: `${ariaControlKey}-${index}`,
      "aria-controls": `${ariaControlKey}-${index}`,
    };
  };

  return (
    <Tab
      onClick={(event) => {
        event.preventDefault();
        handleTabClick(path);
      }}
      sx={{ fontSize: { md: "1rem !important" } }}
      {...tabProps}
      label={
        badge ? (
          <Grid container alignItems="center">
            <Grid
              item
              xs={8}
            >
              Account Verification
            </Grid>
            <Grid
              item
              xs={4}
              className="pl-1"
            >
              {badge}
            </Grid>
          </Grid>
        ) : (
          <Grid container alignItems="center">
            {label}
          </Grid>
        )
      }
      {...a11yProps(index)}
    />
  );
}

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { Footer } from "@shared/components/organisms/footer";

export interface Props {}

const BaseLayout: React.FC<Props> = ({ children }) => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Grid container sx={{ height: "100vh" }}>
      <Box
        component="main"
        sx={{
          flexGrow: "1",
          overflow: "auto",
          width: "100%",
        }}
      >
        <Grid
          container
          direction="column"
          justifyContent="center"
          sx={{
            height: "100%",
          }}
        >
          <Grid
            item
            container
            direction="column"
            justifyContent="space-between"
            sx={{
              height: "100%",
              flex: "1 1 0%",
            }}
          >
            <Grid
              container
              item
              direction="column"
              justifyContent="center"
              sx={{
                width: "100%",
                flex: "1 1 0%",
              }}
            >
              <Box
                py={2}
                px={isSmall ? 1 : 3}
                sx={{
                  width: "100%",
                  maxWidth: "1536px",
                  margin: "0px auto",
                }}
              >
                {children}
              </Box>
            </Grid>
            <Grid
              item
              sx={{
                width: "100%",
              }}
            >
              <Footer hideBorder={true} />
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
};

export default BaseLayout;

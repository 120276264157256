import { useRef } from "react";
import { Box, ButtonBase, Stack, Typography } from "@mui/material";
import voca from "voca";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import { useUserInfo } from "@shared/hooks/useUserInfo";

interface ProfileButtonProps {
  onOpenUserMenu: (anchorEl: Element) => void;
}

export function ProfileButton(props: ProfileButtonProps) {
  const { onOpenUserMenu } = props;
  const { data: userInfo } = useUserInfo();
  const buttonRef = useRef<HTMLButtonElement>(null);

  const handleClick = () => {
    if (!buttonRef.current) {
      return;
    }
    onOpenUserMenu(buttonRef.current);
  };
  return (
    <ButtonBase
      disableRipple
      onClick={handleClick}
      ref={buttonRef}
      sx={{ width: "100%", p: 1 }}
    >
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        spacing={1}
        width="100%"
      >
        <AccountCircleOutlinedIcon />
        <Typography variant="body1" whiteSpace="nowrap" textOverflow="ellipsis">
          {userInfo?.fullName ? voca.prune(userInfo.fullName, 10) : ""}
        </Typography>
        <Box m={1}>
          <KeyboardArrowDownOutlinedIcon />
        </Box>
      </Stack>
    </ButtonBase>
  );
}

import { Hidden, Grid, Typography, Button } from "@mui/material";
import { renderCurrency } from "@shared/helpers/currency";
import { useWalletInfo } from "@shared/hooks/useWalletInfo";
import { grey } from "@mui/material/colors";

interface BalanceAndDepositProps {
  profilePagePath: string;
}

export function BalanceAndDeposit(props: BalanceAndDepositProps) {
  const { profilePagePath } = props;
  const { data: walletInfo } = useWalletInfo();

  return (
    <Hidden mdDown implementation="css">
      <Grid container direction="column" alignContent="center" px={2} pb={2}>
        <Typography align="center" variant="subtitle2" color={grey[600]}>
          Available Balance
        </Typography>
        <Typography
          align="center"
          variant="h5"
          color="secondary"
          fontWeight="bold"
        >
          {walletInfo ? renderCurrency(walletInfo.balance / 100) : "N.A"}
        </Typography>
        <Grid item width="100%" pt={1}>
          <Button
            color="secondary"
            fullWidth
            href={profilePagePath}
            size="small"
            variant="outlined"
          >
            Deposit
          </Button>
        </Grid>
      </Grid>
    </Hidden>
  );
}

import { Box, Card, Container, Stack, Typography } from "@mui/material";
import { LogoFullLarge } from "@shared/components/atoms/logos/LogoFullLarge";
import GooglePlay from "@shared/images/GooglePlay.png";
import AppStore from "@shared/images/AppStore.png";

export function MobilePromotion() {
  const redirectPage = (path: string) => {
    if (window.location) {
      window.location.href = path;
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Stack
        alignItems="center"
        direction="column"
        justifyContent="center"
        spacing={4}
        height="100%"
      >
        <LogoFullLarge />
        <Card sx={{ width: "100%", p: 4 }}>
          <Typography variant="h4" textAlign="center">
            Thank you for signing up with a referral code. Download our app to
            login and get started
          </Typography>
        </Card>
        <Stack direction={"row"}>
          <Box
            component="img"
            src={AppStore}
            alt="TopProp Fantasy Sports"
            sx={{
              width: "48%",
              cursor: "pointer",
              marginLeft: "1%",
              marginRight: "1%",
            }}
            onClick={() =>
              redirectPage(
                "https://apps.apple.com/app/topprop-fantasy-sports/id1639570077"
              )
            }
          />
          <Box
            component="img"
            src={GooglePlay}
            alt="TopProp Fantasy Sports"
            sx={{
              width: "48%",
              cursor: "pointer",
              marginLeft: "1%",
              marginRight: "1%",
            }}
            onClick={() =>
              redirectPage(
                "https://play.google.com/store/apps/details?id=com.toppropfantasy.app"
              )
            }
          />
        </Stack>
      </Stack>
    </Container>
  );
}

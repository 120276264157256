import { Stack, Typography } from "@mui/material";
import {
  PRIMARY,
  PRIMARY_CONTRAST_TEXT,
} from "@shared/components/tokens/colors";

interface BannerProps {
  mainText: string;
  subText?: string;
}

export function Banner(props: BannerProps) {
  const { mainText, subText } = props;
  return (
    <Stack bgcolor={PRIMARY} padding={1} width="100%">
      <Typography align="center" variant="h6" color={PRIMARY_CONTRAST_TEXT}>
        {mainText}
      </Typography>
      {subText ? (
        <Typography
          align="center"
          variant="caption"
          color={PRIMARY_CONTRAST_TEXT}
        >
          {subText}
        </Typography>
      ) : null}
    </Stack>
  );
}

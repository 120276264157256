import { initializeApp } from "firebase/app";
import { Auth, getAuth } from "firebase/auth";
import { axiosAuthInstance, axiosCommonInstance } from "./axiosInstances";

export interface IRenewTokenPayload {
  accessToken: string;
  refreshToken: string;
  email: string;
}

export interface ISupportMailPayload {
  message: string;
  userId: number;
  userName: string;
  email: string;
}

export const fetchMe = () => {
  return axiosAuthInstance({
    method: "GET",
    url: `v1/user/me`,
  });
};

export const renewAccessToken = (payload: IRenewTokenPayload) => {
  return axiosAuthInstance({
    method: "POST",
    url: `v1/renew-access-token`,
    data: payload,
  });
};

// Support Mail Service
export const supportMail = (supportMailPayload: ISupportMailPayload) => {
  return axiosCommonInstance({
    method: "POST",
    url: `v1/send-support`,
    data: supportMailPayload,
  });
};

export async function getFirebaseAuth(): Promise<Auth> {
  const response = await axiosAuthInstance.get("v1/firebase-config");
  const config = response.data.data;
  const app = initializeApp(config);
  return getAuth(app);
}

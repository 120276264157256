import { useQuery } from "react-query";

import { QueryKeys } from "@shared/constants/QueryKeys";
import { ListDataType } from "@shared/components/organisms/transfers";

import { fetchTransactions } from "@shared/services/wallet";

export interface TItem {
  id: number;
  creditorId?: number;
  debitorId?: number;
  createdAt: string;
  type: string;
  status: string;
  originatingService?: string;
  amount: number;
  narration: string;
}

export interface TransferResponse<TItem> {
  rows: Array<TItem>;
}

export function useTransferInfo() {
  return useQuery(QueryKeys.FetchTransfers, () => fetchTransfers(), {
    cacheTime: 60000,
  });

  async function fetchTransfers(): Promise<ListDataType<TItem> | undefined> {
    const response = await fetchTransactions();
    if (response) {
      const transfersInfo: ListDataType<TItem> = {
        rows: response.data.data,
      };
      return transfersInfo;
    }
  }
}

import { MobilePromotion as MobilePromotionTemplate } from "@shared/components/templates/MobilePromotion";
import BaseLayout from "@src/components/templates/BaseLayout";

export function MobilePromotion() {
  return (
    <BaseLayout>
      <MobilePromotionTemplate />
    </BaseLayout>
  );
}

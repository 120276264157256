import { useQuery } from "react-query";
import dayjs from "dayjs";

import { QueryKeys } from "@shared/constants/QueryKeys";
import { createCustomer, fetchCustomer } from "@shared/services/wallet";
import { GatewayCustomerData } from "@shared/types";

const CACHE_TIME = 5; // minutes

interface SessionParams {
  lat?: number;
  long?: number;
}

export function useGatewayInfo(data: SessionParams) {
  return useQuery(QueryKeys.GetUserGateway, () => getGatewayInfo(data), {
    cacheTime: 60000,
    enabled: !!data.lat,
  });

  async function getGatewayInfo(
    data: SessionParams
  ): Promise<GatewayCustomerData> {
    return new Promise((resolve) => {
      fetchCustomer(data)
        .then((response: any) => {
          if (response) {
            const gatewayInfo: GatewayCustomerData = {
              ...response.data.data,
              expiresAt: dayjs().add(CACHE_TIME, "minute").toDate().getTime(),
            };
            return resolve(gatewayInfo);
          }
        })
        .catch(async (error) => {
          if (error.response) {
            if (
              error.response.data.message ===
              "The requested resource was not found."
            ) {
              await createCustomer();
              await fetchCustomer(data);
              // await fetchIavTokenRequest();
            }
            console.log(error.response.data);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("Error", error.message);
          }
        });
    });
  }
}

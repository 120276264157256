import { useMemo, useEffect } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import {
  Button,
  Grid,
  Typography,
  useMediaQuery,
  Link,
  Box,
  Chip,
} from "@mui/material";
import dayjs from "dayjs";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import BlockIcon from "@mui/icons-material/Block";

import { theme } from "@shared/components/tokens/theme";
import { DatePickerControl } from "@shared/components/molecules/forms/DatePickerControl";
import { TextInputControl } from "@shared/components/molecules/forms/TextInputControl";
import { SelectControl } from "@shared/components/molecules/forms/SelectControl";
import { FileUploadControl } from "@shared/components/molecules/forms/FileUploadControl";
import { CanadianStates, Countries, USStates } from "@shared/constants/States";
import {
  GatewayCustomerData,
  ISelectOption,
  UserVerificationStatusType,
  VerificationFormValues,
} from "@shared/types";
import { VerificationStatus } from "./VerificationStatus";
import { CachedUserInfo } from "@shared/hooks/useUserInfo";

import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/system";
import { red } from "@mui/material/colors";

export interface AccountVerificationProps {
  isIdVerificationUploaded?: boolean;
  isLoading: boolean;
  isVerified: boolean;
  isVerifying: boolean;
  isGatewayInfoLoading: boolean;
  onVerify: (data: VerificationFormValues) => void;
  userVerificationStatus: UserVerificationStatusType;
  verificationFileName?: string;
  gatewayInfo?: GatewayCustomerData;
  userInfo?: CachedUserInfo;
}

const useStyles = makeStyles((theme: Theme) => ({
  zeroPad: {
    paddingLeft: "0 !important",
    paddingRight: "0 !important",
  },
}));

export function AccountVerification(props: AccountVerificationProps) {
  const {
    isIdVerificationUploaded = false,
    isLoading,
    onVerify,
    isVerifying,
    userVerificationStatus,
    verificationFileName,
    gatewayInfo,
    userInfo,
    isGatewayInfoLoading,
  } = props;

  const isSmall = useMediaQuery(theme.breakpoints.down("md"));
  const isVerySmall = useMediaQuery(theme.breakpoints.down("sm"));
  // const [test, setTest] = useState(0);
  const classes = useStyles();
  const isVerified = useMemo(
    () => userVerificationStatus === UserVerificationStatusType.Verified,
    [userVerificationStatus]
  );
  const isRetry = useMemo(
    () => userVerificationStatus === UserVerificationStatusType.Retry,
    [userVerificationStatus]
  );
  const isBlocked = useMemo(
    () => userVerificationStatus === UserVerificationStatusType.Blocked,
    [userVerificationStatus]
  );
  const isDeactivated = useMemo(
    () => userVerificationStatus === UserVerificationStatusType.Deactivated,
    [userVerificationStatus]
  );

  const isDocument = useMemo(
    () => userVerificationStatus === UserVerificationStatusType.Document,
    [userVerificationStatus]
  );

  const countriesDropdownData = useMemo(
    () =>
      Countries.map((country) => {
        return {
          label: country.text,
          value: country.value,
        };
      }),
    []
  );

  const USstateDropdownData = useMemo(
    () =>
      USStates.map((state) => {
        return {
          label: state.text,
          value: state.value,
        };
      }),
    []
  );

  const CanadianStatesDropdownData = useMemo(
    () =>
      CanadianStates.map((state) => {
        return {
          label: state.text,
          value: state.value,
        };
      }),
    []
  );

  const { control, handleSubmit, reset, watch } =
    useForm<VerificationFormValues>({
      defaultValues: {
        email: userInfo?.email || "",
        firstName: gatewayInfo?.firstName || "",
        lastName: gatewayInfo?.lastName || "",
        dob: dayjs().subtract(18, "years").toDate(),
        country: countriesDropdownData[0],
        address: "",
        city: "",
        phone: userInfo?.phone || "",
        zipcode: "",
      },
    });

  const watchCountry = watch("country");

  const selectedStateDropdownData: ISelectOption[] = useMemo(() => {
    let selectedStateDropdown;
    switch (watchCountry?.value) {
      case "US":
        selectedStateDropdown = USstateDropdownData;
        break;
      case "CA":
        selectedStateDropdown = CanadianStatesDropdownData;
        break;
      default:
        selectedStateDropdown = USstateDropdownData;
        break;
    }
    return selectedStateDropdown;
  }, [watchCountry, CanadianStatesDropdownData, USstateDropdownData]);

  useEffect(() => {
    if (gatewayInfo) {
      const selectedCountry = countriesDropdownData.find(
        (country) => country.value === gatewayInfo?.country
      );

      const selectedState = selectedStateDropdownData.find(
        (state) => state.value === gatewayInfo?.state
      );

      const payload = {
        email: gatewayInfo?.email || "",
        firstName: gatewayInfo?.firstName || "",
        lastName: gatewayInfo?.lastName || "",
        dob: dayjs(userInfo?.dateOfBirth).toDate(),
        country: selectedCountry,
        state: selectedState,
        city: gatewayInfo?.city,
        zipcode: gatewayInfo?.postalCode,
        phone: gatewayInfo?.phone || userInfo?.phone || "",
        address: gatewayInfo?.address1,
      };
      reset(payload);
      // setTest(0);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gatewayInfo, userInfo, reset, countriesDropdownData]);

  const onSubmit: SubmitHandler<VerificationFormValues> = (data) => {
    onVerify(data);
  };

  return (
    <Box className={classes.zeroPad}>
      {userInfo ? (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box>
            <Grid
              container
              spacing={0}
              rowSpacing={3}
              columnSpacing={isVerySmall ? 0 : 3}
              sx={{ marginLeft: "0px", width: "100%" }}
            >
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                lg={6}
                className={classes.zeroPad}
              >
                <Typography variant={isSmall ? "h6" : "h5"}>
                  Account Verification
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} justifyContent="flex-end">
                <VerificationStatus status={userVerificationStatus} />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                className={classes.zeroPad}
              >
                <TextInputControl
                  control={control}
                  disabled={isVerified || isDeactivated || isBlocked}
                  fullWidth
                  id="email"
                  label="Email"
                  required
                  size="small"
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                lg={6}
                className={classes.zeroPad}
              >
                <TextInputControl
                  control={control}
                  disabled={isVerified || isDeactivated || isBlocked}
                  fullWidth
                  id="firstName"
                  label="First Name"
                  required
                  size="small"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <TextInputControl
                  control={control}
                  disabled={isVerified || isDeactivated || isBlocked}
                  fullWidth
                  id="lastName"
                  label="Last Name"
                  required
                  size="small"
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                lg={6}
                className={classes.zeroPad}
              >
                <DatePickerControl
                  control={control}
                  disabled={isVerified || isDeactivated || isBlocked}
                  id="dob"
                  label="Date of Birth"
                  helperText="mm/dd/yyyy"
                  required
                  size="small"
                  defaultValue={dayjs(userInfo?.dateOfBirth)}
                  maxDate={dayjs().subtract(18, "years")}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <TextInputControl
                  control={control}
                  disabled={isVerified || isDeactivated || isBlocked}
                  fullWidth
                  id="address"
                  label="Address"
                  required
                  size="small"
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                lg={6}
                className={classes.zeroPad}
              >
                <TextInputControl
                  control={control}
                  id="city"
                  label="City"
                  disabled={isVerified || isDeactivated || isBlocked}
                  fullWidth
                  required
                  variant="outlined"
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <TextInputControl
                  control={control}
                  disabled={isVerified || isDeactivated || isBlocked}
                  fullWidth
                  id="phone"
                  label="Phone"
                  required
                  size="small"
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={isDocument ? 3 : 6}
                md={isDocument ? 3 : 6}
                lg={isDocument ? 3 : 6}
                className={classes.zeroPad}
              >
                <SelectControl
                  id="country"
                  isDisabled={isVerified || isDeactivated || isBlocked}
                  control={control}
                  isClearable
                  isSearchable
                  options={countriesDropdownData}
                  placeholder="Select Country"
                  rules={{ required: true }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={isDocument ? 3 : 3}
                md={isDocument ? 3 : 3}
                lg={isDocument ? 3 : 3}
              >
                <SelectControl
                  id="state"
                  isDisabled={isVerified || isDeactivated || isBlocked}
                  control={control}
                  isClearable
                  isSearchable
                  options={selectedStateDropdownData}
                  placeholder="Select State"
                  rules={{ required: true }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={isDocument ? 3 : 3}
                md={isDocument ? 3 : 3}
                lg={isDocument ? 3 : 3}
              >
                <TextInputControl
                  control={control}
                  disabled={isVerified || isDeactivated || isBlocked}
                  id="zipcode"
                  label="Zip Code"
                  autoComplete=""
                  fullWidth
                  size="small"
                  variant="outlined"
                  required
                />
              </Grid>
              <Grid item xs={12} sm={3} md={3} lg={3}>
                {isDocument && (
                  <>
                    {!isIdVerificationUploaded && !verificationFileName && (
                      <FileUploadControl
                        control={control}
                        id="file"
                        label="Upload verification file"
                        helperText={
                          isIdVerificationUploaded && verificationFileName
                            ? `${verificationFileName} Your Id is in review, we will let you know once we have some news about it.`
                            : "Please upload an image (PNG or JPG) of a government issued Driver's License (9mb max)."
                        }
                      />
                    )}
                  </>
                )}
              </Grid>
              {gatewayInfo?.status === UserVerificationStatusType.Blocked && (
                <Grid item xs={12} container justifyContent="center">
                  {/* <Typography sx={{ color: red[500] }} textAlign="center">
                    {gatewayInfo?.statusMessage}
                  </Typography> */}
                  <Chip
                    icon={<BlockIcon />}
                    label={gatewayInfo?.statusMessage}
                    variant="outlined"
                    color="error"
                  />
                </Grid>
              )}
            </Grid>
            <Grid item xs={12} className={classes.zeroPad}>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                spacing={0}
                rowSpacing={3}
                columnSpacing={isVerySmall ? 0 : 3}
                sx={{ marginLeft: "0px", marginTop: "0.5rem", width: "100%" }}
              >
                {/* @ts-ignore */}
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  lg={3}
                  className={classes.zeroPad}
                >
                  <Button
                    size="large"
                    variant="outlined"
                    color="primary"
                    component={Link}
                    href="https://topprop-static-assets.s3.us-west-2.amazonaws.com/ID_Verification.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                    download
                    fullWidth
                  >
                    Download Instructions
                  </Button>
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={3}>
                  <Button
                    type="submit"
                    size="large"
                    disabled={
                      isVerified ||
                      isLoading ||
                      isDeactivated ||
                      isBlocked ||
                      isGatewayInfoLoading ||
                      isVerifying
                    }
                    variant="contained"
                    color="primary"
                    fullWidth
                  >
                    Submit Verification
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </form>
      ) : (
        ""
      )}
    </Box>
  );
}

import { useState } from "react";
import { SupportModal } from "@shared/components/organisms/supportModal";
import { SidebarItemType } from "@shared/types";
import { SidebarDrawer } from "@shared/components/molecules/sidebar/Drawer";
import { AppBar } from "@shared/components/molecules/sidebar/AppBar";
import { UserMenu } from "@shared/components/molecules/sidebar/UserMenu";
import { useUserInfo } from "@shared/hooks/useUserInfo";

export interface SidebarProps {
  hasPaidContests: boolean;
  items: Array<SidebarItemType>;
  profilePagePath: string;
  showAppBar?: boolean;
  logout: () => void;
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
}

export function Sidebar(props: SidebarProps) {
  const {
    hasPaidContests,
    items,
    profilePagePath,
    showAppBar = false,
    logout,
    window,
  } = props;

  const { data: userInfo } = useUserInfo();

  const [isSupportDialogOpen, setSupportDialogOpen] = useState(false);
  const [userMenuAnchor, setUserMenuAnchor] = useState<Element | null>(null);
  const [isMobileDrawerOpen, setMobileDrawerOpen] = useState(false);

  return (
    <>
      <AppBar
        isDisabled={!showAppBar}
        onOpenDrawer={() => setMobileDrawerOpen(true)}
      />
      <SidebarDrawer
        isMobileOpen={isMobileDrawerOpen}
        items={items}
        onClose={() => setMobileDrawerOpen(false)}
        onOpenUserMenu={setUserMenuAnchor}
        profilePagePath={profilePagePath}
        window={window}
      />
      <UserMenu
        anchorEl={userMenuAnchor}
        hasPaidContests={hasPaidContests}
        onClose={() => setUserMenuAnchor(null)}
        onOpenSupport={() => setSupportDialogOpen(true)}
        onLogout={logout}
        profilePagePath={profilePagePath}
      />
      {userInfo && (
        <SupportModal
          isOpen={isSupportDialogOpen}
          onClose={() => setSupportDialogOpen(false)}
        />
      )}
    </>
  );
}

import { useNavigate } from "react-router-dom";
import { Grid, AppBar, Button, Box } from "@mui/material";

const PublicPagesNavBar = () => {
  let navigate = useNavigate();
  return (
    <Box
      component={AppBar}
      position="fixed"
      sx={{
        top: "auto",
        bottom: "1rem",
        padding: "0.25rem",
        boxShadow: "none",
        bgcolor: "transparent",
      }}
    >
      <Grid container justifyContent="center">
        <Grid item xs={11} sm={10} md={6} lg={2}>
          <Button
            variant="contained"
            fullWidth
            color="primary"
            onClick={() => {
              navigate(-1);
            }}
          >
            Back
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PublicPagesNavBar;

import { useState, useEffect, useMemo } from "react";
import {
  Box,
  Button,
  Grid,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Divider } from "@mui/material";
import { theme } from "@shared/components/tokens/theme";
import { AddFundingSourceCard } from "./AddFundingSourceCard";
import { FundingSourceCard } from "./FundingSourceCard";
import VerifyAccountModal from "@shared/components/organisms/verifyAccountModal/VerifyAccountModal";
import { useCreateTsevoSession } from "@shared/hooks/useCreateTsevoSession";
import CreatePayoutDestinationModal from "@shared/components/organisms/createPayoutDestinationModal/CreatePayoutDestinationModal";
import { PayoutMethodData, WithdrawFundsPayload } from "@shared/types";
import * as yup from "yup";
import { AppRoot, routesForApp } from "@shared/constants/routePaths";
import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/system";
import { TextInputControlWithPrefix } from "@shared/components/organisms/TextInputControlWithPrefix";
import { useForm, SubmitHandler, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useGeoLocation } from "@shared/hooks/useGeolocation";

const routes = routesForApp<AppRoot.ACCOUNT>(AppRoot.ACCOUNT);
export interface WithdrawFundsProps {
  fundingSources: PayoutMethodData[];
  isVerified: boolean;
  hasInsufficientFunds: boolean;
  onWithdraw: (payload: WithdrawFundsPayload) => void;
  onDeleteFundingSource: (fudingSourceId: string) => void;
  availableBalance: number;
}
interface IFormValues {
  amount: number;
  fundingSourceDetails: PayoutMethodData | null;
}

const useStyles = makeStyles((theme: Theme) => ({
  zeroPad: {
    paddingLeft: "0 !important",
    paddingRight: "0 !important",
  },
}));

export function WithdrawFunds(props: WithdrawFundsProps) {
  const {
    fundingSources,
    isVerified,
    hasInsufficientFunds,
    onWithdraw,
    availableBalance,
    onDeleteFundingSource,
  } = props;

  const geocodingKey = process.env.REACT_APP_GOOGLE_GEOCODING_KEY || "";
  const fallbackCountry = "IN";
  const fallbackState = "GA";

  const { isError, data: geoData } = useGeoLocation({
    geocodingKey,
    fallbackCountry,
    fallbackState,
  });

  const navigate = useNavigate();

  const { data: tsevoSession } = useCreateTsevoSession({
    payActionCode: "PAYOUT",
    lat: geoData?.lat,
    long: geoData?.long,
  });

  const tsevoStatus = tsevoSession?.tsevoStatus;
  const isDisabled = tsevoStatus?.status === "blocked";
  const disabledMessage = tsevoStatus?.message || "";

  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();
  const [isAddCardModalOpen, setAddCardModalOpen] = useState(false);
  const [verifyModalOpen, setVerifyModalOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [selectedFundingSource, setSelectedFundingSource] =
    useState<PayoutMethodData>(fundingSources[0]);
  const formSchema = useMemo(
    () =>
      yup.object({
        amount: yup.number().min(20, "Minimum allowed value is 20"),
      }),
    []
  );
  const methods = useForm<IFormValues>({
    resolver: yupResolver(formSchema),
    defaultValues: {
      amount: 20,
      fundingSourceDetails: fundingSources[0],
    },
  });
  const { watch, control, setValue } = methods;
  const amount = watch("amount");

  const openVerifyModal = () => {
    setVerifyModalOpen(true);
  };

  const handleVerifyConfirm = () => {
    navigate(routes.profile.verify);
    setVerifyModalOpen(false);
  };

  useEffect(() => {
    const latestPaymentSource = fundingSources[fundingSources.length - 1];
    setSelectedFundingSource(latestPaymentSource);
  }, [fundingSources]);

  const onSubmit: SubmitHandler<IFormValues> = (data) => {
    const payload = {
      amount: data.amount * 100,
      destinationFundingSourceId: selectedFundingSource?.Token || "",
      merchantSessionId: tsevoSession.MerchantSessionID,
      lat: geoData?.lat,
      long: geoData?.long,
    };

    onWithdraw(payload);
  };

  const handleDelete = (fundingSourceId: string) => {
    onDeleteFundingSource(fundingSourceId);
  };

  const openAddFundingSourceModal = () => {
    setLoading(true);
    setAddCardModalOpen(true);
  };

  const closeAddFundingSourceModal = () => {
    setLoading(false);
    setAddCardModalOpen(false);
  };

  return (
    <Box className={classes.zeroPad}>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Grid container rowSpacing={3}>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              className={classes.zeroPad}
            >
              <Stack direction="row" justifyContent="space-between">
                <Typography variant={isSmall ? "h6" : "h5"}>
                  Available Balance
                </Typography>
                <Typography
                  align="right"
                  color="primary"
                  variant={isSmall ? "h6" : "h5"}
                >
                  ${availableBalance / 100}
                </Typography>
              </Stack>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              className={classes.zeroPad}
            >
              <Typography variant={isSmall ? "h6" : "h5"}>
                Profits Payout
              </Typography>
              <Typography marginBottom={1}>
                This information is for TopProp’s private use only. By adding
                your bank information, you can withdraw your profits for direct
                deposit.
              </Typography>
              <Divider />
            </Grid>
            {!fundingSources.length ? (
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                className={classes.zeroPad}
              >
                <Typography
                  align="left"
                  color="primary"
                  variant={isSmall ? "h6" : "h5"}
                >
                  Please add some payout destinations first
                </Typography>
              </Grid>
            ) : null}

            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: {
                  xs: "repeat(1, 1fr)",
                  sm: "repeat(2, 1fr)",
                  md: "repeat(3, 1fr)",
                  xl: "repeat(4, 1fr)",
                },
                gridGap: "15px",
              }}
            >
              <Box className={classes.zeroPad}>
                <AddFundingSourceCard
                  isLoading={isLoading}
                  onClick={openAddFundingSourceModal}
                  isPayout={true}
                  disabled={isDisabled}
                  disabledMessage={disabledMessage}
                />
              </Box>
              {fundingSources.map((fundingSource) => {
                return (
                  <FundingSourceCard
                    key={fundingSource.Token}
                    fundingSource={fundingSource}
                    isSelected={
                      selectedFundingSource === fundingSource ? true : false
                    }
                    onSelect={() => setSelectedFundingSource(fundingSource)}
                    onDelete={handleDelete}
                  />
                );
              })}
            </Box>

            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              className={classes.zeroPad}
            >
              <TextInputControlWithPrefix
                control={control}
                fullWidth
                id="amount"
                label="Amount"
                required
                rules={{ required: true }}
                prefix="$"
                type="number"
                variant="outlined"
              />
            </Grid>
            <Grid item lg={6} md={6} sm={6} />
            <Grid
              item
              lg={6}
              md={6}
              sm={12}
              xs={12}
              className={classes.zeroPad}
            >
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: {
                    xs: "repeat(1, 1fr)",
                    sm: "repeat(1, 1fr)",
                    md: "repeat(3, 1fr)",
                  },
                  gridGap: "1rem",
                }}
              >
                <Button
                  variant={watch("amount") === 50 ? "contained" : "outlined"}
                  disabled={50 * 100 > availableBalance}
                  size="large"
                  color="primary"
                  onClick={() => {
                    setValue("amount", 50, { shouldValidate: true });
                  }}
                  fullWidth
                >
                  $50
                </Button>

                <Button
                  variant={watch("amount") === 100 ? "contained" : "outlined"}
                  disabled={100 * 100 > availableBalance}
                  size="large"
                  color="primary"
                  fullWidth
                  onClick={() => {
                    setValue("amount", 100, { shouldValidate: true });
                  }}
                >
                  $100
                </Button>
                <Button
                  variant={
                    watch("amount") === availableBalance / 100
                      ? "contained"
                      : "outlined"
                  }
                  size="large"
                  color="primary"
                  fullWidth
                  onClick={() => {
                    setValue("amount", availableBalance / 100, {
                      shouldValidate: true,
                    });
                  }}
                >
                  ${availableBalance / 100}
                </Button>
                <div />
              </Box>
              <Grid item xs={12} mt={2}>
                {!isVerified ? (
                  <Button
                    variant="contained"
                    size="large"
                    color="primary"
                    fullWidth
                    onClick={() => openVerifyModal()}
                  >
                    Withdraw funds
                  </Button>
                ) : (
                  <Tooltip title={isDisabled ? disabledMessage : ""}>
                    <span>
                      <Button
                        variant="contained"
                        size="large"
                        color="primary"
                        fullWidth
                        disabled={
                          watch("amount") < 20 ||
                          watch("amount") > availableBalance / 100 ||
                          watch("fundingSourceDetails") === null ||
                          fundingSources.length === 0 ||
                          isError ||
                          !geoData ||
                          isDisabled
                        }
                        type="submit"
                      >
                        Withdraw funds
                      </Button>
                    </span>
                  </Tooltip>
                )}
              </Grid>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
      <VerifyAccountModal
        open={verifyModalOpen}
        handleClose={setVerifyModalOpen}
        handleConfirm={handleVerifyConfirm}
      />
      <CreatePayoutDestinationModal
        open={isAddCardModalOpen}
        handleClose={closeAddFundingSourceModal}
        tsevoSession={tsevoSession}
        isError={isError}
        geoData={geoData}
      />
    </Box>
  );
}

import { axiosWalletInstance } from "@shared/services/axiosInstances";
import {
  DeleteFundingSourcePayload,
  DepositFundsPayload,
  WithdrawFundsPayload,
} from "@shared/types";
import { Dayjs } from "dayjs";

interface IFormValues {
  email: string;
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  address1: string;
  city: string;
  phone: string;
  state?: string;
  postalCode: string;
  file?: File | undefined;
}

// Wallet services

export const fetchPaymentSourcesRequest = () => {
  return axiosWalletInstance({
    method: "GET",
    url: `v1/gateway/fetch-payment-sources`,
  });
};

export const verifyUserRequest = (payload: FormData) => {
  return axiosWalletInstance({
    method: "PATCH",
    url: `v1/gateway/verify-customer`,
    data: payload,
  });
};

export const depositFunds = (payload: DepositFundsPayload) => {
  return axiosWalletInstance({
    method: "POST",
    url: `v1/gateway/deposit-funds`,
    data: payload,
  });
};

export const withdrawFundsRequest = (payload: WithdrawFundsPayload) => {
  return axiosWalletInstance({
    method: "POST",
    url: `v1/gateway/create-withdraw-request`,
    data: payload,
  });
};

export const deleteFundingSourceRequest = (
  payload: DeleteFundingSourcePayload
) => {
  return axiosWalletInstance({
    method: "DELETE",
    url: `v1/gateway/delete-funding-source`,
    data: payload,
  });
};

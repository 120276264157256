import { Fragment } from "react";
import { useQueryClient } from "react-query";
import ProfileLayout from "@src/components/templates/ProfileLayout";
import { AccountVerification as AccountVerificationTab } from "@shared/components/templates/AccountVerification";
// import { useWalletInfo } from "@shared/hooks/useWalletInfo";
import { useGatewayInfo } from "@shared/hooks/useGatewayInfo";
import {
  VerificationFormValues,
  UserVerificationStatusType,
} from "@shared/types";
import { QueryKeys } from "@shared/constants/QueryKeys";
import { useVerifyUser } from "@src/hooks/useVerifyUser";
import { useUserInfo } from "@shared/hooks/useUserInfo";
import { useGeoLocation } from "@shared/hooks/useGeolocation";

export function Verification() {
  // const { data: walletInfo } = useWalletInfo();
  const queryClient = useQueryClient();
  const geocodingKey = process.env.REACT_APP_GOOGLE_GEOCODING_KEY || "";
  const fallbackCountry = "IN";
  const fallbackState = "GA";

  const { data: geoData } = useGeoLocation({
    geocodingKey,
    fallbackCountry,
    fallbackState,
  });

  const { data: gatewayInfo, isLoading: isGatewayInfoLoading } = useGatewayInfo(
    { lat: geoData?.lat, long: geoData?.long }
  );
  const { data: userInfo } = useUserInfo();

  const { mutate: verifyUser, isLoading: isVerifying } = useVerifyUser();

  const useruserVerificationStatus: UserVerificationStatusType =
    (gatewayInfo?.status || "") as UserVerificationStatusType;

  const handleVerify = (payload: VerificationFormValues) => {
    const year = new Date(payload.dob).getFullYear();
    const month = new Date(payload.dob).getMonth() + 1;
    const date = new Date(payload.dob).getDate();
    const dateOfBirth = `${year}-${month}-${date}`;

    const formData: any = new FormData();
    formData.append("email", payload.email);
    formData.append("address1", payload.address);
    formData.append("city", payload.city);
    formData.append("dateOfBirth", dateOfBirth);
    formData.append("firstName", payload.firstName);
    formData.append("lastName", payload.lastName);
    formData.append("phone", payload.phone);
    formData.append("postalCode", payload.zipcode);
    formData.append("state", payload?.state?.value);
    formData.append("lat", geoData?.lat);
    formData.append("long", geoData?.long);

    if (payload.file) {
      formData.append("file", payload.file, payload.file.name);
    }

    verifyUser(formData, {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKeys.GetUserGateway);
        queryClient.invalidateQueries(QueryKeys.GetUserInfo);
      },
    });
  };

  return (
    <Fragment>
      <ProfileLayout>
        <AccountVerificationTab
          isIdVerificationUploaded={false}
          isLoading={false}
          isVerified={useruserVerificationStatus === "verified"}
          onVerify={handleVerify}
          isVerifying={isVerifying}
          userVerificationStatus={useruserVerificationStatus}
          gatewayInfo={gatewayInfo}
          isGatewayInfoLoading={isGatewayInfoLoading}
          userInfo={userInfo}
        />
      </ProfileLayout>
    </Fragment>
  );
}

import { AxiosResponse } from "axios";
import { useMutation } from "react-query";
import { createPayoutMethodRequest } from "@shared/services/wallet";

interface IVariables {
  merchantSessionId: string;
  displayName: string;
  accountNumber: number;
  routingNumber: number;
  nameOnAccount: string;
  phoneNumber: number;
  billingAddress: {
    addressLine1: string;
    addressLine2: string;
    city: string;
    stateCode: string;
    postalCode: string;
    countryCode: string;
  };
}

export function useCreatePayoutMethod() {
  return useMutation<AxiosResponse<any, any>, unknown, IVariables, unknown>(
    (payload: IVariables) => createPayoutMethod(payload)
  );
}

async function createPayoutMethod(payload: IVariables) {
  return createPayoutMethodRequest(payload);
}

import { axiosWalletInstance } from "./axiosInstances";

export const fetchWalletBalance = () => {
  return axiosWalletInstance({
    method: "GET",
    url: `v1/ledger/balance`,
  });
};

export const fetchCustomer = (payload: any) => {
  return axiosWalletInstance({
    method: "GET",
    url: `v1/gateway/fetch-customer`,
    params: payload,
  });
};

export const createCustomer = () => {
  return axiosWalletInstance({
    method: "GET",
    url: `v1/gateway/create-customer`,
  });
};

export const fetchTransactions = () => {
  return axiosWalletInstance({
    method: "GET",
    url: `v1/ledger`,
  });
};
export const fetchFundingSources = () => {
  return axiosWalletInstance({
    method: "GET",
    url: `v1/funding-sources`,
  });
};

export const createTsevoSessionRequest = (requestPayload: any) => {
  return axiosWalletInstance({
    method: "POST",
    url: `v1/gateway/create-session`,
    data: requestPayload,
  });
};

export const createPaymentMethodRequest = (requestPayload: any) => {
  return axiosWalletInstance({
    method: "POST",
    url: `v1/gateway/create-payment-method`,
    data: requestPayload,
  });
};

export const createPayoutMethodRequest = (requestPayload: any) => {
  return axiosWalletInstance({
    method: "POST",
    url: `v1/gateway/create-payout-method`,
    data: requestPayload,
  });
};

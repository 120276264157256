import { axiosAuthInstance, axiosVerifiedAuthInstance } from "./axiosInstances";

export interface ISignUpPayload {
  confirmPassword: string;
  dateOfBirth: string; // ISO date string
  email: string;
  fullName: string;
  password: string;
  phone: string;
  promo: string;
  signUpState: string;
  signUpCountry: string;
  tosAccepted: boolean;
}

export interface ISignInPayload {
  idToken: string;
  state: string;
  country: string;
}

export interface IRenewTokenPayload {
  accessToken: string;
  refreshToken: string;
  email: string;
}

export interface IForgotPasswordPayload {
  email: string;
}

export interface IResetPasswordPayload {
  password: string;
  confirmPassword: string;
  forgotPasswordToken: string;
}

// Authentication services
export const signIn = (payload: ISignInPayload) => {
  return axiosAuthInstance({
    method: "POST",
    url: `v1/fetch-login-data`,
    data: payload,
  });
};

export const renewAccessToken = (payload: IRenewTokenPayload) => {
  return axiosAuthInstance({
    method: "POST",
    url: `v1/renew-access-token`,
    data: payload,
  });
};

// Registration services
export const signUp = (payload: ISignUpPayload) => {
  return axiosAuthInstance({
    method: "POST",
    url: `v1/sign-up`,
    data: payload,
  });
};

// Forgot service
export const forgotPassword = (payload: IForgotPasswordPayload) => {
  return axiosAuthInstance({
    method: "PATCH",
    url: `v1/forgot-password`,
    data: payload,
  });
};

// Reset service
export const resetPassword = (payload: IResetPasswordPayload) => {
  return axiosAuthInstance({
    method: "PATCH",
    url: `v1/reset-password`,
    data: payload,
  });
};

export const logout = () => {
  return axiosVerifiedAuthInstance({
    method: "POST",
    url: `v1/logout`,
  });
};

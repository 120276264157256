import { useQuery } from "react-query";
import { createTsevoSessionRequest } from "@shared/services/wallet";
import { QueryKeys } from "@shared/constants/QueryKeys";

interface SessionParams {
  payActionCode: string;
  lat?: number;
  long?: number;
}

export function useCreateTsevoSession(data: SessionParams) {
  return useQuery(
    [QueryKeys.CreateSession, data],
    async () => createTsevoSession(data),
    {
      staleTime: 0,
      retry: false,
      enabled: !!data.lat,
    }
  );
}

async function createTsevoSession(requestObject: SessionParams): Promise<any> {
  return createTsevoSessionRequest(requestObject).then(({ data: { data } }) => {
    return data;
  });
}

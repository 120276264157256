export const GEO = "topprop_geo";
export const USER_CONFIG = "topprop_config";
export const USER_INFO = "topprop_user";
export const WALLET_INFO = "topprop_wallet";
export const GATEWAY_INFO = "topprop_gateway";
export const ACCESS_TOKEN = "topprop_access_token";
export const REFRESH_TOKEN = "topprop_refresh_token";
export const TRANSFER_INFO = "topprop_wallet_transfers";
export const WITHDRAW_FUNDS_INFO = "topprop_withdraw_funds_info";
export const POPUP_EMAILS = "topprop_popup_emails";

import { ReactNode, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AppRoot, routesForApp } from "@shared/constants/routePaths";
import { useAuth } from "@src/hooks/useAuth";

const routes = routesForApp<AppRoot.ACCOUNT>(AppRoot.ACCOUNT);

export function ProtectedRoute({ children }: { children: ReactNode }) {
  const navigate = useNavigate();
  const { isError } = useAuth();

  useEffect(() => {
    if (isError) {
      navigate(routes.login, { replace: true });
    }
  }, [isError, navigate]);

  return <>{children}</>;
}

import { Button, Card, Container, Stack, Typography } from "@mui/material";
import { LogoFullLarge } from "@shared/components/atoms/logos/LogoFullLarge";

export interface ForgotPasswordProps {
  onReturnToDashboard: () => void;
}

export function FourOhFour(props: ForgotPasswordProps) {
  const { onReturnToDashboard } = props;

  return (
    <Container component="main" maxWidth="xs">
      <Stack
        alignItems="center"
        direction="column"
        justifyContent="center"
        spacing={4}
        height="100%"
      >
        <LogoFullLarge />
        <Card sx={{ width: "100%", p: 2 }}>
          <Stack direction="column">
            <Stack direction="column" spacing={2} alignItems={"center"}>
              <Typography color="primary" variant="h1" align="center">
                404
              </Typography>
              <Typography variant="h5" align="center">
                Uh Oh! Page Not Found
              </Typography>
              <Typography align="center">
                The page you are looking for does not exist. You can click on
                the button below to go back to the homepage.
              </Typography>
              <Button
                size="large"
                fullWidth
                disableElevation
                variant="contained"
                color="primary"
                onClick={() => onReturnToDashboard()}
                sx={{
                  mt: 4,
                  pl: 6,
                  pr: 6,
                  fontWeight: "bold",
                }}
              >
                Home
              </Button>
            </Stack>
          </Stack>
        </Card>
      </Stack>
    </Container>
  );
}

import { ReactNode } from "react";

interface ListHeaderProps {
  render: () => ReactNode;
}

export function ListHeader(props: ListHeaderProps) {
  const { render } = props;
  return <>{render()}</>;
}

import { useMutation } from "react-query";
import { Dayjs } from "dayjs";
import { verifyUserRequest } from "@src/services/wallet";

interface IFormValues {
  email: string;
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  address1: string;
  city: string;
  phone: string;
  state?: string;
  postalCode: string;
  file?: File | undefined;
}

export function useVerifyUser() {
  return useMutation<void, Error, FormData>((variables) =>
    verifyUser(variables)
  );
}

async function verifyUser(variables: any): Promise<void> {
  return verifyUserRequest(variables).then(() => {});
}

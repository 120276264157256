export enum QueryKeys {
  GetGeolocation = "GetGeoLocation",
  GetUserInfo = "GetUserInfo",
  GetUserWallet = "GetUserWallet",
  GetUserGateway = "GetUserGateway",
  FetchTransfers = "GetFetchTransfers",
  GetUserbalance = "GetUserbalance",
  GetFundingSources = "GetFundingSources",
  GetWithdrawFundsInfo = "GetWithdrawFundsInfo",
  IavToken = "IavToken",
  FirebaseConfig = "FirebaseConfig",
  SignIn = "SignIn",
  SignUp = "SignUp",
  Auth = "Auth",
  PaymentSources = "PaymentSources",
  CreateSession = "CreateSession",
  CompleteSession = "CompleteSession",
}

import { useQuery } from "react-query";
import { QueryKeys } from "@shared/constants/QueryKeys";
import { fetchMe } from "@shared/services/auth";

export interface CachedUserInfo {
  id: number;
  fullName: string;
  email: string;
  phone: string;
  _customerTokenUrl: string;
  dateOfBirth: string;
  role: string;
  customId: string;
  expiresAt?: number;
  config: {
    appAccess: boolean;
    paidContests: boolean;
    minAge: number;
    weeklyDepositLimit: number;
    leaguesEnabled: boolean;
    battlegroundEnabled: boolean;
    hasDeposits: boolean;
    type: "principle" | "proxy";
    dwollaEnv: "sanbox" | "production";
  };
}

export function useUserInfo() {
  return useQuery<CachedUserInfo | undefined, Error>(
    QueryKeys.GetUserInfo,
    () => getUserInfo(),
    {
      cacheTime: Infinity,
    }
  );
}

async function getUserInfo(): Promise<CachedUserInfo | undefined> {
  const response = await fetchMe();
  if (response) {
    const userInfo = response.data.data;
    const gatewayInfo: CachedUserInfo = {
      id: userInfo.id,
      fullName: userInfo.fullName,
      email: userInfo.email,
      phone: userInfo.phone,
      _customerTokenUrl: userInfo._customerTokenUrl,
      dateOfBirth: userInfo.dateOfBirth,
      role: userInfo.role,
      customId: userInfo.customId,
      config: userInfo.config,
    };
    return gatewayInfo;
  }
}

import React from "react";

import { CircularProgress, Grid, Typography } from "@mui/material";

export interface IProps {
  text?: string;
}
const Loader = (props: IProps) => {
  const { text } = props;

  return (
    <Grid
      container
      justifyContent="center"
      alignContent="center"
      className="h-64 w-full"
    >
      <Grid item xs={12} container justifyContent="center">
        <CircularProgress size={64} thickness={4} />
      </Grid>

      {text ? (
        <Grid item xs={12} className="mt-12">
          <Typography variant="h5" color="primary" align="center">
            {text}
          </Typography>
        </Grid>
      ) : (
        ""
      )}
    </Grid>
  );
};
export default Loader;

import { ReactElement } from "react";

export enum UserVerificationStatusType {
  Verified = "verified",
  Unverified = "unverified",
  Retry = "retry",
  Suspended = "suspended",
  Deactivated = "deactivated",
  Document = "document",
  Blocked = "blocked",
}

export interface SidebarItemType {
  label: string;
  path: string;
  activePaths: Array<string>;
  activeIcon: ReactElement;
  inactiveIcon: ReactElement;
  isExternal?: boolean;
}

export interface ISelectOption {
  label: string;
  value: string;
}

export interface VerificationFormValues {
  email: string;
  firstName: string;
  lastName: string;
  dob: Date;
  address: string;
  city: string;
  phone: string;
  country: ISelectOption;
  state: ISelectOption;
  zipcode: string;
  file?: File | undefined;
}

export interface FundingSourceData {
  bankAccountType: string;
  bankName: string;
  created: string;
  id: string;
  status: string;
  type: string;
  name: string;
}

export interface PayoutMethodData {
  NameOnAccount: string;
  AccountNumber: string;
  Type: string;
  DisplayName: string;
  RoutingNumber: string;
  Token: string;
}

export interface PaymentMethodData {
  NameOnAccount: string;
  CardNumber: string;
  Type: string;
  DisplayName: string;
  Token: string;
}

export interface GatewayCustomerData {
  correlationId: string;
  created: string;
  email: string;
  firstName: string;
  id: string;
  lastName: string;
  status: string;
  statusMessage?: string;
  type: string;
  expiresAt?: string;
  address1: string;
  city: string;
  phone: string;
  postalCode: string;
  state: string;
  country: string;
  ssn: string;
  dob: string;
}

export interface AccessTokenPayload {
  exp: number;
  data: {
    userId: string;
    email: string;
  };
  sub: string;
}

export interface DepositFundsPayload {
  amount: number;
  sourceFundingSourceId: string;
  merchantSessionId: string;
}

export interface WithdrawFundsPayload {
  amount?: number;
  destinationFundingSourceId: string;
  merchantSessionId: string;
}

export interface DeleteFundingSourcePayload {
  fundingSourceId: string;
}

import { ReactNode } from "react";
import { Control, Controller, Path } from "react-hook-form";
import {
  FormControl,
  FormControlLabel,
  Checkbox,
  Typography,
  FormHelperText,
  Box,
} from "@mui/material";

interface ToSLinkProps {
  children: string | ReactNode | HTMLCollection;
  href: string;
}

function ToSLink(props: ToSLinkProps) {
  const { href, children } = props;
  return (
    <Box
      component="a"
      href={href}
      target="_blank"
      rel="noreferrer"
      sx={{
        color: "info.main", 
        textDecoration: "none",
        ":hover": {
          "textDecoration": "underline",
          "textDecorationColor": "info.main", 
        },
      }}
    >
      {children}
    </Box>
  );
}

type TosCheckboxControlProps<TFormFields> = {
  control: Control<TFormFields, object>;
  id: Path<TFormFields>;
};

export function ToSCheckboxControl<TFormFields>(
  props: TosCheckboxControlProps<TFormFields>
) {
  const { control, id } = props;
  return (
    <Controller
      control={control}
      name={id}
      render={({ field, fieldState }) => (
        <FormControl required component="fieldset" variant="standard">
          <FormControlLabel
            control={
              <Checkbox id={id} {...field} name="tosAccepted" color="primary" />
            }
            sx={{ alignItems: "flex-start" }}
            label={
              <Typography variant="caption">
                By checking this box you agree to{" "}
                <ToSLink href="https://app.toppropfantasy.com/terms-of-service">
                  Our Terms of Service
                </ToSLink>
                {" and "}
                <ToSLink href="https://app.toppropfantasy.com/privacy-policy">
                  Privacy Policy
                </ToSLink>
                , as well as our partner{" "}
                <ToSLink href="https://www.dwolla.com/legal/dwolla-account-terms-of-service/">
                  Dwolla's Terms of Service
                </ToSLink>
                {" and "}
                <ToSLink href="https://www.dwolla.com/legal/privacy/">
                  Dwolla's Privacy Policy
                </ToSLink>
                , and our banking partner Evolve Bank & Trust's{" "}
                <ToSLink href="https://www.dwolla.com/legal/virtual-account-number-business-account-demand-deposit-agreement-2/#legal-content">
                  Customer Account Terms
                </ToSLink>
                {" and "}
                <ToSLink href="https://assets.getevolved.com/cdnevolve/privacy-policy/Consumer-Privacy-Policy-Notice---9.15.20-Final.pdf">
                  Privacy Notice
                </ToSLink>
                .
              </Typography>
            }
          />
          {fieldState.error && (
            <FormHelperText error>{fieldState.error.message}</FormHelperText>
          )}
        </FormControl>
      )}
    />
  );
}

export function ToSContestRules({ isLargeScreen }: { isLargeScreen: boolean }) {
  return (
    <Typography
      variant="caption"
      textAlign={isLargeScreen ? "right" : "center"}
    >
      By registering, you agree to TopProp's{" "}
      <ToSLink href="https://www.toppropsports.com/faq">Contest Rules</ToSLink>.
    </Typography>
  );
}

import { Auth, signInWithEmailAndPassword } from "firebase/auth";
import { useMutation } from "react-query";
import { signIn } from "@src/services/auth";
import { saveAuthTokens, saveUserConfig } from "@src/services/authTokenStorage";
import useFirebaseAuth from "./useFirebaseAuth";

interface Variables {
  email: string;
  password: string;
  state: string;
  country: string;
}

interface ITokens {
  accessToken: string;
  refreshToken: string;
  config: {};
}

export function useSignIn(
  onSuccess: () => void,
  onError: (error: any) => void
) {
  const { data: auth } = useFirebaseAuth();
  return useMutation<ITokens | undefined, Error, Variables>(
    (variables) => signinAndGetAuthTokens(variables, auth),
    {
      onSuccess,
      onError,
    }
  );
}

async function signinAndGetAuthTokens(
  variables: Variables,
  auth?: Auth
): Promise<ITokens | undefined> {
  if (
    !auth ||
    !variables.country ||
    !variables.state ||
    !variables.email ||
    !variables.password
  ) {
    throw new Error("Incomplete credentials");
  }

  // TODO @irina: surface error messages
  return new Promise((resolve, reject) => {
    signInWithEmailAndPassword(auth, variables.email, variables.password)
      .then(({ user }) => {
        return user
          .getIdToken(true)
          .then((idToken: string) =>
            signIn({
              idToken,
              country: variables.country,
              state: variables.state,
            })
          )
          .then(({ data: { data } }) => {
            saveAuthTokens({
              accessToken: data.accessToken,
              refreshToken: data.refreshToken,
            });
            saveUserConfig(JSON.stringify(data.config));

            const userData = {
              accessToken: data.accessToken,
              refreshToken: data.refreshToken,
              config: data.config,
            };
            resolve(userData);
          })
          .catch((error) => {
            reject({
              message: "Incorrect Username or Password",
              error: error,
            });
          });
      })
      .catch((error) => {
        const errorCode = error.code;
        let errorMessage = "";
        switch (errorCode) {
          case "auth/user-disabled":
            errorMessage = "User has been disabled";
            break;
          case "auth/too-many-requests":
            errorMessage =
              "Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later.";
            break;

          default:
            errorMessage = "Incorrect Username or Password";
            break;
        }
        reject({
          message: errorMessage,
          error: error,
        });
      });
  });
}

import { Routes, Route, Navigate } from "react-router-dom";

import { SignIn } from "@src/pages/SignIn";
import { SignUp } from "@src/pages/SignUp";
import { ForgotPassword } from "@src/pages/ForgotPassword";
import { Wallet } from "@src/pages/Wallet";
import { ProtectedRoute } from "@molecules/ProtectedRoute";
import { ResetPassword } from "./pages/ResetPassword";
import { AppRoot, routesForApp } from "@shared/constants/routePaths";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsOfService from "./pages/TermsOfService";
import ResponsibleGaming from "./pages/ResponsibleGaming";
import { WithdrawFunds } from "./pages/WithdrawFunds";
import { Transfers } from "./pages/Transfers";
import { Verification } from "./pages/Verification";
import PublicPageLayout from "./components/templates/PublicPageLayout";
import { FourOhFour } from "./pages/FourOhFour";
import NavigateWithParams from "@atoms/NavigateWithParams";
import { MobilePromotion } from "./pages/MobilePromotion";
// import { Signout } from "./pages/Signout";

const routes = routesForApp<AppRoot.ACCOUNT>(AppRoot.ACCOUNT);

export function AppRoutes() {
  return (
    <Routes>
      <Route path={routes.login} element={<SignIn />} />
      <Route
        path={routes.loginAlt}
        element={<NavigateWithParams to={routes.login} replace />}
      />
      <Route path={routes.forgotPassword} element={<ForgotPassword />} />
      <Route path={routes.signup} element={<SignUp />} />
      <Route path={routes.mobilePromotion} element={<MobilePromotion />} />
      <Route
        path={routes.register}
        element={
          <NavigateWithParams to={routes.signup} replace keys={["promo"]} />
        }
      />
      <Route path={routes.resetPassword} element={<ResetPassword />} />

      <Route
        path={routes.privacyPolicy}
        element={
          <PublicPageLayout title="Privacy Policy">
            <PrivacyPolicy />
          </PublicPageLayout>
        }
      />
      <Route
        path={routes.termsOfService}
        element={
          <PublicPageLayout title="Terms of Service">
            <TermsOfService />
          </PublicPageLayout>
        }
      />
      <Route
        path={routes.responsibleGaming}
        element={
          <PublicPageLayout title="Responsible Gaming">
            <ResponsibleGaming />
          </PublicPageLayout>
        }
      />

      <Route
        path={routes.profile.wallet}
        element={
          <ProtectedRoute>
            <Wallet />
          </ProtectedRoute>
        }
      />
      <Route
        path={routes.profile.withdraw}
        element={
          <ProtectedRoute>
            <WithdrawFunds />
          </ProtectedRoute>
        }
      />
      <Route
        path={routes.profile.transfers}
        element={
          <ProtectedRoute>
            <Transfers />
          </ProtectedRoute>
        }
      />
      <Route
        path={routes.profile.verify}
        element={
          <ProtectedRoute>
            <Verification />
          </ProtectedRoute>
        }
      />
      <Route path="*" element={<FourOhFour />} />
    </Routes>
  );
}

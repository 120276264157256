import { loadAuthTokens, loadUserConfig } from "@src/services/authTokenStorage";
import { useQuery } from "react-query";
import * as jwt from "jsonwebtoken";
import { QueryKeys } from "@shared/constants/QueryKeys";
import { AccessTokenPayload } from "@shared/types";

interface AuthInfoPayload {
  userData: AccessTokenPayload["data"];
  config: {
    dwollaEnv: string;
  };
}

export function useAuth() {
  return useQuery(QueryKeys.Auth, async () => getAuthInfo(), {
    staleTime: 0,
    retry: false,
  });
}

async function getAuthInfo(): Promise<AuthInfoPayload> {
  const tokens = loadAuthTokens();
  let payload: AccessTokenPayload = jwt.decode(
    tokens.accessToken
  ) as AccessTokenPayload;
  const config = loadUserConfig();
  return { userData: payload.data, config };
}

import { Box } from "@mui/material";
import { FC } from "react";

interface RowItemProps {
  flex?: number;
  width?: number;
}

export const RowItem: FC<RowItemProps> = ({ children, flex, width }) => {
  return (
    <Box
      alignItems="center"
      {...(width ? { width } : null)}
      {...(flex && !width ? { flex } : null)}
    >
      {children}
    </Box>
  );
};

import { Grid, Box } from "@mui/material";

import allRoutes, { AppRoot } from "@shared/constants/routePaths";
import LogoImage from "@shared/images/LogoFull.png";
import { FooterLink } from "@shared/components/molecules/footerLink";
import { Theme } from "@mui/material/styles";

export interface FooterProps {
  hideBorder: boolean;
}

const footerLinks = [
  {
    label: "FAQ",
    link: `https://www.toppropsports.com/faq`,
  },
  {
    label: "Privacy Policy",
    link: allRoutes[AppRoot.ACCOUNT].privacyPolicy,
  },
  {
    label: "Terms of Service",
    link: allRoutes[AppRoot.ACCOUNT].termsOfService,
  },
  {
    label: "Responsible Gambling",
    link: allRoutes[AppRoot.ACCOUNT].responsibleGaming,
  },
];

export function Footer(props: FooterProps) {
  const { hideBorder } = props;

  return (
    <Grid
      item
      container
      justifyContent="space-between"
      alignContent="center"
      sx={{
        marginBottom: {
          xs: "1rem",
          md: "0",
        },
        padding: {
          xs: "0.25rem",
          lg: "0.25rem",
        },
        borderTop: (theme: Theme) =>
          hideBorder ? "0" : `1px solid ${theme.palette.grey[300]}`,
      }}
    >
      <Grid
        item
        xs={12}
        sm={12}
        md={4}
        lg={6}
        container
        justifyContent={{ xs: "center", md: "flex-start" }}
      >
        <Box
          component="img"
          src={LogoImage}
          alt="TopProp"
          sx={{ height: "2.5rem" }}
        />
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={8}
        lg={6}
        container
        alignContent="center"
        justifyContent={{ xs: "center", md: "flex-end" }}
      >
        {footerLinks.map((link) => (
          <FooterLink key={link.label} item={link} />
        ))}
      </Grid>
    </Grid>
  );
}

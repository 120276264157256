import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { SignIn as SignInTemplate } from "@shared/components/templates/SignIn";
import { useSignIn } from "@src/hooks/useSignIn";
import allRoutes, { AppRoot, routesForApp } from "@shared/constants/routePaths";
import { useAuth } from "@src/hooks/useAuth";
import BaseLayout from "@src/components/templates/BaseLayout";
import { useQueryParams } from "@shared/hooks/useQueryParams";
import Loader from "@shared/components/molecules/loader";

const routes = routesForApp<AppRoot.PGA>(AppRoot.PGA);

interface IFormValues {
  email: string;
  password: string;
}

export function SignIn() {
  const { enqueueSnackbar } = useSnackbar();
  const [isPageLoading, setIsPageLoading] = useState(false);

  const onError = (error: any) => {
    enqueueSnackbar(error.message, {
      variant: "default",
    });
  };
  const { isLoading, mutate: signin, isSuccess } = useSignIn(() => {}, onError);

  const { isSuccess: isAuthSuccess, isLoading: isAuthLoading } = useAuth();

  let query = useQueryParams();
  const returnUrl = query.get("returnUrl");

  useEffect(() => {
    if (isSuccess || isAuthSuccess) {
      if (returnUrl) {
        window.location.href = returnUrl;
      } else {
        window.location.href = allRoutes.pga.battleground.lobby;
      }
    } else {
      setIsPageLoading(false);
    }

    if (isAuthLoading) {
      setIsPageLoading(true);
    }
  }, [isAuthSuccess, isSuccess, returnUrl, isAuthLoading]);

  if (!process.env.REACT_APP_GOOGLE_GEOCODING_KEY) {
    throw new Error("Geocoding key missing");
  }

  return (
    <BaseLayout>
      {!isPageLoading ? (
        <SignInTemplate
          fallbackCountry="IN"
          fallbackState="GA"
          geocodingKey={process.env.REACT_APP_GOOGLE_GEOCODING_KEY}
          isLoading={isLoading}
          onSignIn={signin}
        />
      ) : (
        <Loader text="Loading..." />
      )}
    </BaseLayout>
  );
}

import { useMemo, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useForm, SubmitHandler, FormProvider } from "react-hook-form";
import {
  Box,
  Button,
  Grid,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Divider } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { TextInputControlWithPrefix } from "@shared/components/organisms/TextInputControlWithPrefix";
import { theme } from "@shared/components/tokens/theme";
import { Banner } from "@shared/components/molecules/banners/Banner";
import { AddFundingSourceCard } from "./AddFundingSourceCard";
import VerifyAccountModal from "@shared/components/organisms/verifyAccountModal/VerifyAccountModal";
import { useCreateTsevoSession } from "@shared/hooks/useCreateTsevoSession";
import { AppRoot, routesForApp } from "@shared/constants/routePaths";
import CreateFundingSourceModal from "@shared/components/organisms/createFundingSourceModal/CreateFundingSourceModal";
import SelectionFundingSource from "@shared/components/molecules/selectionFundingSource";
import { DepositFundsPayload, PaymentMethodData } from "@shared/types";

import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/system";
import { useGeoLocation } from "@shared/hooks/useGeolocation";

const routes = routesForApp<AppRoot.ACCOUNT>(AppRoot.ACCOUNT);

interface IFormValues {
  fundingSourceDetails: PaymentMethodData | null;
  cvv: string;
  amount: number;
}

const useStyles = makeStyles((theme: Theme) => ({
  zeroPad: {
    paddingLeft: "0 !important",
    paddingRight: "0 !important",
  },
}));

export interface WalletProps {
  onAddFunds: (data: DepositFundsPayload) => void;
  availableBalance: number;
  isVerified: boolean;
  paymentSources: Array<PaymentMethodData>;
  onDeleteFundingSource: (fudingSourceId: string) => void;
}

export function Wallet(props: WalletProps) {
  const { paymentSources, onAddFunds, onDeleteFundingSource } = props;

  const geocodingKey = process.env.REACT_APP_GOOGLE_GEOCODING_KEY || "";
  const fallbackCountry = "IN";
  const fallbackState = "GA";

  const { isError, data: geoData } = useGeoLocation({
    geocodingKey,
    fallbackCountry,
    fallbackState,
  });

  const navigate = useNavigate();
  const isSmall = useMediaQuery(theme.breakpoints.down("md"));

  const classes = useStyles();

  const { data: tsevoSession } = useCreateTsevoSession({
    payActionCode: "PAY",
    lat: geoData?.lat,
    long: geoData?.long,
  });

  const tsevoStatus = tsevoSession?.tsevoStatus;
  const isDisabled = tsevoStatus?.status === "blocked";
  const disabledMessage = tsevoStatus?.message || "";

  const [verifyModalOpen, setVerifyModalOpen] = useState(false);
  const [isAddCardModalOpen, setAddCardModalOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const formSchema = useMemo(
    () =>
      yup.object({
        amount: yup.number().min(10, "Minimum allowed value is 10"),
      }),
    []
  );

  const methods = useForm<IFormValues>({
    resolver: yupResolver(formSchema),
    defaultValues: {
      amount: 100,
      fundingSourceDetails: paymentSources[0],
    },
  });

  const { watch, reset, control, setValue } = methods;

  const selectedFundingSource = watch("fundingSourceDetails");

  useEffect(() => {
    if (paymentSources.length > 0) {
      const latestPaymentSource = paymentSources.reduce(
        (prev: PaymentMethodData, current: PaymentMethodData) =>
          prev.DisplayName > current.DisplayName ? prev : current,
        paymentSources[0]
      );
      const defaultValues = {
        amount: 100,
        cvv: "",
        fundingSourceDetails: latestPaymentSource,
      };

      reset(defaultValues);
    }
  }, [paymentSources, reset]);

  const openAddFundingSourceModal = () => {
    setLoading(true);
    setAddCardModalOpen(true);
  };

  const closeAddFundingSourceModal = () => {
    setLoading(false);
    setAddCardModalOpen(false);
  };

  const openVerifyModal = () => {
    setVerifyModalOpen(true);
  };

  const handleVerifyConfirm = () => {
    navigate(routes.profile.verify);
    setVerifyModalOpen(false);
  };

  const handleDeleteCard = (fundingSourceId: string) => {
    onDeleteFundingSource(fundingSourceId);
  };

  const onSubmit: SubmitHandler<IFormValues> = (data) => {
    const payload = {
      amount: data.amount * 100,
      cvv: data.cvv,
      sourceFundingSourceId: data?.fundingSourceDetails?.Token || "",
      merchantSessionId: tsevoSession.MerchantSessionID,
      lat: geoData?.lat,
      long: geoData?.long,
    };
    onAddFunds(payload);
  };
  const availableBalance = props.availableBalance;
  const isVerified = props.isVerified;

  return (
    <Box className={classes.zeroPad}>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Grid container rowSpacing={3}>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              className={classes.zeroPad}
            >
              <Stack direction="row" justifyContent="space-between">
                <Typography variant={isSmall ? "h6" : "h5"}>
                  Available Balance
                </Typography>
                <Typography
                  align="right"
                  color="primary"
                  variant={isSmall ? "h6" : "h5"}
                >
                  ${availableBalance / 100}
                </Typography>
              </Stack>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              className={classes.zeroPad}
            >
              <Banner
                mainText="100% Deposit Match in immediately playable funds up to $50"
                subText="First deposit only. State restrictions apply."
              ></Banner>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              className={classes.zeroPad}
            >
              <Typography variant={isSmall ? "h6" : "h5"}>
                Funding Sources
              </Typography>
              <Typography marginBottom={1}>
                This information is for TopProp’s private use only. By adding
                your bank information, you can fund your wallet for direct
                deposit.
              </Typography>
              <Divider />
            </Grid>
            {!paymentSources.length ? (
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                className={classes.zeroPad}
              >
                <Typography
                  align="left"
                  color="primary"
                  variant={isSmall ? "h6" : "h5"}
                >
                  Please add some funding sources to fund your wallet
                </Typography>
              </Grid>
            ) : null}
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: {
                  xs: "repeat(1, 1fr)",
                  sm: "repeat(2, 1fr)",
                  md: "repeat(3, 1fr)",
                  xl: "repeat(4, 1fr)",
                },
                gridGap: "15px",
              }}
            >
              <Box className={classes.zeroPad}>
                <AddFundingSourceCard
                  isLoading={isLoading}
                  onClick={openAddFundingSourceModal}
                  disabled={isDisabled}
                  disabledMessage={disabledMessage}
                />
              </Box>
              {paymentSources.map((fundingSource) => (
                <SelectionFundingSource
                  key={fundingSource.Token}
                  fundingSource={fundingSource}
                  selectedFundingSource={selectedFundingSource}
                  handleDeleteCard={handleDeleteCard}
                  setValue={setValue}
                />
              ))}
            </Box>
            <Grid item xs={12} sm={12} md={6} lg={6} container spacing={1}>
              <Grid item xs={3}>
                <TextInputControlWithPrefix
                  control={control}
                  fullWidth
                  id="cvv"
                  label="CVV ( Selected Card )"
                  required
                  rules={{ required: true }}
                  type="number"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={9}>
                <TextInputControlWithPrefix
                  control={control}
                  fullWidth
                  id="amount"
                  label="Amount"
                  required
                  rules={{ required: true }}
                  prefix="$"
                  type="number"
                  variant="outlined"
                />
              </Grid>
            </Grid>
            <Grid item lg={6} md={6} sm={6} />
            <Grid
              item
              lg={6}
              md={6}
              sm={12}
              xs={12}
              className={classes.zeroPad}
            >
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: {
                    xs: "repeat(1, 1fr)",
                    sm: "repeat(1, 1fr)",
                    md: "repeat(3, 1fr)",
                  },
                  gridGap: "1rem",
                }}
              >
                <Button
                  variant={watch("amount") === 50 ? "contained" : "outlined"}
                  size="large"
                  color="primary"
                  onClick={() => {
                    setValue("amount", 50, { shouldValidate: true });
                  }}
                  fullWidth
                >
                  $50
                </Button>

                <Button
                  variant={watch("amount") === 100 ? "contained" : "outlined"}
                  size="large"
                  color="primary"
                  fullWidth
                  onClick={() => {
                    setValue("amount", 100, { shouldValidate: true });
                  }}
                >
                  $100
                </Button>
                <Button
                  variant={watch("amount") === 250 ? "contained" : "outlined"}
                  size="large"
                  color="primary"
                  fullWidth
                  onClick={() => {
                    setValue("amount", 250, { shouldValidate: true });
                  }}
                >
                  $250
                </Button>
                <div />
              </Box>
              <Grid item xs={12} mt={2}>
                {!isVerified ? (
                  <Button
                    variant="contained"
                    size="large"
                    color="primary"
                    fullWidth
                    onClick={() => openVerifyModal()}
                  >
                    Add funds
                  </Button>
                ) : (
                  <Tooltip title={isDisabled ? disabledMessage : ""}>
                    <span>
                      <Button
                        variant="contained"
                        size="large"
                        color="primary"
                        fullWidth
                        disabled={
                          watch("amount") < 10 ||
                          watch("fundingSourceDetails") === null ||
                          paymentSources.length === 0 ||
                          isError ||
                          !geoData ||
                          isDisabled
                        }
                        type="submit"
                      >
                        Add funds
                      </Button>
                    </span>
                  </Tooltip>
                )}
              </Grid>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
      <VerifyAccountModal
        open={verifyModalOpen}
        handleClose={setVerifyModalOpen}
        handleConfirm={handleVerifyConfirm}
      />
      <CreateFundingSourceModal
        open={isAddCardModalOpen}
        handleClose={closeAddFundingSourceModal}
        tsevoSession={tsevoSession}
      />
    </Box>
  );
}

import axios, { AxiosRequestConfig } from "axios";
import { AUTH_BASE_URL, WALLET_API_BASE_URL } from "@shared/constants/ApiHosts";
import * as BrowserStorage from "@shared/constants/BrowserStorage";

export const axiosAuthInstance = axios.create({
  baseURL: AUTH_BASE_URL,
  headers: { "Content-Type": "application/json" },
});

export const axiosVerifiedAuthInstance = axios.create({
  baseURL: AUTH_BASE_URL,
  headers: { "Content-Type": "application/json" },
});

export const axiosWalletInstance = axios.create({
  baseURL: WALLET_API_BASE_URL,
  headers: { "Content-Type": "application/json" },
});

axiosVerifiedAuthInstance.interceptors.request.use(
  function (config: AxiosRequestConfig) {
    let token = localStorage.getItem(BrowserStorage.ACCESS_TOKEN);
    if (token && config.headers) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);


axiosVerifiedAuthInstance.interceptors.request.use(
  function (config: AxiosRequestConfig) {
    let token = localStorage.getItem(BrowserStorage.ACCESS_TOKEN);
    if (token && config.headers) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);
